import { FC } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { NeuSubLeftNav, NeuItem, NeuStepper, NeuLabel } from '@neutron/react';
import { AuthUserProps, RootState } from '../../config/interfaces';

interface AccountSideNavProps {
  authorizedUser?: AuthUserProps;
}

const AccountSideNav: FC<AccountSideNavProps> = ({ authorizedUser }) => {
  const { pathname } = useLocation();

  return (
    <NeuSubLeftNav
      id="Side-Nav"
      name="MENU"
      color="plain"
      side="left"
      style={{ marginTop: '-3px', zIndex: 1 }}
    >
      {authorizedUser &&
        (authorizedUser?.roles.includes('enterpriseAdmin') ||
          authorizedUser?.roles.includes('employeeRounder')) && (
          <>
            <Link to="/account/employee-rounding-settings/employee-list">
              <NeuItem
                id="Side-Nav-My-Employee-List-Label"
                color={
                  pathname === '/account/employee-rounding-settings' ||
                  pathname.includes('employee-list')
                    ? 'primary-10'
                    : 'plain-0'
                }
                className="pl-3"
              >
                <NeuStepper slot="start" />
                <NeuLabel>My Employee List</NeuLabel>
              </NeuItem>
            </Link>
            <Link to="/account/employee-rounding-settings/delegate-list">
              <NeuItem
                id="Side-Nav-My-Delegates-Label"
                data-testid="Side-Nav-My-Delegates-Label"
                color={
                  pathname.includes('delegate-list') ? 'primary-10' : 'plain-0'
                }
                className="pl-3"
              >
                <NeuStepper slot="start" />
                <NeuLabel> My Delegates </NeuLabel>
              </NeuItem>
            </Link>
            <Link to="/account/employee-rounding-settings/delegated-to-me">
              <NeuItem
                id="Side-Nav-Lists-Delegated-To-Me-Label"
                color={
                  pathname.includes('delegated-to-me')
                    ? 'primary-10'
                    : 'plain-0'
                }
                className="pl-3"
              >
                <NeuStepper slot="start" />
                <NeuLabel>Lists Delegated To Me </NeuLabel>
              </NeuItem>
            </Link>
          </>
        )}
      {(authorizedUser?.roles.includes('csrnRounder') ||
        authorizedUser?.roles.includes('enterpriseAdmin')) && (
        <Link to="/account/employee-rounding-settings/csrn-list">
          <NeuItem
            id="Side-Nav-Lists-CSRN-Label"
            data-testid="Side-Nav-Lists-CSRN-Label"
            color={pathname.includes('csrn-list') ? 'primary-10' : 'plain-0'}
            className="pl-3"
          >
            <NeuStepper slot="start" />
            <NeuLabel>CSRN List</NeuLabel>
          </NeuItem>
        </Link>
      )}
      {(authorizedUser?.roles.includes('cnedRounder') ||
        authorizedUser?.roles.includes('enterpriseAdmin')) && (
        <Link to="/account/employee-rounding-settings/cned-list">
          <NeuItem
            id="Side-Nav-Lists-CNEd-Label"
            data-testid="Side-Nav-Lists-CNEd-Label"
            color={pathname.includes('cned-list') ? 'primary-10' : 'plain-0'}
            className="pl-3"
          >
            <NeuStepper slot="start" />
            <NeuLabel>CNEd List</NeuLabel>
          </NeuItem>
        </Link>
      )}
    </NeuSubLeftNav>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser
});
export default connect(mapReduxStateToProps)(AccountSideNav);

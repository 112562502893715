import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import { CReportContainer } from '../styles/ReportsViews.styles';
import { setCsvToExport } from '../../../redux/actions/Admin.action';
import {
  AuthUserProps,
  CensusReportList,
  CensusReport as ICensusReport,
  RootState
} from '../../../config/interfaces';
import CensusReportTable from '../../../components/customTables/censusReportTable';
import CensusHeader from '../../../components/searchHeader/censusReportHeader/CensusReportHeader';
import { date } from '../../../utils/helpers';
import { getCensusReport } from '../../../redux/actions/Report.action';

interface CensusReportProps {
  censusReport: CensusReportList[];
  authorizedUser: AuthUserProps;
  selectedUnits: { id: string; unit: string }[];
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const CensusReport: FC<CensusReportProps> = ({
  censusReport,
  authorizedUser,
  selectedUnits,
  searchTerm,
  setSearchTerm
}) => {
  const dispatch = useDispatch();
  const today = new Date();

  const [csvData, setCsvData] = useState<ICensusReport[]>([]);
  const [listToDisplay, setListToDisplay] = useState<ICensusReport[]>([]);
  const [customStartDate, setCustomStartDate] = useState<string>(
    format(today, 'yyyy-MM-dd')
  );
  const [customEndDate, setCustomEndDate] = useState<string>(
    format(today, 'yyyy-MM-dd')
  );

  useEffect(() => {
    // when dates change
    dispatch(
      getCensusReport({
        facilityId: authorizedUser.facilityId,
        startday: date.formatFilterStartDate(customStartDate),
        endday: date.formatFilterEndDate(customEndDate)
      })
    );
  }, [customEndDate]);

  useEffect(() => {
    let cleanData;

    if (censusReport?.length) {
      const censusByUnitFilter = censusReport.filter((c: CensusReportList) =>
        selectedUnits.some(unit => unit.id === c.unitId)
      );
      const cleanCensus = censusByUnitFilter.map((item: CensusReportList) => {
        return {
          unit: item.unitName,
          unitId: item.unitId,
          room: item.room,
          patient: `${item.lastName}, ${item.firstName}`,
          mrn: item.mrn,
          lastRoundDate:
            item.rounds.length > 0
              ? `${date.dayOfYr(
                  item.rounds[0]?.roundDate
                )} @ ${date.localTimeOfDay(item.rounds[0].roundDate)}`
              : '',
          lastRoundedBy:
            item.rounds.length > 0
              ? `${item.rounds[0].userFirstName} ${item.rounds[0].userLastName}`
              : '',
          status:
            item.status === 'none'
              ? 'Not Rounded'
              : item.status === 'rounded'
              ? 'Rounded'
              : 'Unable to Round'
        };
      });

      // filter by patient
      if (searchTerm.length) {
        cleanData = cleanCensus.filter(
          (o: ICensusReport) =>
            o.patient
              ?.toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            `${o.patient?.split(', ')[1]} ${o.patient?.split(', ')[0]}`
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            `${o.patient?.split(', ')[0]} ${o.patient?.split(', ')[1]}`
              .toLowerCase()
              .includes(searchTerm.trim().toLowerCase()) ||
            o.mrn?.toLowerCase().includes(searchTerm.trim().toLowerCase())
        );
      } else {
        cleanData = cleanCensus;
      }

      setListToDisplay(cleanData);
      setCsvData(cleanData);
    }
  }, [censusReport, selectedUnits, searchTerm]);

  const handleExportCsv = () => {
    dispatch(setCsvToExport(csvData));
  };

  return (
    <CReportContainer id="Census-Report-Container">
      <CensusHeader
        setCustomStartDate={setCustomStartDate}
        setCustomEndDate={setCustomEndDate}
        customStartDate={customStartDate}
        customEndDate={customEndDate}
        handleExportCsv={handleExportCsv}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
      />
      <CensusReportTable censusList={listToDisplay} setCSV={setCsvData} />
    </CReportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    censusReport: state.ReportReducer.censusReport,
    selectedUnits: state.ConfigReducer.selectedUnits,
    units: state.ConfigReducer.units
  };
};

export default connect(mapReduxStateToProps)(CensusReport);

/**
 * External Imports
 */
import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
/**
 * Internal Imports
 */
import SideNav from '../../components/sideNav/adminSideNav/AdminSideNav';
import TemplatesTree from '../../components/templatesTree';
import EditMasterTemplate from '../../components/editMasterTemplate/EditMasterTemplate';

import store from '../../redux/store';
import {
  clearChosenTemplate,
  updateMasterTemplateSuccessFlags
} from '../../redux/actions/Template.action';
/**
 * Global Type Definition Imports
 */
import { RootState } from '../../config/interfaces';
/**
 * Style Imports
 */
import { TemplatesContainer } from './styles/AdminViews.styles';

interface TemplateProps {
  createMasterTemplateSuccessFlag: boolean;
  editMasterTemplateSuccessFlag: boolean;
}

const Templates: FC<TemplateProps> = ({
  createMasterTemplateSuccessFlag,
  editMasterTemplateSuccessFlag
}) => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<string>('patient');
  const [editTemplateFlag, setEditTemplateFlag] = useState<boolean>(false);

  useEffect(() => {
    dispatch(clearChosenTemplate());
    store.dispatch(
      updateMasterTemplateSuccessFlags({
        createMasterTemplateSuccessFlag: false,
        editMasterTemplateSuccessFlag: false
      })
    );
    return () => {
      dispatch(clearChosenTemplate());
    };
  }, []);

  useEffect(() => {
    if (editMasterTemplateSuccessFlag) {
      setEditTemplateFlag(false);
    }
    if (createMasterTemplateSuccessFlag) {
      setEditTemplateFlag(true);
    }
  }, [createMasterTemplateSuccessFlag, editMasterTemplateSuccessFlag]);

  const menuItems = [
    { tabLabel: 'Patient Templates', tabId: 'patient' },
    { tabLabel: 'Employee Templates', tabId: 'employee' },
    { tabLabel: 'Support Templates', tabId: 'support' }
  ];

  return (
    <TemplatesContainer>
      {!editTemplateFlag && (
        <>
          <SideNav
            navItems={menuItems}
            defaultColor="plain-0"
            activeColor="primary-10"
            className="pl-3"
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <TemplatesTree
            roundingType={selectedTab}
            setEditTemplateFlag={setEditTemplateFlag}
          />
        </>
      )}
      {editTemplateFlag && (
        <EditMasterTemplate setEditTemplateFlag={setEditTemplateFlag} />
      )}
    </TemplatesContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  createMasterTemplateSuccessFlag:
    state.TemplateReducer.createMasterTemplateSuccessFlag,
  editMasterTemplateSuccessFlag:
    state.TemplateReducer.editMasterTemplateSuccessFlag
});

export default connect(mapReduxStateToProps)(Templates);

import { FC, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { NeuIcon, NeuButton, NeuCardTitle } from '@neutron/react';
import {
  ModalContainer,
  CardHeader,
  ModalCardContent,
  InputHeading,
  ListContainer,
  ListRow,
  ListHeader,
  EmployeeNameInTable,
  TextInTable,
  RemoveButton,
  ModalFooter
} from './EmployeeRoundingSettingsModal.styles';

import EmployeeSearch from '../reusableModalPieces/employeeSearch';

import store from '../../../redux/store';

import {
  addEmployeeToUser,
  removeEmployeeFromUser,
  addDelegateToUser,
  removeDelegateFromUser
} from '../../../redux/actions/User.action';

import { AuthUserProps, Employee, RootState } from '../../../config/interfaces';
import { handleModal } from '../../../services/ModalPortal/utils';

interface ModalProps {
  authorizedUser: AuthUserProps;
  searchedEmployees?: Employee[];
  myEmployees?: Employee[];
  employeesDelegatedTo: Employee[];
  modalType: string;
}

// CAN BE USED FOR Add Employees, Add Delegates,and Edit Rounding Access.
const EmployeeRoundingSettingsModal: FC<ModalProps> = ({
  authorizedUser,
  searchedEmployees,
  myEmployees,
  employeesDelegatedTo,
  modalType
}) => {
  const [listToDisplay, setListToDisplay] = useState<Employee[]>([]);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  // Sorting the list A-Z for initial display
  useEffect(() => {
    if (myEmployees && modalType === 'Add Employees') {
      const sorted = myEmployees.sort((a: Employee, b: Employee) =>
        a.firstName > b.firstName ? 1 : -1
      );
      setListToDisplay(sorted);
    }

    if (myEmployees && modalType === 'Add Delegates') {
      const sorted = employeesDelegatedTo.sort((a: Employee, b: Employee) =>
        a.firstName > b.firstName ? 1 : -1
      );
      setListToDisplay(sorted);
    }
  }, [myEmployees, employeesDelegatedTo, authorizedUser]);

  const handleEmployeeSearchSelected = (employee: Employee) => {
    // existing users are not allowed to add
    if (modalType === 'Add Employees') {
      if (
        // existing user not allowed
        !myEmployees?.some(
          (existingUser: Employee) => existingUser.hcaid === employee.hcaid
        ) &&
        // authorized user not allowed
        employee.hcaid !== authorizedUser.hcaid
      ) {
        const payload = {
          userId: authorizedUser.hcaid,
          employeeId: employee.hcaid
        };
        store.dispatch(addEmployeeToUser(payload));
      }
    }

    if (modalType === 'Add Delegates') {
      if (
        // existing user not allowed
        !employeesDelegatedTo?.some(
          (existingUser: Employee) => existingUser.hcaid === employee.hcaid
        ) &&
        // authorized user not allowed
        employee.hcaid !== authorizedUser.hcaid
      ) {
        const payload = {
          userId: authorizedUser.hcaid,
          delegateIds: [employee.hcaid]
        };
        store.dispatch(addDelegateToUser(payload));
      }
    }
  };

  const handleRemoveEmployee = (employee: Employee) => {
    if (modalType === 'Add Employees') {
      const payload = {
        userId: authorizedUser.hcaid,
        employeeId: employee.hcaid
      };
      store.dispatch(removeEmployeeFromUser(payload));
    }
    if (modalType === 'Add Delegates') {
      const payload = {
        userId: authorizedUser.hcaid,
        delegateId: employee.hcaid
      };
      store.dispatch(removeDelegateFromUser(payload));
    }
  };

  return (
    <ModalContainer
      className="show-modal"
      id="Employee-Rounding-Settings-Modal"
      type="submit"
      maxWidth="413px"
      height="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        {modalType === 'Add Employees' ? (
          <NeuCardTitle>Add Employees</NeuCardTitle>
        ) : modalType === 'Add Delegates' ? (
          <NeuCardTitle>Add Delegates</NeuCardTitle>
        ) : null}

        <NeuButton
          id="Employee-Rounding-Settings-Modal-Close-Button"
          fill="flat"
          onClick={() => handleModal(modalContentRef)}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <ModalCardContent>
        {
          modalType === 'Add Employees' || modalType === 'Add Delegates' ? (
            <div>
              <InputHeading>
                {modalType === 'Add Employees'
                  ? 'Add Employees'
                  : 'Add Delegates'}
              </InputHeading>
              <EmployeeSearch
                val={searchedEmployees}
                setEmployeeSelection={(emp: Employee) =>
                  handleEmployeeSearchSelected(emp)
                }
                showLabel={false}
                handleRemoveEmployee={() => {}}
              />

              {modalType === 'Add Employees' ||
              modalType === 'Add Delegates' ? (
                <div style={{ marginTop: '25px' }}>
                  <ListHeader>
                    {modalType === 'Add Employees'
                      ? 'My Employees'
                      : 'My Delegates'}
                  </ListHeader>
                  <ListContainer>
                    {listToDisplay.map((employee: Employee) => {
                      return (
                        <ListRow>
                          <div style={{ width: '33%' }}>
                            <EmployeeNameInTable
                              style={{ fontWeight: '700' }}
                            >{`${employee.firstName} ${employee.lastName}`}</EmployeeNameInTable>

                            <TextInTable>{employee.hcaid}</TextInTable>
                          </div>
                          <div style={{ width: '46%' }}>
                            <TextInTable>{employee.title}</TextInTable>
                            <TextInTable>{employee.dept}</TextInTable>
                          </div>
                          <RemoveButton
                            onClick={() => handleRemoveEmployee(employee)}
                          >
                            <NeuIcon color="danger">remove_circle</NeuIcon>{' '}
                            {` `}
                            Remove
                          </RemoveButton>
                        </ListRow>
                      );
                    })}
                  </ListContainer>
                </div>
              ) : (
                <div style={{ marginTop: '25px' }}>
                  <ListHeader>My Delegates</ListHeader>
                </div>
              )}
            </div>
          ) : null // Add the content of Rounding Access modal here
        }
      </ModalCardContent>
      <ModalFooter />
    </ModalContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    searchedEmployees: state.EmployeeReducer.searchedEmployees,
    modalType: state.ModalReducer.modalType,
    myEmployees: state.EmployeeReducer.myEmployees,
    employeesDelegatedTo: state.EmployeeReducer.employeesDelegatedTo
  };
};

export default connect(mapReduxStateToProps)(EmployeeRoundingSettingsModal);

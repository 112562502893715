import { all, call, put, select } from 'redux-saga/effects';

import { getData, postData } from '../../services/API/axios';
import { date } from '../../utils/helpers';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';

import {
  getPatientRoundHistory,
  getEmployeeRoundHistory
} from '../actions/Report.action';
import { getTaskByAccountNumber } from '../actions/Task.action';
import { getPatientsToRound } from '../actions/User.action';
import { setChosenRoundTemplate } from '../actions/Template.action';
import {
  setCurrentRound,
  setRoundEditing,
  setRoundViewOnly
} from '../actions/Round.action';

import { postNewTask } from './Task.saga';
import {
  getEmployeesToRound,
  getCSRNSubjectsToRoundForList,
  getCNEdSubjectsToRoundForList
} from '../actions/Employee.action';

// Round sagas

export function* postRound() {
  // Select round data from the store
  const { currentRound, isRoundStarted, pendingFeedbacks, pendingIssues } =
    yield select(state => state.RoundReducer);
  const { facilityId } = yield select(
    state => state.AuthorizedUser.authorizedUser
  );

  // If there is no round data, return
  if (!Object.keys(currentRound).length > 0) return;

  const url = `${API.postRoundUrl}`;
  const multiShare = pendingIssues.length + pendingFeedbacks.length > 1;

  try {
    // if Issues attached to a round post it to /task
    if (
      (pendingIssues.length > 0 || pendingFeedbacks.length > 0) &&
      isRoundStarted
    ) {
      yield all([
        ...pendingFeedbacks.map(fb =>
          call(postNewTask, {
            data: {
              ...fb,
              roundId: currentRound.roundId,
              multiShare
            }
          })
        ),
        ...pendingIssues.map(issue =>
          call(postNewTask, {
            data: {
              ...issue,
              roundId: currentRound.roundId,
              multiShare
            }
          })
        )
      ]);
      if (multiShare) {
        yield put({
          type: ACTIONS.TASK.CLEAR_SHARING_Q
        });
      }
      toast('All round tasks posted!', 'success', 1000, 500);
      // add the taskIds to the round
      currentRound.taskIds = [
        ...currentRound.taskIds,
        ...pendingIssues.map(issue => issue.taskId),
        ...pendingFeedbacks.map(fb => fb.taskId)
      ];
    }
    // Call the POST /round endpoint with the round payload
    const { status } = yield call(postData, url, currentRound);
    // If successful remove loading flags from the store
    if (status === 200) {
      // if issues attached
      if (
        (pendingIssues.length > 0 || pendingFeedbacks.length > 0) &&
        isRoundStarted &&
        currentRound.accountNum
      ) {
        yield put(
          getTaskByAccountNumber({
            accountNumber: currentRound.accountNum,
            facilityMnemonic: facilityId
          })
        );
      }
      const { roundingType } = currentRound;
      yield put({
        type: ACTIONS.ROUND.COMPLETE_ROUND_SUCCESS
      });
      // Update store with new round info
      if (roundingType === 'patient') {
        // TODO: Is this necessary?
        yield put(
          getPatientsToRound({
            facilityId
          })
        );
        yield put(
          getPatientRoundHistory({
            facilityId,
            patientId: currentRound.patientId || currentRound.accountNum
          })
        );
      } else if (roundingType === 'employee') {
        // TODO: Is this necessary?
        yield put(getEmployeesToRound({ hcaid: currentRound.userId }));
        yield put(
          getEmployeeRoundHistory({
            employeeId: currentRound.employeeId,
            type: 'employee'
          })
        );
      } else {
        // TODO: Is this necessary?
        if (roundingType === 'csrn') {
          yield put(
            getCSRNSubjectsToRoundForList({
              hcaid: currentRound.userId
            })
          );
        } else {
          yield put(
            getCNEdSubjectsToRoundForList({
              hcaid: currentRound.userId
            })
          );
        }
        yield put(
          getEmployeeRoundHistory({
            employeeId: currentRound.employeeId,
            type: 'support',
            supportType: roundingType
          })
        );
      }
    } else {
      toast('Round failed to Post!', 'error', null, 500, true);
      yield put({
        type: ACTIONS.ROUND.COMPLETE_ROUND_FAILURE
      });
    }
  } catch (error) {
    console.log(error);
    toast('Round failed to Post!', 'error', null, 500, true);
    yield put({
      type: ACTIONS.ROUND.COMPLETE_ROUND_FAILURE
    });
  }
}

export function* getRound(action) {
  const { roundId, fromHistorydrawer } = action.data;

  const url = `${API.getRoundUrl}?roundId=${roundId}`;

  try {
    const response = yield call(getData, url);
    const { hcaid } = yield select(
      state => state.AuthorizedUser.authorizedUser
    );
    const accountNum = yield select(
      state => state.UserReducer.selectedPatient?.accountNum
    );
    if (response.status === 200) {
      if (
        accountNum &&
        accountNum.substring(0, 2) === 'UL' &&
        !fromHistorydrawer
      ) {
        yield put({
          type: ACTIONS.REPORT.GET_PATIENT_ROUND_HISTORY_SUCCESS,
          data: response.data
        });
        return yield put({
          type: ACTIONS.ROUND.GET_ROUND_SUCCESS
        });
      }
      const roundDate = response.data[0].origRoundDate;
      const { userId } = response.data[0];
      const createdByCurrentUser = userId === hcaid;
      const isPast24Hrs = date.isOverOneDayOld(roundDate);

      // If round occurred more than 1 day ago or was created by someone else set to view only
      if (isPast24Hrs || !createdByCurrentUser) {
        yield put(setRoundViewOnly());
      } else {
        yield put(setRoundEditing());
      }

      yield put({
        type: ACTIONS.ROUND.SET_PAST_ROUND_ID,
        data: response.data[0].roundId
      });

      yield put(setChosenRoundTemplate(response.data[0]));
      yield put(setCurrentRound(response.data[0]));
      return yield put({
        type: ACTIONS.ROUND.GET_ROUND_SUCCESS
      });
    }
    return yield put({
      type: ACTIONS.ROUND.GET_ROUND_FAILURE
    });
  } catch (error) {
    return yield put({
      type: ACTIONS.ROUND.GET_ROUND_FAILURE
    });
  }
}

import { FC, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';

import FeatureRoute from '../featureRoute/FeatureRoute';
import CSRNList from './supportLists/CSRNList';
import CNEdList from './supportLists/CNEdList';
import SupportProfile from './SupportProfile';

import { clearAllDepts } from '../../redux/actions/Employee.action';
import {
  resetUserSectionType,
  setUserSectionType
} from '../../redux/actions/User.action';

import { useFeatures } from '../../services/Features/features';

import { RootState } from '../../config/interfaces';

const Support: FC<{ authRoles: string[] }> = ({ authRoles }) => {
  const dispatch = useDispatch();

  const { isCSRNEnabled, isCNEdEnabled } = useFeatures();

  const [searchTerm, setSearchTerm] = useState<string>('');

  const isCSRNRounder = useMemo(
    () =>
      authRoles.includes('csrnRounder') ||
      authRoles.includes('enterpriseAdmin'),
    [authRoles]
  );

  const isCNEdRounder = useMemo(
    () =>
      authRoles.includes('cnedRounder') ||
      authRoles.includes('enterpriseAdmin'),
    [authRoles]
  );

  useEffect(() => {
    dispatch(setUserSectionType('support'));
    return () => {
      dispatch(resetUserSectionType());
      dispatch(clearAllDepts());
    };
  }, []);

  return (
    <div>
      <Routes>
        <Route
          index
          element={<Navigate to={isCSRNRounder ? 'csrn-list' : 'cned-list'} />}
        />
        {isCSRNRounder && (
          <Route
            path="csrn-list"
            element={
              <FeatureRoute
                component={
                  <CSRNList
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                }
                isFeatureEnabled={isCSRNEnabled}
                offsetHeight={72}
              />
            }
          >
            <Route
              path=":csrnFilter"
              element={
                <CSRNList
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
          </Route>
        )}
        {isCNEdRounder && (
          <Route
            path="cned-list"
            element={
              <FeatureRoute
                component={
                  <CNEdList
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                  />
                }
                isFeatureEnabled={isCNEdEnabled}
                offsetHeight={72}
              />
            }
          >
            <Route
              path=":cnedFilter"
              element={
                <CSRNList
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                />
              }
            />
          </Route>
        )}
        <Route path="profile" element={<Outlet />}>
          <Route path=":supportType" element={<SupportProfile />} />
        </Route>
        <Route
          path="*"
          element={<Navigate to={localStorage.getItem('lastPath') || '/'} />}
        />
      </Routes>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authRoles: state.AuthorizedUser.authRoles
});

export default connect(mapReduxStateToProps)(Support);

/**
 * External Imports
 */
import { FC, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import { NeuTab, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import store from '../../../redux/store';
import { clearSelectedEmployee } from '../../../redux/actions/User.action';
import {
  logCOGReportClick,
  logHRInsightsClick,
  logTableStart
} from '../../../utils/analyticsHelpers';
import { setLastPath } from '../../../utils/helpers';

/**
 * Global Type Definition Imports
 */
import { RootState } from '../../../config/interfaces';

/**
 * Styling Imports
 */
import {
  PopItem,
  PopIcon,
  IconPositioning,
  InlineRelativeContainer,
  ReportDropDownContainer,
  PopContent
} from '../../Header/mainNav/MainNav.styles';

interface ReportDropdownProps {
  authUserRoles: Array<string>;
}

const ReportDropdown: FC<ReportDropdownProps> = ({ authUserRoles }) => {
  const [reportDropdownState, setReportDropdownState] = useState(false);
  const [, setShowPopover] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logTrackingEvent } = useAnalyticsApi();

  return (
    <>
      {authUserRoles &&
        (authUserRoles?.includes('enterpriseAdmin') ||
          authUserRoles?.includes('patientRounder') ||
          authUserRoles?.includes('employeeRounder') ||
          authUserRoles?.includes('csrnRounder')) && (
          <NeuTab
            id="Reports-Tab"
            onBlur={() => setReportDropdownState(false)}
            navy
            active={pathname.includes('reports')}
            onClick={() => setReportDropdownState(!reportDropdownState)}
          >
            <IconPositioning>
              Reports
              <NeuIcon small>arrow_drop_down</NeuIcon>
            </IconPositioning>
          </NeuTab>
        )}
      <InlineRelativeContainer onBlur={() => setShowPopover(false)}>
        <ReportDropDownContainer showReportDropdown={reportDropdownState}>
          {(authUserRoles?.includes('enterpriseAdmin') ||
            authUserRoles?.includes('employeeRounder')) && (
            <PopItem
              button
              onMouseDown={() => {
                window.open(
                  'https://hrinsights.medcity.net/analytics/emp-rounding',
                  '_blank'
                );
                logTrackingEvent(logHRInsightsClick());
              }}
            >
              <PopContent>
                HR Insights <PopIcon>launch</PopIcon>
              </PopContent>
            </PopItem>
          )}

          {(authUserRoles?.includes('enterpriseAdmin') ||
            authUserRoles?.includes('csrnRounder')) && (
            <PopItem
              button
              onMouseDown={() =>
                window.open(
                  'https://app.powerbi.com/groups/me/apps/1384df98-12c7-4007-a470-a692392a8f60/reports/54e09b8c-a85a-4120-9fa4-c8f8df8daa1d?ctid=335a6d75-c3eb-4b1a-ac08-d49338816ca0&experience=power-bi',
                  '_blank'
                )
              }
            >
              <PopContent>
                CSRN Reports <PopIcon>launch</PopIcon>
              </PopContent>
            </PopItem>
          )}

          {(authUserRoles?.includes('enterpriseAdmin') ||
            authUserRoles?.includes('patientRounder') ||
            authUserRoles?.includes('employeeRounder')) && (
            <>
              {(authUserRoles?.includes('enterpriseAdmin') ||
                authUserRoles?.includes('patientRounder')) && (
                <>
                  <PopItem
                    button
                    onMouseDown={() => {
                      window.open(
                        'https://www.powerbi.com/groups/me/apps/1384df98-12c7-4007-a470-a692392a8f60/reports/206e4c55-8696-467a-a80d-2d1a3d7ba420/ReportSection3c7d73a9f3f288b13269',
                        '_blank'
                      );
                      logTrackingEvent(logCOGReportClick());
                    }}
                  >
                    <PopContent>
                      COG Patient Reports <PopIcon>launch</PopIcon>
                    </PopContent>
                  </PopItem>
                  <PopItem
                    button
                    onMouseDown={() => {
                      setLastPath('/reports/patient');
                      navigate('/reports/patient');
                      logTrackingEvent(
                        logTableStart('/reports/patient/compliance')
                      );
                    }}
                  >
                    <PopContent>Patient Reports</PopContent>
                  </PopItem>
                </>
              )}
              {(authUserRoles?.includes('enterpriseAdmin') ||
                authUserRoles?.includes('employeeRounder')) && (
                <PopItem
                  button
                  onMouseDown={() => {
                    store.dispatch(clearSelectedEmployee());
                    setLastPath('/reports/employee');
                    navigate('/reports/employee');
                    logTrackingEvent(logTableStart('/reports/employee/rounds'));
                  }}
                >
                  <PopContent>Employee Reports</PopContent>
                </PopItem>
              )}
            </>
          )}
        </ReportDropDownContainer>
      </InlineRelativeContainer>
    </>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authUserRoles: state.AuthorizedUser.authorizedUser?.roles
  };
};

export default connect(mapReduxStateToProps)(ReportDropdown);

/**
 * External Imports
 */
import { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuDivider } from '@neutron/react';
import { NeuInputChangeEventDetail } from '@neutron/core';
/**
 * Internal Imports
 */
import { BlueChip } from '../../../shared/chips/blueChip';

import {
  searchPatientHistory,
  clearPatientSearch
} from '../../../../redux/actions/Report.action';

import { useDebounceValue } from '../../../../utils/debouncers';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  Patient,
  RootState
} from '../../../../config/interfaces';
/**
 * Style Imports
 */
import { ChipWrapper } from '../../feedbackModal/FeedbackModal.styles';
import {
  DropItem,
  DropDownSearchContainer,
  ModalInput,
  InputHeading
} from './PatientSearch.styles';

interface PatientSearchProps {
  authorizedUser: AuthUserProps;
  searchedPatients: [];
  chipVal?: string;
  // using type any here so that this can be re-used in multiple places by passing down whatever state object is being modified with a patient search
  // TODO: Revisit any
  propObject: any;
  setPatientSelection: (patient: Patient) => void;
  handleRemovePatient: (data: string) => void;
  showLabel?: boolean; // true by default
}

const PatientSearch: FC<PatientSearchProps> = ({
  authorizedUser,
  searchedPatients,
  chipVal,
  propObject,
  setPatientSelection,
  handleRemovePatient,
  showLabel
}) => {
  const dispatch = useDispatch();
  const [patientSearchTerm, setPatientSearchTerm] = useState('');
  const [patientPopState, setPatientPopState] = useState<boolean>(false);
  const [patientSearchState, setPatientSearchState] = useState<boolean>(true);
  const [showLabelState] = useState<boolean>(showLabel !== false);
  const [sortedPatients, setSortedPatients] = useState([]);

  const dbPatientSearchTerm = useDebounceValue(patientSearchTerm, 250);

  useEffect(() => {
    if (patientSearchTerm.length > 2) {
      dispatch(
        searchPatientHistory({
          facilityId: authorizedUser.facilityId,
          patientSearch: dbPatientSearchTerm
        })
      );
    }
  }, [dbPatientSearchTerm]);

  useEffect(() => {
    if (searchedPatients.length > 0) {
      const sorted = searchedPatients.sort((a: Patient, b: Patient) =>
        a.lastname! > b.lastname! ? 1 : -1
      );
      setSortedPatients(sorted);
      setPatientPopState(true);
    }
  }, [searchedPatients]);

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    setPatientSearchState(true);
    setPatientSearchTerm(target.value);
  };

  const selectPatient = (patient: Patient) => {
    // using function passed as prop to set patient
    setPatientSelection(patient);
    // handling local changes
    setPatientSearchState(false);
    setPatientSearchTerm('');
    setPatientPopState(false);
    dispatch(clearPatientSearch());
  };

  // ending the patient search on blur so that it doesn't persist when re-opened
  const handleBlur = () => {
    setPatientSearchState(false);
    setPatientSearchTerm('');
    setPatientPopState(false);
    dispatch(clearPatientSearch());
  };

  return (
    <div id="Modal-Patient-Search">
      {showLabelState ? <InputHeading>Patient</InputHeading> : null}
      {chipVal ? (
        <ChipWrapper onClick={() => handleRemovePatient('patient')}>
          <BlueChip key={chipVal} name={chipVal} removable />
        </ChipWrapper>
      ) : (
        <div style={{ position: 'relative' }} onBlur={handleBlur}>
          <ModalInput
            id="Modal-Patient-Search-Input"
            type="search"
            name="patient"
            autocomplete="off"
            placeholder="Start typing name or MRN"
            inputmode="search"
            enterkeyhint="search"
            autocorrect="off"
            value={patientSearchState ? patientSearchTerm : propObject.patient}
            onNeuChange={patientSearchState ? handleSearching : () => {}}
            onNeuFocus={() => setPatientSearchState(true)}
          />
          <DropDownSearchContainer
            id="Modal-Patient-Search-Dropdoown"
            showPopover={patientPopState}
          >
            {sortedPatients.map((patient: Patient) => (
              <div key={`${patient.accountNumber}:${patient.mrn}`}>
                <DropItem onMouseDown={() => selectPatient(patient)} button>
                  <p>
                    {patient.firstname} {patient.lastname}
                  </p>
                  <p style={{ fontSize: '14px' }}>{patient.mrn}</p>
                </DropItem>
                {sortedPatients.length > 1 && <NeuDivider />}
              </div>
            ))}
          </DropDownSearchContainer>
        </div>
      )}
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    searchedPatients: state.ReportReducer.searchedPatients
  };
};

export default connect(mapReduxStateToProps)(PatientSearch);

/**
 * External Imports
 */
import { FC, Dispatch, SetStateAction, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { NeuTooltip, NeuIcon, NeuLabel, NeuHint } from '@neutron/react';
/**
 * Internal Imports
 */
import AddBtn from './addBtn';
import AddQuestionBtn from './addQuestionBtn';
import ExportCsvBtn from './exportCsvBtn';
import DischargeBtn from './dischargeBtn/DischargeBtn';
import FilterBtn from './filterBtn';
import FeedbackBtn from './feedbackBtn';
import ProvisioningBtn from './provisioningBtn';
import DownloadBtn from '../shared/buttons/downloadBtn';
import SearchEmployeesBtn from './searchEmployeesBtn';
import SearchField from './searchField';
import SelectTemplateDropDown from './selectTemplatesDropdown';
import CustomDateRangeSelect from './customDateRangeSelect';
import UnlistedRoundBtn from './unlistedRoundBtn';
/**
 * Global Type Definition Imports
 */
import { DatesStartEnd, RootState } from '../../config/interfaces';
/**
 * Style Imports
 */
import { SearchHeaderBar, HintSection } from './SearchHeader.styles';

interface SearchHeaderProps {
  searchTerm: string;
  authRoles: Array<string>;
  customDates?: DatesStartEnd;
  setCustomDates?: Dispatch<SetStateAction<DatesStartEnd>>;
  handleExportCsv?: () => void;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const SearchHeader: FC<SearchHeaderProps> = ({ authRoles, ...props }) => {
  const { '*': view } = useParams();
  const location = useLocation();
  useEffect(() => {
    props.setSearchTerm('');
  }, [location]);

  return (
    <SearchHeaderBar
      id="Search-Header"
      style={
        view === 'employee' ||
        view === 'employee/rounds' ||
        view === 'employee/frequency'
          ? { height: '90px', paddingTop: '8px' }
          : {}
      }
    >
      {view === 'question-bank' && (
        <div style={{ display: 'flex' }}>
          <SelectTemplateDropDown type="questionBank" />
          <SearchField {...props} />
        </div>
      )}
      {view !== 'question-bank' && <SearchField {...props} />}
      {(view === 'employee' ||
        view === 'employee/rounds' ||
        view === 'employee/frequency') &&
        props.customDates &&
        props.setCustomDates && (
          <CustomDateRangeSelect
            customDates={props.customDates}
            setCustomDates={props.setCustomDates}
          />
        )}
      {view === 'census' && <UnlistedRoundBtn />}
      {(view === 'list' ||
        view?.includes('csrn-list') ||
        view?.includes('cned-list')) && <SearchEmployeesBtn />}

      {(view === 'issues' ||
        view === 'feedback' ||
        view === 'stoplight' ||
        view === 'users') && <FilterBtn />}
      {view === 'patient/rounds' && (
        <HintSection>
          <div>
            <FilterBtn />
          </div>
          <NeuTooltip className="mt-1">
            <NeuIcon small color="primary" slot="content" className="ml-1">
              help
            </NeuIcon>
            <NeuLabel
              slot="tooltip"
              background-color="primary-100"
              style={{ textAlign: 'left' }}
            >
              Rounds equals the number of completed patient rounds (able to
              round plus unable to round) <br />
              in the selected unit(s) for the selected date range. The units and
              date range <br />
              selections can be changed using the drop-down menu and orange
              filter button.
            </NeuLabel>
          </NeuTooltip>
          <NeuHint color="primary" className="ml-2 mb-3 mt-2">
            Report Metrics
          </NeuHint>
        </HintSection>
      )}
      {(view === 'employee' || view === 'employee/rounds') && (
        <HintSection>
          <div>
            <FilterBtn />
          </div>
          <NeuTooltip className="mt-1">
            <NeuIcon small color="primary" slot="content" className="ml-1">
              help
            </NeuIcon>
            <NeuLabel slot="tooltip" background-color="primary-100">
              Rounds displays the most recently completed round on an employee
              during the selected date range. <br />
              The date range and round type selection can be changed using the
              drop-down and orange filter.
            </NeuLabel>
          </NeuTooltip>
          <NeuHint color="primary" className="ml-2 mb-3 mt-2">
            Report Metrics
          </NeuHint>
        </HintSection>
      )}
      {view === 'issues' && (
        <div style={{ display: 'flex' }}>
          <AddBtn />
          <DownloadBtn fileNamePrefix="Issue List" />
        </div>
      )}
      {view === 'stoplight' && (
        <div style={{ display: 'flex' }}>
          <AddBtn />
          <DownloadBtn fileNamePrefix="StopLight List" />
        </div>
      )}
      {view === 'users' && (
        // align the button group to right
        <div style={{ display: 'flex' }}>
          <ProvisioningBtn />
          <ExportCsvBtn
            handleExportCsv={props.handleExportCsv}
            fileNamePrefix="Users List"
          />
        </div>
      )}
      {view === 'question-bank' && (
        // align the button group to right
        <div style={{ display: 'flex' }}>
          {authRoles.includes('enterpriseAdmin') && <AddQuestionBtn />}
          <ExportCsvBtn
            fileNamePrefix="Question Bank"
            handleExportCsv={props.handleExportCsv}
          />
        </div>
      )}
      {view === 'discharges' && <DischargeBtn />}
      {view === 'feedback' && <FeedbackBtn />}

      {(view === 'patient/rounds' ||
        view === 'employee' ||
        view === 'employee/rounds') && (
        <div style={{ display: 'flex' }}>
          <ExportCsvBtn
            handleExportCsv={props.handleExportCsv}
            fileNamePrefix="Rounds Report"
          />
        </div>
      )}

      {view === 'employee/frequency' && (
        <>
          <HintSection>
            <NeuTooltip className="mt-1">
              <NeuIcon small color="primary" slot="content" className="ml-1">
                help
              </NeuIcon>
              <NeuLabel slot="tooltip" background-color="primary-100">
                Frequency count is calculated by the number of completed
                employee rounds per employee in each department. <br />
                The date range selections can be changed using the drop-down
                menu.
              </NeuLabel>
            </NeuTooltip>
            <NeuHint color="primary" className="ml-2 mb-3 mt-2">
              Report Metrics
            </NeuHint>
          </HintSection>
          <div>
            <ExportCsvBtn
              handleExportCsv={props.handleExportCsv}
              fileNamePrefix="Rounds Report"
            />
          </div>
        </>
      )}

      {view === 'feedback' && (
        <DownloadBtn
          fileNamePrefix="Feedback List"
          downloadStoplight={props.handleExportCsv}
        />
      )}
    </SearchHeaderBar>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authRoles: state.AuthorizedUser.authRoles
});

export default connect(mapReduxStateToProps)(SearchHeader);

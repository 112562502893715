/**
 * External Imports
 */
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { NeuTablist } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';
/**
 * Internal Imports
 */
import Events from '../../services/Analytics';

import { setLastPath } from '../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { RootState } from '../../config/interfaces';
/**
 * Style Imports
 */
import { ReusableToolbar, Tab } from './ReusableTabs.styles';
import store from '../../redux/store';

interface TabProps {
  tabItems: {
    base: string;
    links: { label: string; path: string }[];
  };
}

const ReusableTabs: FC<TabProps> = ({ tabItems }) => {
  const { '*': view } = useParams();
  const { coid } = store.getState().AuthorizedUser.authorizedUser;
  const { logTrackingEvent } = useAnalyticsApi();

  return (
    <ReusableToolbar id="Reusable-Toolbar">
      <NeuTablist color="plain">
        {tabItems.links.map(item => {
          return (
            <Link
              onClick={() => {
                const event = {
                  eventType: 'start',
                  org: {
                    orgId: coid,
                    orgLevel: 'Facility'
                  }
                };
                if (tabItems.base === '/employees') {
                  logTrackingEvent([
                    {
                      eventName: null,
                      module: Events.Employee.Module,
                      screen:
                        item.path === '/list'
                          ? Events.Employee.Screen.List.Name
                          : item.path === '/issues'
                          ? Events.Employee.Screen.Issues.Name
                          : item.path === '/feedback'
                          ? Events.Employee.Screen.Feedback.Name
                          : item.path === '/stoplight'
                          ? Events.Employee.Screen.Stoplights.Name
                          : Events.Employee.Screen.Profile.Name,
                      ...event
                    }
                  ]);
                } else if (tabItems.base === '/patients') {
                  logTrackingEvent([
                    {
                      eventName: null,
                      module: Events.Patient.Module,
                      screen:
                        item.path === '/census'
                          ? Events.Patient.Screen.Census.Name
                          : item.path === '/issues'
                          ? Events.Patient.Screen.Issues.Name
                          : item.path === '/feedback'
                          ? Events.Patient.Screen.Feedback.Name
                          : item.path === '/stoplight'
                          ? Events.Patient.Screen.Stoplights.Name
                          : Events.Patient.Screen.Profile.Name,
                      ...event
                    }
                  ]);
                } else if (tabItems.base === '/reports/patient') {
                  logTrackingEvent([
                    {
                      eventName: null,
                      module: Events.Report.Module,
                      screen:
                        item.path === '/compliance'
                          ? Events.Report.Screen.PatientCompliance.Name
                          : item.path === '/rounds'
                          ? Events.Report.Screen.PatientRounds.Name
                          : Events.Report.Screen.PatientCensus.Name,
                      ...event
                    }
                  ]);
                } else if (tabItems.base === '/reports/employee') {
                  logTrackingEvent([
                    {
                      eventName: null,
                      module: Events.Report.Module,
                      screen:
                        item.path === '/rounds'
                          ? Events.Report.Screen.EmployeeRounds.Name
                          : Events.Report.Screen.EmployeeFreq.Name,
                      ...event
                    }
                  ]);
                }
                setLastPath(tabItems.base + item.path);
              }}
              key={item.path}
              to={tabItems.base + item.path}
            >
              <Tab
                id={`Reusable-Tab-${item.label}`}
                active={
                  (view === 'employee' &&
                    item.path.split('/').pop() === 'rounds') ||
                  (view === 'patient' &&
                    item.path.split('/').pop() === 'compliance') ||
                  view?.includes(item.path.split('/').pop() || '')
                }
                gray-60
              >
                {item.label}
              </Tab>
            </Link>
          );
        })}
      </NeuTablist>
    </ReusableToolbar>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser?.authorizedUser
});

export default connect(mapReduxStateToProps)(ReusableTabs);

import { FC, useEffect, useState } from 'react';

import { connect, useDispatch } from 'react-redux';

import { NeuInputChangeEventDetail } from '@neutron/core';
import { NeuSpinner } from '@neutron/react';

import { getFacilityUnits } from '../../redux/actions/Config.action';
import {
  addFavorite,
  removeFavorite,
  updateCurrentFacility
} from '../../redux/actions/Account.action';
import {
  WrapperContainer,
  LeftContainer,
  InputHeading,
  FacilityInput,
  UnitListContainer,
  UnitList,
  UnitItemHeading,
  UnitItem,
  UnitLabel,
  IconFavoriteUnit,
  SpinnerContainer,
  ModalInput,
  DropDownSearchContainer,
  DropItem,
  UnitListContainerLeft,
  UnitListContainerRight
} from './Account.styles';
import { IFacility, RootState, Unit } from '../../config/interfaces';
import { toTitleCaseSpecial } from '../../utils/helpers';
import Loading from '../../components/shared/Loading';

interface IFacilityAndUnitSettingsProps {
  facilityId: string;
  userId: string;
  facilities: IFacility[];
  primaryUnit: {
    facilityId: string;
    locType: string;
    unit: string;
    unitId: string;
  }[];
  units: Unit[];
  primaryUnitsLoading: boolean;
  addRemoveFvouriteLoading: boolean;
}

const FacilityAndUnitSettings: FC<IFacilityAndUnitSettingsProps> = ({
  facilityId,
  userId,
  facilities,
  primaryUnit,
  units,
  primaryUnitsLoading,
  addRemoveFvouriteLoading
}) => {
  const dispatch = useDispatch();
  const [availableUnits, setAvailableUnits] = useState(units);
  const [availablePrimaryUnits, setAvailablePrimaryUnits] =
    useState(primaryUnit);
  const [selectedFacility, setSelectedFacility] = useState('');
  const [selectedFacilityName, setSelectedFacilityName] = useState('');
  const [facilitySearchTerm, setFacilitySearchTerm] = useState('');
  const [facilityPopState, setFacilityPopState] = useState<boolean>(false);
  const [facilitySearchState, setFacilitySearchState] =
    useState<boolean>(false);
  const [filteredFacilities, setFilteredFacilities] = useState(facilities);

  useEffect(() => {
    if (facilityId) {
      dispatch(getFacilityUnits({ facilityId, isUnfiltered: false }));
    }
  }, [facilityId]);

  useEffect(() => {
    if (units && units.length > 0 && primaryUnit) {
      const filteredPrimaryUnits = primaryUnit.filter(
        primary => primary.facilityId === facilityId
      );
      const filteredUnits = units.filter(
        (item: Unit) =>
          !filteredPrimaryUnits.find(
            primary =>
              item.unitId === primary.unitId &&
              item.facilityId === primary.facilityId
          )
      );
      setAvailableUnits(filteredUnits);
      setAvailablePrimaryUnits(filteredPrimaryUnits);
    }
  }, [primaryUnit, units]);

  useEffect(() => {
    if (selectedFacility && selectedFacility !== facilityId) {
      dispatch(
        updateCurrentFacility({
          userId,
          facilityId: selectedFacility
        })
      );
    }
  }, [selectedFacility]);

  const MarkAsFavorite = (item: Unit) => {
    dispatch(
      addFavorite({
        userId,
        unitId: item.unitId,
        facilityId: item.facilityId
      })
    );
  };
  const removeFromFavorite = (item: Unit) => {
    dispatch(
      removeFavorite({
        userId,
        unitId: item.unitId,
        facilityId: item.facilityId
      })
    );
  };

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    const searchInput = target?.value ? target?.value.trim() : '';
    setFacilitySearchTerm(target?.value);
    setFacilitySearchState(true);
    if (searchInput) {
      const searchKey = searchInput.toUpperCase();
      const resultFacility = facilities.filter(
        (item: { id: string; value: string; division: string }) => {
          return item.value.toUpperCase().includes(searchKey);
        }
      );
      setFilteredFacilities(resultFacility);
      setFacilityPopState(true);
    } else {
      setFilteredFacilities(facilities);
      setFacilityPopState(true);
    }
  };

  const setFacilityForUpdate = (selectedFac: {
    id: string;
    value: string;
    division: string;
  }) => {
    setSelectedFacility(selectedFac.id);
    setSelectedFacilityName(selectedFac.value);
    setFacilitySearchState(false);
    setFacilitySearchTerm('');
    setFacilityPopState(false);
  };

  const facilityName = (id: string) => {
    const foundFacility =
      facilities &&
      facilities.filter(
        (item: { id: string; value: string; division: string }) =>
          item.id === id
      );
    return foundFacility[0]?.value;
  };

  return (
    <WrapperContainer id="Facility-Unit-Settings">
      <LeftContainer>
        <FacilityInput>
          <InputHeading>Select Current Facility</InputHeading>
          <div
            style={{ position: 'relative' }}
            onBlur={() => setFacilityPopState(false)}
          >
            <ModalInput
              type="search"
              name="patient"
              autocomplete="off"
              placeholder="Search here for Facility"
              inputmode="search"
              enterkeyhint="search"
              autocorrect="off"
              value={
                facilitySearchState
                  ? facilitySearchTerm
                  : selectedFacilityName || facilityName(facilityId)
              }
              onNeuChange={facilitySearchState ? handleSearching : () => {}}
              onNeuFocus={() => setFacilitySearchState(true)}
              onNeuBlur={() => {
                setFacilitySearchState(false);
                setFacilitySearchTerm('');
                setFacilityPopState(false);
              }}
            />
            <DropDownSearchContainer showPopover={facilityPopState}>
              {filteredFacilities &&
                filteredFacilities.map(
                  (facility: {
                    id: string;
                    value: string;
                    division: string;
                  }) => (
                    <DropItem
                      onMouseDown={() => setFacilityForUpdate(facility)}
                      key={facility.id}
                      button
                    >
                      {toTitleCaseSpecial(facility.value)}
                    </DropItem>
                  )
                )}
            </DropDownSearchContainer>
          </div>
        </FacilityInput>
        <UnitListContainer>
          <UnitListContainerLeft>
            <UnitItemHeading>
              <UnitLabel>
                Unit List
                {primaryUnitsLoading || addRemoveFvouriteLoading ? (
                  <SpinnerContainer>
                    <NeuSpinner ariaLabel="default" width="18" height="18" />
                  </SpinnerContainer>
                ) : (
                  ''
                )}
              </UnitLabel>
            </UnitItemHeading>
            <UnitList color="plain-0" lines="inset">
              {availableUnits &&
                availableUnits.map(item => {
                  return (
                    <UnitItem key={item.unitId}>
                      <UnitLabel key={item.unitId}>
                        {item.unit}
                        <IconFavoriteUnit
                          onClick={() => MarkAsFavorite(item)}
                          key={item.unitId}
                        >
                          star_border
                        </IconFavoriteUnit>
                      </UnitLabel>
                    </UnitItem>
                  );
                })}
            </UnitList>
          </UnitListContainerLeft>
          <UnitListContainerRight>
            <UnitItemHeading>
              <UnitLabel>
                Favorite Units
                {primaryUnitsLoading || addRemoveFvouriteLoading ? (
                  <SpinnerContainer>
                    <NeuSpinner ariaLabel="default" width="18" height="18" />
                  </SpinnerContainer>
                ) : (
                  ''
                )}
              </UnitLabel>
            </UnitItemHeading>
            <UnitList color="plain-0" lines="inset">
              {availablePrimaryUnits &&
                availablePrimaryUnits.map(item => {
                  return (
                    <UnitItem key={`${item.facilityId}:${item.unitId}`}>
                      <UnitLabel key={item.unitId}>
                        {item.unit}
                        <IconFavoriteUnit
                          onClick={() => removeFromFavorite(item)}
                          key={item.unitId}
                        >
                          star
                        </IconFavoriteUnit>
                      </UnitLabel>
                    </UnitItem>
                  );
                })}
              {availablePrimaryUnits && availablePrimaryUnits.length === 0 && (
                <UnitItem key="no-primary-unit">
                  <UnitLabel key="no-primary-unit">No items</UnitLabel>
                </UnitItem>
              )}
            </UnitList>
          </UnitListContainerRight>
        </UnitListContainer>
      </LeftContainer>
    </WrapperContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    facilityId: state.AuthorizedUser.authorizedUser.facilityId,
    userId: state.AuthorizedUser.authorizedUser.hcaid,
    facilities: state.UserReducer.facilities,
    primaryUnit: state.AuthorizedUser.authorizedUser.primaryUnit,
    units: state.ConfigReducer.units,
    primaryUnitsLoading: state.AuthorizedUser.loading,
    addRemoveFvouriteLoading: state.AccountReducer.loading
  };
};

export default connect(mapReduxStateToProps)(FacilityAndUnitSettings);

import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuButton, NeuIcon } from '@neutron/react';

import { openModal, setModalType } from '../../../redux/actions/Modal.action';
import { RootState } from '../../../config/interfaces';

interface AddQuestionBtnProps {
  modalState: boolean;
}

const AddQuestionBtn: FC<AddQuestionBtnProps> = ({ modalState }) => {
  const dispatch = useDispatch();

  const toggleModal = (mType: string) => {
    if (modalState === false) {
      dispatch(setModalType(mType));
      dispatch(openModal());
      // appInsights.trackEvent({
      //   name: 'Question_Created'
      // });
    }
  };

  return (
    <NeuButton
      id="Provision-Button"
      className="mr-5"
      color="primary"
      fill="raised"
      onClick={() => toggleModal('AddQuestion')}
    >
      <NeuIcon className="mr-2" feedback="default">
        add
      </NeuIcon>
      Add Question
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(AddQuestionBtn);

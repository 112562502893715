import { FC } from 'react';

import { connect, useDispatch } from 'react-redux';
import { NeuButton, NeuIcon } from '@neutron/react';
import {
  openModal,
  setModalType,
  setModalData
} from '../../../../redux/actions/Modal.action';
import { Employee, Patient, RootState } from '../../../../config/interfaces';

interface AddFbFromRoundBtnProps {
  modalData: Patient | Employee;
  type: string;
}
const AddFbFromRoundBtn: FC<AddFbFromRoundBtnProps> = ({ modalData, type }) => {
  const dispatch = useDispatch();
  const handleAddNewFb = () => {
    if (type === 'patient') {
      dispatch(setModalType('Add Patient Feedback On Rounds'));
      dispatch(setModalData(modalData));
      dispatch(openModal());
    } else {
      dispatch(setModalType('Add Employee Feedback On Rounds'));
      dispatch(setModalData(modalData));
      dispatch(openModal());
    }
  };

  return (
    <NeuButton
      id="Add-Feeback-Button-Round"
      className="mx-2"
      color="primary"
      fill="outline"
      onClick={handleAddNewFb}
    >
      <NeuIcon className="mr-2" left>
        campaign
      </NeuIcon>
      Add Feedback
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(AddFbFromRoundBtn);

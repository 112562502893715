import { all, call, delay, put, select } from 'redux-saga/effects';
import { getData, postData } from '../../services/API/axios';
import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';
import store from '../store';
import { useIssuesApi, useFeedbackApi } from '../../utils/api';
import { date, text } from '../../utils/helpers';
import { setCsvToExport } from '../actions/Admin.action';
import {
  getFeedbackOnRoundByEmployeeId,
  getTaskByAccountNumber,
  getIssueOnRoundByEmployeeId
} from '../actions/Task.action';

import { closeModal, setModalData } from '../actions/Modal.action';

// FEEDBACK
export function* getFeedbackByUnitIds(action) {
  const params = new URLSearchParams(action.data).toString();
  const url = `${API.getFeedbackByUnitsIdsUrl}?${params}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data.patientFeedbackList?.map(task => {
        return {
          ...task,
          roundingType: task.roundingType,
          taskType: 'feedback'
        };
      });

      yield put({
        type: ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS_SUCCESS,
        data: {
          feedbackList: data.totalRecordsCount > 0 ? cleanData : [],
          totalCount: data.totalRecordsCount
        }
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.TASK.GET_FEEDBACK_BY_UNIT_IDS_FAILED,
      data: {
        feedbackList: [],
        totalCount: 0
      }
    });
  }
}

export function* getFeedbackByEmployeeList(action) {
  const params = new URLSearchParams(action.data).toString();
  const url = `${API.getFeedbackByEmployeeListUrl}?${params}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data.employeeFeedbackList?.map(task => {
        return {
          ...task,
          roundingType: task.roundingType,
          taskType: 'feedback'
        };
      });

      yield put({
        type: ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST_SUCCESS,
        data: {
          feedbackList: data.totalRecordsCount > 0 ? cleanData : [],
          totalCount: data.totalRecordsCount
        }
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.TASK.GET_FEEDBACK_BY_EMPLOYEE_LIST_FAILED,
      data: {
        feedbackList: [],
        totalCount: 0
      }
    });
  }
}

export function* getSingleIssueOrFeedbackFromEmail(action) {
  const taskId = action.data;
  const { authRoles } = yield select(state => state.AuthorizedUser);

  // FUTURE call will either get users or all tasks not of the user
  const url = `${API.postNewTaskUrl}?taskId[]=${taskId}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data.map(task => {
        return {
          friendlyId: task.friendlyId,
          accountId: task.accountId,
          taskId: task.taskId,
          userId: task.userId,
          taskType: task.taskType,
          category: task.category,
          taskSubtype: task.taskSubtype,
          subCategory: task.subCategory,
          taskCategory: task.taskCategory,
          title: task.title,
          completed: task.completed,
          completedDate: task.completedDate,
          taskDate: task.taskDate,
          description: task.description,
          facilityId: task.facilityId,
          patientFirstName: task.patientFirstName,
          patientLastName: task.patientLastName,
          patientFullName:
            task.patientFirstName &&
            task.patientLastName &&
            text.capFirst(`${task.patientFirstName} ${task.patientLastName}`),
          employeeFirstName: task.employeeFirstName,
          employeeLastName: task.employeeLastName,
          employeeFullName:
            task.employeeFirstName &&
            task.employeeLastName &&
            text.capFirst(`${task.employeeFirstName} ${task.employeeLastName}`),
          employeeId: task.employeeId?.toUpperCase(),
          room: task.room,
          roundingType: task.roundingType,
          sharedWith: task.sharedWith,
          spotlight: task.isCopiedToStoplight,
          updated: task.updated,
          updatedBy: task.updatedBy,
          unitId: task.unitId,
          userFullName: task.userFullName,
          stoplightId: task.stoplightId,
          ticketNumber: task.ticketNumber,
          callback: task.callback || task.u_best_contact_info,
          u_best_contact_info: task.u_best_contact_info,
          u_issue_location: task.u_issue_location,
          u_control_number: task.u_control_number,
          ec_loc: task.ec_loc,
          roundId: task.roundId,
          accountNum: task.accountNum
        };
      });

      yield put({
        type: ACTIONS.TASK.GET_SINGLE_TASK_TO_COMPLETE_SUCCESS,
        data: {
          singleTaskToComplete:
            authRoles.includes('enterpriseAdmin') ||
            (cleanData[0]?.roundingType === 'patient' &&
              authRoles.includes('patientRounder')) ||
            (cleanData[0]?.roundingType === 'employee' &&
              authRoles.includes('employeeRounder'))
              ? cleanData
              : []
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}
export function* getFacilityIssues(action) {
  const { hcaid, facilityId } = yield select(
    state => state.AuthorizedUser?.authorizedUser
  );
  const { location, filterData } = action.data;
  const params = new URLSearchParams(filterData).toString();

  const { employeesToRound } = yield select(state => state.EmployeeReducer);
  if (
    hcaid &&
    ((facilityId && location === 'patient' && filterData.units) ||
      (location === 'employee' && employeesToRound.length > 0))
  ) {
    let url = `${API.getPatientIssuesUrl}?${params}`;
    if (location === 'employee') {
      url = `${API.getEmployeeIssuesUrl}?${params}`;
    }
    try {
      const { data, status } = yield call(getData, url);
      if (status === 200) {
        let arrData = [];
        if (location === 'employee') {
          arrData = data.employeeIssueList;
        } else {
          arrData = data.patientIssueList;
        }

        const cleanData = arrData?.map(task => {
          let taskStatus;
          if (task.completed) {
            taskStatus = 'Completed';
          } else {
            taskStatus = 'Opened';
          }
          return {
            friendlyId: task.friendlyId,
            taskId: task.taskId,
            userId: task.userId,
            taskType: location === 'employee' ? task.tasktype : task.taskType,
            category: task.category,
            taskSubtype: task.taskSubtype,
            subCategory: task.subCategory,
            taskCategory: task.taskCategory,
            title: task.title,
            completed: task.completed,
            completedDate: task.completedDate,
            taskDate: task.taskDate,
            description: task.description,
            facilityId: task.facilityId,
            patientFirstName: task.patientFirstName,
            patientLastName: task.patientLastName,
            patientFullName: task.patientFullName,
            employeeFirstName: task.employeeFirstName,
            employeeLastName: task.employeeLastName,
            employeeFullName: task.employeeFullName,
            employeeId: task.employeeId && task.employeeId.toUpperCase(),
            room: task.room,
            bed: task.bed,
            roundingType: task.roundingType,
            sharedWith: task.sharedWith,
            spotlight: task.isCopiedToStoplight,
            taskStatus,
            updated: task.updated,
            updatedBy: task.updatedBy,
            unitId: task.unitId,
            userFullName: task.userFullName,
            stoplightId: task.stoplightId,
            ticketNumber: task.ticketNumber,
            latestComment: task.latestComment,
            discussion: task.discussion,
            discussionId: task.discussionId,
            department: task.department,
            departmentCode: task.departmentCode,
            departmentId: task.departmentId,
            callback: task.callback || task.u_best_contact_info,
            u_best_contact_info: task.u_best_contact_info,
            u_issue_location: task.u_issue_location,
            u_control_number: task.u_control_number,
            ec_loc: task.ec_loc,
            roundId: task.roundId,
            accountNum: task.accountNum
          };
        });

        return yield put({
          type: ACTIONS.TASK.GET_ISSUES_BY_FACILITY_SUCCESS,
          data: {
            issues: data.totalRecordsCount === 0 ? [] : cleanData,
            totalCount: data.totalRecordsCount
          }
        });
      }
      return yield put({
        type: ACTIONS.TASK.GET_ISSUES_BY_FACILITY_SUCCESS,
        data: {
          issues: [],
          totalCount: 0
        }
      });
    } catch (error) {
      console.log(error);
      return yield put({
        type: ACTIONS.TASK.GET_ISSUES_BY_FACILITY_SUCCESS,
        data: {
          issues: [],
          totalCount: 0
        }
      });
    }
  }
  return yield put({
    type: ACTIONS.TASK.GET_ISSUES_BY_FACILITY_SUCCESS,
    data: {
      issues: [],
      totalCount: 0
    }
  });
}

export function* getAllFacilityIssues(action) {
  const { location, filterData } = action.data;

  const { hcaid, facilityId } = yield select(
    state => state.AuthorizedUser?.authorizedUser
  );
  const { units } = yield select(state => state.ConfigReducer);
  const { employeesToRound } = yield select(state => state.EmployeeReducer);
  const { facilities } = yield select(state => state.UserReducer);

  const params = new URLSearchParams(filterData).toString();

  yield put({
    type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT
  });
  if (
    hcaid &&
    ((facilityId && location === 'patient' && filterData.units) ||
      (location === 'employee' && employeesToRound.length > 0))
  ) {
    let totalData = [];
    let url = `${API.getPatientIssuesUrl}?${params}`;
    if (location === 'employee') {
      url = `${API.getEmployeeIssuesUrl}?${params}`;
    }
    try {
      const { data, status } = yield call(getData, `${url}&page=1`);
      if (status === 200) {
        if (data.totalRecordsCount > 50) {
          const totalPages = Math.ceil(data.totalRecordsCount / 50);
          const restData = yield all(
            [...Array(totalPages + 1).keys()]
              .slice(2)
              .map(page => call(getData, `${url}&page=${page}`))
          );
          if (restData && restData.length > 0) {
            totalData =
              location === 'employee'
                ? [
                    ...data.employeeIssueList,
                    ...restData.reduce(
                      (acc, response) =>
                        acc.concat(response.data.employeeIssueList),
                      []
                    )
                  ]
                : [
                    ...data.patientIssueList,
                    ...restData.reduce(
                      (acc, response) =>
                        acc.concat(response.data.patientIssueList),
                      []
                    )
                  ];
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (location === 'employee') {
            totalData = [...data.employeeIssueList];
          } else {
            totalData = [...data.patientIssueList];
          }
        }
      } else {
        return yield put({
          type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
        });
      }
    } catch (error) {
      console.log(error);
      return yield put({
        type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
      });
    }
    return yield put(
      setCsvToExport(
        totalData.map(item => ({
          taskDate: date.dayOfYrFull(item.taskDate),
          friendlyId: item.friendlyId,
          userFullName: item.userFullName,
          facility:
            facilities.find(f => f.id === item.facilityId).value || 'Unknown',
          location:
            location === 'patient'
              ? units.find(unit => unit.unitId === item.unitId)?.unit
              : item.department
              ? item.department
              : 'Unknown',
          category: item.category || item.taskSubtype,
          subCategory: item.subCategory,
          title: text.escCSVChars(item.title),
          description: text.escCSVChars(item.description),
          name: `${
            location === 'patient'
              ? item.patientFirstName && item.patientLastName
                ? `${
                    item.patientLastName
                      ? item.patientLastName.slice(0, 3).toUpperCase()
                      : ''
                  }, ${
                    item.patientFirstName
                      ? item.patientFirstName.slice(0, 3).toUpperCase()
                      : ''
                  }`
                : ''
              : item.employeeFullName
          }`,
          latestComment: item.latestComment
            ? `${
                item.latestComment.date && date.dayOfYr(item.latestComment.date)
              } | ${item.latestComment.authorFullName} ${text.escCSVChars(
                item.latestComment.body
              )}`
            : '',
          attachedTo: item.stoplightId ? `Stoplight` : '',
          status: item.completed ? 'Completed' : 'Opened'
        }))
      )
    );
  }
  return yield put(setCsvToExport([]));
}

export function* getCategoriesByType(action) {
  const paramData = action.data;
  // Query params are categoryFor, categoryType, feedbackType
  const params = new URLSearchParams(paramData).toString();

  const url = `${API.getCategoriesByType}?${params}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      return yield put({
        type: ACTIONS.TASK.GET_CATEGORIES_SUCCESS,
        data
      });
    }
    return yield put({
      type: ACTIONS.TASK.GET_CATEGORIES_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.TASK.GET_CATEGORIES_FAILURE
    });
  }
}

export function* shareTask(share) {
  const url = API.shareTaskUrl;
  try {
    const { status } = yield call(postData, url, share);
    if (status === 200) {
      return yield put({
        type: ACTIONS.TASK.SHARE_TASK_SUCCESS
      });
    }
    return toast('Failed to Share!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.TASK.SHARE_TASK_FAILED,
      data: error
    });
    return toast('Failed to Share!', 'error', null, 500, true);
  }
}

export function* postNewTask(action) {
  const url = API.postNewTaskUrl;
  const { taskType, roundId, employeeId } = action.data;

  try {
    const { data, status } = yield call(postData, url, action.data);
    const { authorizedUser } = yield select(state => state.AuthorizedUser);
    const { selectedUnits } = yield select(state => state.ConfigReducer);
    const { selectedDelegatedEmployee } = yield select(
      state => state.EmployeeReducer
    );
    const {
      currentFeedbackPage,
      currentIssuePage,
      feedbackFilter,
      issueFilter,
      sortKey,
      sortDir,
      taskSearchQuery
    } = yield select(state => state.TaskReducer);
    const { userSection } = yield select(state => state.UserReducer);

    if (status === 200) {
      const toastMsg =
        taskType === 'issue' ? 'Issue Posted' : 'Feedback Posted';
      toast(toastMsg, 'success', 1000, 500);
      const sharingQ = yield select(state => state.TaskReducer.sharingQ);
      // Only call the share saga in there are users in the Q to share task with
      if (sharingQ.length > 0) {
        const taskShare = sharingQ.filter(
          item => item.taskId === data[0].taskId
        );

        yield call(shareTask, taskShare[0]);

        yield delay(1000);
        toast(
          `Task${
            sharingQ.length > 1 ? 's have been shared!' : ' has been shared!'
          }`,
          'success',
          1000,
          500
        );
      }
      yield put({
        type: ACTIONS.TASK.SHARE_ALL_TASK_SUCCESS,
        data
      });
      if (!action.data.multiShare) {
        yield put({
          type: ACTIONS.TASK.CLEAR_SHARING_Q
        });
      }
      if (taskType === 'issue') {
        const issuesAction = useIssuesApi({
          authorizedUser,
          currentIssuePage,
          issueFilter,
          searchTerm: taskSearchQuery,
          selectedDelegate: selectedDelegatedEmployee.hcaid,
          selectedUnits,
          sortDir,
          sortKey
        });
        yield put(issuesAction);
      }
      if (taskType === 'feedback') {
        const feedbackAction = useFeedbackApi({
          authorizedUser,
          currentFeedbackPage,
          feedbackFilter,
          searchTerm: taskSearchQuery,
          selectedDelegate: selectedDelegatedEmployee.hcaid,
          selectedUnits,
          sortKey,
          sortDir
        });
        yield put(feedbackAction);
      }

      // update tasks with new data
      if (roundId && userSection === 'employee') {
        yield put(
          getIssueOnRoundByEmployeeId({
            employeeIds: employeeId,
            userId: action.data.userId
          })
        );
        yield put(
          getFeedbackOnRoundByEmployeeId({
            employeeIds: employeeId,
            userId: action.data.userId
          })
        );
      } else if (
        roundId &&
        userSection === 'patient' &&
        action.data.accountNum
      ) {
        yield put(
          // patient tasks
          getTaskByAccountNumber({
            facilityMnemonic: action.data.facilityId,
            accountNumber: action.data.accountNum
          })
        );
      }
      yield put(closeModal());
      return yield put({
        type: ACTIONS.TASK.POST_NEW_TASK_SUCCESS,
        data
      });
    }
    yield put({
      type: ACTIONS.TASK.POST_NEW_TASK_FAIL
    });
    return toast(
      `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Failed to Post!`,
      'error',
      null,
      500,
      true
    );
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.TASK.POST_NEW_TASK_FAIL
    });
    return toast(
      `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Failed to Post!`,
      'error',
      null,
      500,
      true
    );
  }
}

export function* completeTask(action) {
  const { complete, edit, taskId, taskType } = action.data;
  const { authorizedUser } = yield select(state => state.AuthorizedUser);
  const { selectedUnits } = yield select(state => state.ConfigReducer);
  const { selectedDelegatedEmployee } = yield select(
    state => state.EmployeeReducer
  );
  const {
    currentFeedbackPage,
    currentIssuePage,
    feedbackFilter,
    issueFilter,
    singleTaskToComplete,
    sortKey,
    sortDir,
    taskSearchQuery
  } = yield select(state => state.TaskReducer);

  try {
    const { status } = yield call(postData, API.completeTaskUrl, {
      taskId,
      complete
    });
    if (status === 200) {
      toast(
        `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Completed!`,
        'success',
        1000,
        500
      );
      if (taskType === 'issue') {
        const issuesAction = useIssuesApi({
          authorizedUser,
          currentIssuePage,
          issueFilter,
          searchTerm: taskSearchQuery,
          selectedDelegate: selectedDelegatedEmployee.hcaid,
          selectedUnits,
          sortDir,
          sortKey
        });
        yield put(issuesAction);
      } else if (taskType === 'feedback') {
        const feedbackAction = useFeedbackApi({
          authorizedUser,
          currentFeedbackPage,
          feedbackFilter,
          searchTerm: taskSearchQuery,
          selectedDelegate: selectedDelegatedEmployee.hcaid,
          selectedUnits,
          sortKey,
          sortDir
        });
        yield put(feedbackAction);
      }
      if (edit) {
        if (singleTaskToComplete.length > 0) {
          yield call(getSingleIssueOrFeedbackFromEmail, { data: taskId });
          return yield put({
            type: ACTIONS.TASK.COMPLETE_TASK_SUCCESS
          });
        }
        const { data = [], status: issueStatus = undefined } = yield call(
          getData,
          `${API.tasksByIdUrl}?taskId[]=${taskId}`
        );
        if (issueStatus === 200 && data.length > 0) {
          yield put(setModalData(data[0]));
          return yield put({
            type: ACTIONS.TASK.COMPLETE_TASK_SUCCESS
          });
        }
        return yield put(closeModal());
      }
      return yield put({
        type: ACTIONS.TASK.COMPLETE_TASK_SUCCESS
      });
    }
    return toast(
      `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Failed to Complete!`,
      'error',
      null,
      500,
      true
    );
  } catch (error) {
    console.log(error);
    return toast(
      `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Failed to Complete!`,
      'error',
      null,
      500,
      true
    );
  }
}

export function* deleteTask(action) {
  const url = API.deleteTaskUrl;
  const payload = {
    taskId: action.data.taskId
  };
  const { taskType } = action.data;
  const { authorizedUser } = yield select(state => state.AuthorizedUser);
  const { selectedUnits } = yield select(state => state.ConfigReducer);
  const { selectedDelegatedEmployee } = yield select(
    state => state.EmployeeReducer
  );
  const {
    currentFeedbackPage,
    currentIssuePage,
    feedbackFilter,
    issueFilter,
    sortKey,
    sortDir,
    taskSearchQuery
  } = yield select(state => state.TaskReducer);

  try {
    const { status } = yield call(postData, url, payload);
    if (status === 200) {
      if (taskType === 'issue') {
        // appInsights.trackEvent({
        //   name: 'Issue_Deleted'
        // });
      } else {
        // appInsights.trackEvent({
        //   name: 'Feedback_Deleted'
        // });
      }
      toast(
        `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Deleted!`,
        'success',
        1000,
        500
      );
      // update tasks with new data
      if (action.data.accountNum)
        yield put(
          // patient tasks
          getTaskByAccountNumber({
            accountNumber: action.data.accountNum,
            facilityMnemonic: action.data.facilityId
          })
        );

      if (taskType === 'issue') {
        const issuesAction = useIssuesApi({
          authorizedUser,
          currentIssuePage,
          issueFilter,
          searchTerm: taskSearchQuery,
          selectedDelegate: selectedDelegatedEmployee.hcaid,
          selectedUnits,
          sortDir,
          sortKey
        });
        yield put(issuesAction);
      } else if (taskType === 'feedback') {
        const feedbackAction = useFeedbackApi({
          authorizedUser,
          currentFeedbackPage,
          feedbackFilter,
          searchTerm: taskSearchQuery,
          selectedDelegate: selectedDelegatedEmployee.hcaid,
          selectedUnits,
          sortKey,
          sortDir
        });
        yield put(feedbackAction);
      }

      yield delay(400);
      yield put({
        type: ACTIONS.TASK.DELETE_TASK_SUCCESS
      });
      return yield put(closeModal());
    }
    return toast(
      `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Failed to Delete!`,
      'error',
      null,
      500,
      true
    );
  } catch (error) {
    console.log(error);
    return toast(
      `${taskType === 'feedback' ? 'Feedback' : 'Issue'} Failed to Delete!`,
      'error',
      null,
      500,
      true
    );
  }
}

export function* getTaskDiscussion(action) {
  const { id } = action.data;

  const url = `${API.discussionUrl}/${id}`;
  const { modalData } = yield select(state => state.ModalReducer);

  try {
    const { data, status } = yield call(getData, url);

    if (status === 200) {
      // get the task again to refresh the convo
      return store.dispatch(setModalData({ ...modalData, discussion: data }));
    }
    return store.dispatch(setModalData({ ...modalData, discussion: [] }));
  } catch (error) {
    console.log(error);
    return store.dispatch(setModalData({ ...modalData, discussion: [] }));
  }
}

export function* getIssueById(action) {
  const url = `${API.tasksByIdUrl}?taskId[]=${action.data}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data?.map(task => {
        let taskStatus;
        if (task.completed) {
          taskStatus = 'Completed';
        } else {
          taskStatus = 'Opened';
        }
        return {
          friendlyId: task.friendlyId,
          taskId: task.taskId,
          userId: task.userId,
          taskType: task.taskType,
          category: task.category,
          taskSubtype: task.taskSubtype,
          subCategory: task.subCategory,
          taskCategory: task.taskCategory,
          title: task.title,
          completed: task.completed,
          completedDate: task.completedDate,
          taskDate: task.taskDate,
          description: task.description,
          facilityId: task.facilityId,
          patientFirstName: task.patientFirstName,
          patientLastName: task.patientLastName,
          patientFullName: task.patientFullName,
          employeeFirstName: task.employeeFirstName,
          employeeLastName: task.employeeLastName,
          employeeFullName: task.employeeFullName,
          employeeId: task.employeeId && task.employeeId.toUpperCase(),
          room: task.room,
          bed: task.bed,
          roundingType: task.roundingType,
          sharedWith: task.sharedWith,
          spotlight: task.isCopiedToStoplight,
          taskStatus,
          updated: task.updated,
          updatedBy: task.updatedBy,
          unitId: task.unitId,
          userFullName: task.userFullName,
          stoplightId: task.stoplightId,
          ticketNumber: task.ticketNumber,
          latestComment: task.latestComment,
          discussion: task.discussion,
          discussionId: task.discussionId,
          department: task.department,
          departmentCode: task.departmentCode,
          departmentId: task.departmentId,
          callback: task.callback || task.u_best_contact_info,
          u_best_contact_info: task.u_best_contact_info,
          u_issue_location: task.u_issue_location,
          u_control_number: task.u_control_number,
          ec_loc: task.ec_loc,
          roundId: task.roundId,
          accountNum: task.accountNum
        };
      });
      yield put({
        type: ACTIONS.TASK.GET_ISSUE_BY_ID_SUCCESS,
        data: cleanData[0]
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* postCommentToTask(action) {
  const {
    task,
    comment: { id, comment }
  } = action.data;

  const url = `${API.discussionUrl}/${id}/comment`;

  try {
    const { data, status } = yield call(postData, url, comment);

    if (status === 200) {
      yield put({
        type: ACTIONS.STOPLIGHT.POST_COMMENT_TO_STOPLIGHT_SUCCESS,
        data
      });
      // TODO: Is this needed
      const { authorizedUser } = yield select(state => state.AuthorizedUser);
      const { selectedUnits } = yield select(state => state.ConfigReducer);
      const { selectedDelegatedEmployee } = yield select(
        state => state.EmployeeReducer
      );
      const {
        currentIssuePage,
        issueFilter,
        sortKey,
        sortDir,
        taskSearchQuery
      } = yield select(state => state.TaskReducer);

      const issuesAction = useIssuesApi({
        authorizedUser,
        currentIssuePage,
        issueFilter,
        searchTerm: taskSearchQuery,
        selectedDelegate: selectedDelegatedEmployee.hcaid,
        selectedUnits,
        sortDir,
        sortKey
      });
      yield put(issuesAction);

      return store.dispatch(
        setModalData({
          ...task,
          discussion: data,
          latestComment: data.comments[data.comments.length - 1]
        })
      );
    }
    return toast('Failed to Post Comment!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to Post Comment!', 'error', null, 500, true);
  }
}

export function* getEmployeeSharedWith(action) {
  const { employeeSharedWith } = yield select(state => state.TaskReducer);

  const url = `${API.employeeSearchUrl}?employeeId=${action.data}`;

  try {
    const {
      data: [data],
      status
    } = yield call(getData, url);
    if (status === 200) {
      const formattedData = {
        employeeId: data.employeeId.toUpperCase(),
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        fullName: `${data.firstName} ${data.lastName}`
      };
      yield put({
        type: ACTIONS.TASK.GET_EMP_SHARED_WITH_SUCCESS,
        data: !employeeSharedWith.find(
          emp => emp.employeeId === formattedData.employeeId
        )
          ? [...employeeSharedWith, formattedData]
          : [...employeeSharedWith]
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getIssuesOnRoundByEmployeeId(action) {
  const params = new URLSearchParams(action.data).toString();

  const url = `${API.getEmployeeIssuesUrl}?${params}`;
  const { status, data } = yield call(getData, url);
  const { employeeIssueList } = data;
  // const tasksOnRounds = employeeIssueList.filter(task => task.roundId);

  if (status === 200 && employeeIssueList !== null) {
    yield put({
      type: ACTIONS.TASK.GET_ISSUE_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS,
      data: {
        issues: employeeIssueList
      }
    });
  } else {
    yield put({
      type: ACTIONS.TASK.GET_ISSUE_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS,
      data: {
        issues: []
      }
    });
  }
}

export function* getFeedbacksOnRoundByEmployeeId(action) {
  const { employeeIds, userId } = action.data;
  const url = `${API.getFeedbackByEmployeeListUrl}?employeeIds=${employeeIds}&userId=${userId}`;
  const { status, data } = yield call(getData, url);
  const { employeeFeedbackList } = data;

  if (status === 200 && employeeFeedbackList !== null) {
    yield put({
      type: ACTIONS.TASK.GET_FEEDBACK_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS,
      data: {
        feedback: employeeFeedbackList
      }
    });
  } else {
    yield put({
      type: ACTIONS.TASK.GET_FEEDBACK_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS,
      data: {
        feedback: []
      }
    });
  }
}
export function* getTasksByAccountNumber(action) {
  const { facilityMnemonic, accountNumber } = action.data;
  const url = `${API.getTasksByAccountNumberUrl}?accountNumber=${accountNumber}&facilityMnemonic=${facilityMnemonic}`;

  try {
    const { status, data } = yield call(getData, url);
    if (status === 200) {
      return yield put({
        type: ACTIONS.TASK.GET_TASKS_BY_ACCOUNT_NUMBER_SUCCESS,
        data
      });
    }
    return yield put({
      type: ACTIONS.TASK.CLEAR_TASKS_BY_ACCOUNT_NUMBER
    });
  } catch (error) {
    return yield put({
      type: ACTIONS.TASK.CLEAR_TASKS_BY_ACCOUNT_NUMBER
    });
  }
}

export function* searchIssues(action) {
  const { facilityId } = yield select(
    state => state.AuthorizedUser?.authorizedUser
  );
  const { query, origin, page } = action.data;

  const url = `${API.issueSearchUrl}?facilityId=${facilityId}&query=${query}&origin=${origin}&page=${page}`;

  const { status, data } = yield call(getData, url);
  if (status === 200) {
    yield put({
      type: ACTIONS.TASK.SEARCH_ISSUES_SUCCESS,
      data
    });
  }
}

// Fetch service central category's ticket details by ticketNumber
export function* getDetailsByTicketNumber(action) {
  const url = `${API.ticketDetailsUrl}?ticketNumber=${action.data}`;

  const { status, data } = yield call(getData, url);
  if (status === 200) {
    yield put({
      type: ACTIONS.TASK.GET_TICKET_DETAILS_SUCCESS,
      data
    });
  }
}

// Fetch service central category's locations
export function* getSCLocations() {
  const url = `${API.getECLocationsNewUrl}`;

  const { status, data } = yield call(getData, url);
  if (status === 200) {
    yield put({
      type: ACTIONS.TASK.GET_SC_LOCATIONS_SUCCESS,
      data: data.result
    });
  }
}

// Fetch service central category's locations
export function* getGELocations() {
  const url = `${API.getGELocationsUrl}`;

  const { status, data } = yield call(getData, url);
  if (status === 200) {
    yield put({
      type: ACTIONS.TASK.GET_GE_LOCATIONS_SUCCESS,
      data: data.result
    });
  }
}

// Verify control number entered
export function* verifyControlNumber(action) {
  const url = `${API.verifyControlNumberUrl}?controlNumber=${action.data}`;

  const { status, data } = yield call(getData, url);
  if (status === 200) {
    yield put({
      type: ACTIONS.TASK.VERIFY_CONTROL_NUMBER_SUCCESS,
      data: data.result[0] || data.result
    });
  } else {
    yield put({ type: ACTIONS.TASK.CLEAR_VERIFY_CONTROL_NUMBER });
    toast('Invalid Control Number', 'error', 1500, 500);
  }
}

// Get issue list by facility from the dropdown on header
export function* getIssueListByFacilityId(action) {
  const { location, filterData } = action.data;
  const params = new URLSearchParams(filterData).toString();
  const url = `${API.issuesByFacilityUrl}?${params}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data.issueList?.map(task => {
        let taskStatus;
        if (task.completed) {
          taskStatus = 'Completed';
        } else {
          taskStatus = 'Opened';
        }
        return {
          friendlyId: task.friendlyId,
          taskId: task.taskId,
          userId: task.userId,
          taskType: location === 'employee' ? task.tasktype : task.taskType,
          category: task.category,
          taskSubtype: task.taskSubtype,
          subCategory: task.subCategory,
          taskCategory: task.taskCategory,
          title: task.title,
          completed: task.completed,
          completedDate: task.completedDate,
          taskDate: task.taskDate,
          description: task.description,
          facilityId: task.facilityId,
          patientFirstName: task.patientFirstName,
          patientLastName: task.patientLastName,
          patientFullName: task.patientFullName,
          employeeFirstName: task.employeeFirstName,
          employeeLastName: task.employeeLastName,
          employeeFullName: task.employeeFullName,
          employeeId: task.employeeId && task.employeeId.toUpperCase(),
          room: task.room,
          bed: task.bed,
          roundingType: task.roundingType,
          sharedWith: task.sharedWith,
          spotlight: task.isCopiedToStoplight,
          taskStatus,
          updated: task.updated,
          updatedBy: task.updatedBy,
          unitId: task.unitId,
          userFullName: task.userFullName,
          stoplightId: task.stoplightId,
          ticketNumber: task.ticketNumber,
          latestComment: task.latestComment,
          discussion: task.discussion,
          discussionId: task.discussionId,
          department: task.department,
          departmentCode: task.departmentCode,
          departmentId: task.departmentId,
          callback: task.callback || task.u_best_contact_info,
          u_best_contact_info: task.u_best_contact_info,
          u_issue_location: task.u_issue_location,
          u_control_number: task.u_control_number,
          ec_loc: task.ec_loc,
          roundId: task.roundId,
          accountNum: task.accountNum
        };
      });

      yield put({
        type: ACTIONS.TASK.GET_ISSUE_LIST_BY_FACILITY_SUCCESS,
        data: {
          issues: data.totalRecordsCount === 0 ? [] : cleanData,
          totalCount: data.totalRecordsCount
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// Get issue list by facility from the dropdown on header
export function* getAllIssuesListByFacilityId(action) {
  const { location, filterData } = action.data;

  const { units } = yield select(state => state.ConfigReducer);
  const { facilities } = yield select(state => state.UserReducer);

  const params = new URLSearchParams(filterData).toString();
  const url = `${API.issuesByFacilityUrl}?${params}`;

  yield put({
    type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT
  });
  let totalData = [];
  try {
    const { data, status } = yield call(getData, `${url}&page=1`);
    if (status === 200) {
      if (data.totalRecordsCount > 50) {
        const totalPages = Math.ceil(data.totalRecordsCount / 50);
        const restData = yield all(
          [...Array(totalPages + 1).keys()]
            .slice(2)
            .map(page => call(getData, `${url}&page=${page}`))
        );
        if (restData && restData.length > 0) {
          totalData = [
            ...data.issueList,
            ...restData.reduce(
              (acc, response) => acc.concat(response.data.issueList),
              []
            )
          ];
        }
      } else {
        totalData = [...data.issueList];
      }
    } else {
      yield put({
        type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
    });
  }
  yield put(
    setCsvToExport(
      totalData.map(item => ({
        taskDate: date.dayOfYrFull(item.taskDate),
        friendlyId: item.friendlyId,
        userFullName: item.userFullName,
        facility:
          facilities.find(f => f.id === item.facilityId).value || 'Unknown',
        location:
          location === 'patient'
            ? units.find(unit => unit.unitId === item.unitId)?.unit
            : item.department
            ? item.department
            : 'Unknown',
        category: item.category || item.taskSubtype,
        subCategory: item.subCategory,
        title: text.escCSVChars(item.title),
        description: text.escCSVChars(item.description),
        name: `${
          location === 'patient'
            ? item.patientFirstName && item.patientLastName
              ? `${
                  item.patientLastName
                    ? item.patientLastName.slice(0, 3).toUpperCase()
                    : ''
                }, ${
                  item.patientFirstName
                    ? item.patientFirstName.slice(0, 3).toUpperCase()
                    : ''
                }`
              : ''
            : item.employeeFullName
        }`,
        latestComment: item.latestComment
          ? `${
              item.latestComment.date && date.dayOfYr(item.latestComment.date)
            } | ${item.latestComment.authorFullName} ${text.escCSVChars(
              item.latestComment.body
            )}`
          : '',
        attachedTo: item.stoplightId ? `Stoplight` : '',
        status: item.completed ? 'Completed' : 'Opened'
      }))
    )
  );
}

// Get feedback list by facility from the dropdown on header
export function* getFeedbackListByFacilityId(action) {
  const { filterData } = action.data;
  const params = new URLSearchParams(filterData).toString();
  const url = `${API.feedbackByFacilityUrl}?${params}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data.employeeFeedbackList?.map(task => {
        return {
          ...task,
          roundingType: task.roundingType,
          taskType: 'feedback'
        };
      });

      yield put({
        type: ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY_SUCCESS,
        data: {
          feedbackList: data.totalRecordsCount > 0 ? cleanData : [],
          totalCount: data.totalRecordsCount
        }
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.TASK.GET_FEEDBACK_LIST_BY_FACILITY_FAILURE,
      data: {
        feedbackList: [],
        totalCount: 0
      }
    });
  }
}

export function* getAllFacilityFeedback(action) {
  const { location, filterData } = action.data;

  const { hcaid, facilityId } = yield select(
    state => state.AuthorizedUser?.authorizedUser
  );
  const { units } = yield select(state => state.ConfigReducer);
  const { employeesToRound } = yield select(state => state.EmployeeReducer);

  const { facilities } = yield select(state => state.UserReducer);

  const params = new URLSearchParams(filterData).toString();

  yield put({
    type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT
  });
  if (
    hcaid &&
    ((facilityId && location === 'patient') || location === 'employee')
  ) {
    let totalData = [];
    let url = `${API.getFeedbackByUnitsIdsUrl}?${params}`;
    if (location === 'employee') {
      url = `${API.getFeedbackByEmployeeListUrl}?${params}`;
    }
    try {
      const { data, status } = yield call(getData, `${url}&page=1`);
      if (status === 200) {
        if (data.totalRecordsCount > 50) {
          const totalPages = Math.ceil(data.totalRecordsCount / 50);
          const restData = yield all(
            [...Array(totalPages + 1).keys()]
              .slice(2)
              .map(page => call(getData, `${url}&page=${page}`))
          );
          if (restData && restData.length > 0) {
            totalData =
              location === 'employee'
                ? [
                    ...data.employeeFeedbackList,
                    ...restData.reduce(
                      (acc, response) =>
                        acc.concat(response.data.employeeFeedbackList),
                      []
                    )
                  ]
                : [
                    ...data.patientFeedbackList,
                    ...restData.reduce(
                      (acc, response) =>
                        acc.concat(response.data.patientFeedbackList),
                      []
                    )
                  ];
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (location === 'employee') {
            totalData = [...data.employeeFeedbackList];
          } else {
            totalData = [...data.patientFeedbackList];
          }
        }
      } else {
        yield put({
          type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
        });
      }
    } catch (error) {
      console.log(error);
      yield put({
        type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
      });
    }
    yield put(
      setCsvToExport(
        totalData.map(item => ({
          taskDate: date.dayOfYrFull(item.taskDate),
          userFullName: item.userFullName,
          facility:
            facilities.find(f => f.id === item.facilityId)?.value || 'Unknonwn',
          location:
            location === 'patient'
              ? units.find(unit => unit.unitId === item.unitId)?.unit
              : item.department
              ? item.department
              : 'Unknown',
          taskSubtype: text.capFirst(item.taskSubtype),
          category: item.category || item.taskCategory.map(tc => tc),
          subCategory: item.subCategory,
          title: text.escCSVChars(item.title),
          description: text.escCSVChars(item.description),
          employeeName:
            `${item.employeeFirstName} ${item.employeeLastName}` ||
            item.employeeFullName,
          patientName: `${
            location === 'patient'
              ? item.patientFirstName && item.patientLastName
                ? `${
                    item.patientLastName
                      ? item.patientLastName.slice(0, 3).toUpperCase()
                      : ''
                  }, ${
                    item.patientFirstName
                      ? item.patientFirstName.slice(0, 3).toUpperCase()
                      : ''
                  }`
                : ''
              : ''
          }`,
          status: item.completed ? 'Completed' : 'Opened'
        }))
      )
    );
  } else if (employeesToRound.length === 0 && location === 'employee') {
    yield put(setCsvToExport([]));
  }
}

// Get issue list by facility from the dropdown on header
export function* getAllFeedbackListByFacilityId(action) {
  const { location, filterData } = action.data;

  const { units } = yield select(state => state.ConfigReducer);

  const { facilities } = yield select(state => state.UserReducer);

  const params = new URLSearchParams(filterData).toString();
  const url = `${API.feedbackByFacilityUrl}?${params}`;

  yield put({
    type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT
  });
  let totalData = [];
  try {
    const { data, status } = yield call(getData, `${url}&page=1`);
    if (status === 200) {
      if (data.totalRecordsCount > 50) {
        const totalPages = Math.ceil(data.totalRecordsCount / 50);
        const restData = yield all(
          [...Array(totalPages + 1).keys()]
            .slice(2)
            .map(page => call(getData, `${url}&page=${page}`))
        );
        if (restData && restData.length > 0) {
          totalData = [
            ...data.employeeFeedbackList,
            ...restData.reduce(
              (acc, response) => acc.concat(response.data.employeeFeedbackList),
              []
            )
          ];
        }
      } else {
        totalData = [...data.employeeFeedbackList];
      }
    } else {
      yield put({
        type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
      });
    }
  } catch (error) {
    console.log(error);
    yield put({
      type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL
    });
  }
  yield put(
    setCsvToExport(
      totalData.map(item => ({
        taskDate: date.dayOfYrFull(item.taskDate),
        userFullName: item.userFullName,
        facility:
          facilities.find(f => f.id === item.facilityId)?.value || 'Unknown',
        location:
          location === 'patient'
            ? units.find(unit => unit.unitId === item.unitId)?.unit
            : item.department
            ? item.department
            : 'Unknown',
        taskSubtype: text.capFirst(item.taskSubtype),
        category: item.category || item.taskCategory.map(tc => tc),
        subCategory: item.subCategory,
        title: text.escCSVChars(item.title),
        description: text.escCSVChars(item.description),
        employeeName: item.employeeFullName,
        patientName: `${
          location === 'patient'
            ? item.patientFirstName && item.patientLastName
              ? `${
                  item.patientLastName
                    ? item.patientLastName.slice(0, 3).toUpperCase()
                    : ''
                }, ${
                  item.patientFirstName
                    ? item.patientFirstName.slice(0, 3).toUpperCase()
                    : ''
                }`
              : ''
            : item.employeeFullName
        }`,
        status: item.completed ? 'Completed' : 'Opened'
      }))
    )
  );
}

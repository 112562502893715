import { useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import { RootState } from '../../config/interfaces';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useFeatures: () => any = () =>
  useAppSelector((state: RootState) => {
    return {
      isCSRNEnabled: state.FeatureReducer.isCSRNEnabled,
      isCNEdEnabled: state.FeatureReducer.isCNEdEnabled
    };
  });

import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';
import Loading from '../../shared/Loading';
import { CensusReport, RootState } from '../../../config/interfaces';

interface CensusReportTableProps {
  censusList: CensusReport[];
  loading: boolean;
  setCSV: React.Dispatch<React.SetStateAction<CensusReport[]>>;
}
const CensusReportTable: FC<CensusReportTableProps> = ({
  censusList,
  loading,
  setCSV
}) => {
  const [isTableEmpty, setIsTableEmpty] = useState(false);

  const [listToDisplay, setListToDisplay] = useState<CensusReport[]>([]);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [active, setActive] = useState<string>('lastRoundDate');
  const [pageNumber, setPageNumber] = useState<number>(1);

  useEffect(() => {
    if (censusList.length > 0) {
      setListToDisplay(censusList);
      setIsTableEmpty(false);
    } else {
      setIsTableEmpty(true);
      setListToDisplay([]);
    }
  }, [censusList]);

  const sortedCensus = useMemo(() => {
    const stateCopy = listToDisplay?.length ? [...listToDisplay] : [];
    const sorted = stateCopy.sort((a: CensusReport, b: CensusReport) => {
      if (a[active] === b[active]) {
        return 0;
      }
      if (!a[active]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[active]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[active]! > b[active]! ? 1 : -1;
      }
      return a[active]! > b[active]! ? -1 : 1;
    });
    setCSV(sorted);
    return sorted;
  }, [listToDisplay, sortDir, active]);

  const censusData = useMemo(() => {
    return sortedCensus.slice(pageNumber * 50 - 50, pageNumber * 50);
  }, [pageNumber, 50, sortedCensus]);

  const sortByHeading = (key: string) => {
    if (key === active && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(key);
  };

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Unit': '20%', 'Room': '10%', 'Patient & MRN': '20%', 'Date': '15%', 'Name': '20%', 'Status':'15%'}"
        header
      >
        <NeuTableHeading
          slot="Unit"
          icon={
            !(active === 'unit') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('unit')}
          active={active === 'unit'}
        >
          Unit
        </NeuTableHeading>
        <NeuTableHeading
          slot="Room"
          icon={
            !(active === 'room') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('room')}
          active={active === 'room'}
        >
          Room
        </NeuTableHeading>
        <NeuTableHeading
          slot="Patient & MRN"
          icon={
            !(active === 'patient') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('patient')}
          active={active === 'patient'}
        >
          Patient & MRN
        </NeuTableHeading>
        <NeuTableHeading
          slot="Date"
          icon={
            !(active === 'lastRoundDate')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('lastRoundDate')}
          active={active === 'lastRoundDate'}
        >
          Date
        </NeuTableHeading>
        <NeuTableHeading
          slot="Name"
          icon={
            !(active === 'lastRoundedBy')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          onClick={() => sortByHeading('lastRoundedBy')}
          active={active === 'lastRoundedBy'}
        >
          Last Rounded By
        </NeuTableHeading>
        <NeuTableHeading
          slot="Status"
          icon={
            !(active === 'status') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          onClick={() => sortByHeading('status')}
          active={active === 'status'}
        >
          Status
        </NeuTableHeading>
      </NeuTableRow>
      {loading ? (
        <Loading centered={false} />
      ) : !loading && isTableEmpty ? (
        <NoDataSplashView type="censusReport" />
      ) : (
        <>
          <NeuTableBody>
            {censusData?.map((item: CensusReport, i: number) => {
              return (
                <NeuTableRow
                  columns="{'Unit': '20%', 'Room': '10%', 'Patient & MRN': '20%', 'Date': '15%', 'Name': '20%', 'Status':'15%'}"
                  size="large"
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                >
                  <NeuLabel style={{ whiteSpace: 'normal' }} slot="Unit">
                    {item.unit}
                  </NeuLabel>
                  <NeuLabel slot="Room">{item.room}</NeuLabel>
                  <NeuLabel
                    slot="Patient & MRN"
                    style={{ whiteSpace: 'normal' }}
                    // onClick={() => openPatient(round)}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          color: 'black',
                          fontWeight: 700
                        }}
                      >
                        {item.patient}
                      </div>
                      <div> {item.mrn}</div>
                    </div>
                  </NeuLabel>
                  <NeuLabel slot="Date">{item.lastRoundDate}</NeuLabel>
                  <NeuLabel slot="Name">{item.lastRoundedBy}</NeuLabel>
                  <NeuLabel slot="Status">{item.status}</NeuLabel>
                </NeuTableRow>
              );
            })}
          </NeuTableBody>
          <div>
            <NeuPaginator
              total={sortedCensus?.length || 1}
              rowsPerPage={50}
              onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
            />
          </div>
        </>
      )}
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    loading: state.ReportReducer.loading
  };
};

export default connect(mapReduxStateToProps)(CensusReportTable);

/**
 * External Imports
 */
import { Dispatch, FC, SetStateAction } from 'react';
import { connect } from 'react-redux';
import { NeuLabel, NeuOption } from '@neutron/react';
/**
 * Internal Imports
 */
import { Dropdown } from '../../../shared/dropdowns';
/**
 * Global Type Definition Imports
 */
import { RootState } from '../../../../config/interfaces';
/**
 * Style Imports
 */
import { InputHeading, SCLogo } from './CategorySelect.styles';
/**
 * Static File Imports
 */
import serviceCentralLogoImg from '../../../../assets/images/service_central_icon.png';

const CategorySelect: FC<{
  disabled?: boolean;
  heading: string;
  categoryName: string;
  categoryValue: string;
  placeholder: string;
  categories: string[];
  open: boolean;
  serviceCentralCategories: string[];
  handleInputs: (value: string, name: string) => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}> = ({
  disabled,
  heading,
  categoryName,
  categories,
  categoryValue,
  placeholder,
  open,
  serviceCentralCategories,
  handleInputs,
  setOpen
}) => {
  return (
    <div id="Modal-Category-Select">
      <InputHeading>{heading}</InputHeading>
      <Dropdown
        classes="pl-0 pr-2"
        disabled={disabled}
        placeholder={categoryValue || placeholder}
        name={categoryName}
        open={open}
        width="97%"
        setOpen={setOpen}
      >
        {categories.map((category: string) => (
          <NeuOption
            className={`dropdown-hover-item${
              categoryValue === category ? ' selected' : ''
            }`}
            value={category}
            key={category}
            onClick={() => handleInputs(category, 'category')}
          >
            <NeuLabel className="d-flex align-items-center">
              {heading.includes('Issue') &&
                serviceCentralCategories.includes(category) && (
                  <SCLogo
                    width="25px"
                    alt="service central logo"
                    src={serviceCentralLogoImg}
                  />
                )}
              {category}
            </NeuLabel>
          </NeuOption>
        ))}
      </Dropdown>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  serviceCentralCategories: state.ConfigReducer.serviceCentralCategories
});

export default connect(mapReduxStateToProps)(CategorySelect);

import { FC, Dispatch, SetStateAction, useState, useMemo } from 'react';
import {
  NeuHint,
  NeuIcon,
  NeuInput,
  NeuLabel,
  NeuTooltip
} from '@neutron/react';
import { sub } from 'date-fns';

import ExportCsvBtn from '../exportCsvBtn';
import SearchField from '../searchField';

import { date } from '../../../utils/helpers';

import {
  CensusReportHeaderContainer,
  DateSect,
  HintSection,
  Section
} from './CensusReportHeader.styles';

interface ICensusHeaderProps {
  customStartDate: string;
  customEndDate: string;
  searchTerm: string;
  handleExportCsv?: () => void;
  setCustomStartDate: (date: string) => void;
  setCustomEndDate: (date: string) => void;
  setSearchTerm: Dispatch<SetStateAction<string>>;
}

const CensusHeader: FC<ICensusHeaderProps> = ({
  customStartDate,
  customEndDate,
  handleExportCsv,
  setCustomStartDate,
  setCustomEndDate,
  ...props
}) => {
  const [displayStartDate, setDisplayStartDate] =
    useState<string>(customStartDate);

  const handleSelects = (e: any) => {
    const { value } = e.detail;
    if (value) {
      setDisplayStartDate(value);
      setCustomStartDate(value);
      setCustomEndDate(value);
    }
  };

  const minDate = useMemo(
    () => date.convertToDayOfYear(sub(new Date(), { years: 2 })),
    []
  );
  const maxDate = useMemo(() => date.convertToDayOfYear(new Date()), []);

  return (
    <CensusReportHeaderContainer>
      <Section>
        <SearchField {...props} />
        <DateSect>
          <NeuInput
            id="Date-Selection-Census-Report"
            name="startDate"
            type="date"
            autocomplete="off"
            inputmode="search"
            enterkeyhint="search"
            autocorrect="off"
            onNeuChange={handleSelects}
            placeholder="Select a date"
            min={minDate}
            max={maxDate}
            value={displayStartDate}
          />
        </DateSect>
        <HintSection>
          <NeuTooltip className="mt-1">
            <NeuIcon small color="primary" slot="content" className="ml-1">
              help
            </NeuIcon>
            <NeuLabel slot="tooltip" background-color="primary-100">
              The Census report shows patients admitted in the selected unit(s)
              for a given calendar <br />
              date (12am to 11:59pm) and whether the patient has or has not been
              rounded on. <br />
              The units and date range selections can be changed using the
              drop-down menus.
            </NeuLabel>
          </NeuTooltip>
          <NeuHint color="primary" className="ml-2 mb-3 mt-2">
            Report Metrics
          </NeuHint>
        </HintSection>
      </Section>

      <Section>
        <ExportCsvBtn
          handleExportCsv={handleExportCsv}
          fileNamePrefix="Census Report"
        />
      </Section>
    </CensusReportHeaderContainer>
  );
};

export default CensusHeader;

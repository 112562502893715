/**
 * @description All action will be written inside this file. Format for this action are as follows
 * REDUCER_NAME_ACTION_NAME(AUTH_LOGIN_IN)
 */
const ACTIONS = {
  AUTH: {
    GET_AUTH_TOKEN: 'AUTH-GET_AUTH_TOKEN',
    GET_AUTH_TOKEN_SUCCESS: 'AUTH-GET_AUTH_TOKEN_SUCCESS',
    GET_USER34_SUCCESS: 'AUTH-GET_USER34_SUCCESS',
    GET_USER34: 'AUTH-GET_USER34',
    GET_USER: 'AUTH-GET_USER',
    GET_USER_SUCCESS: 'AUTH-GET_USER_SUCCESS',
    GET_USER_FAIL: 'AUTH-GET_USER_FAIL',
    CLEAR_USER: 'AUTH-CLEAR_USER',
    SET_SESSION_ID: 'AUTH-SET_SESSION_ID'
  },
  CONFIG: {
    GET_UNITS_BY_FACILITY: 'CONFIG-GET_UNITS_BY_FACILITY',
    GET_UNITS_BY_FACILITY_SUCCESS: 'CONFIG-GET_UNITS_BY_FACILITY_SUCCESS',
    SET_SELECTED_UNITS: 'CONFIG-SET_SELECTED_UNITS',
    SET_FAVS_PREV_CHECKED: 'CONFIG-SET_FAVS_PREV_CHECKED',
    SET_ALL_PREV_CHECKED: 'CONFIG-SET_ALL_PREV_CHECKED',
    GET_FACILITIES_SUCCESS: 'CONFIG-GET_FACILITIES_SUCCESS',
    GET_FACILITIES_FAILURE: 'CONFIG-GET_FACILITIES_FAILURE',
    CHANGE_UNIT_LOC_TYPE_FLAG: 'CONFIG-CHANGE_UNIT_LOC_TYPE_FLAG',
    CHANGE_UNIT_LOC_TYPE_FLAG_SUCCESS:
      'CONFIG-CHANGE_UNIT_LOC_TYPE_FLAG_SUCCESS',
    CHANGE_UNIT_LOC_TYPE_FLAG_FAILURE:
      'CONFIG-CHANGE_UNIT_LOC_TYPE_FLAG_FAILURE',
    GET_ALL_UNITS: 'CONFIG-GET_ALL_UNITS',
    GET_ALL_UNITS_SUCCESS: 'CONFIG-GET_ALL_UNITS_SUCCESS',
    GET_ALL_UNITS_FAILURE: 'CONFIG-GET_ALL_UNITS_FAILURE',
    GET_SASS_TOKEN: 'CONFIG-GET_SASS_TOKEN',
    GET_SASS_TOKEN_SUCCESS: 'CONFIG-GET_SASS_TOKEN_SUCCESS',
    GET_SASS_TOKEN_FAIL: 'CONFIG-GET_SASS_TOKEN_FAIL'
  },
  EMPLOYEE: {
    GET_EMPLOYEES: 'EMPLOYEE-GET_EMPLOYEES',
    GET_EMPLOYEES_SUCCESS: 'EMPLOYEE-GET_EMPLOYEES_SUCCESS',
    SEARCH_EMPLOYEES: 'EMPLOYEE-SEARCH_EMPLOYEES',
    SEARCH_EMPLOYEES_SUCCESS: 'EMPLOYEE-SEARCH_EMPLOYEES_SUCCESS',
    SEARCH_EMPLOYEES_TO_SHARE: 'EMPLOYEE-SEARCH_EMPLOYEES_TO_SHARE',
    SEARCH_EMPLOYEES_TO_SHARE_SUCCESS:
      'EMPLOYEE-SEARCH_EMPLOYEES_TO_SHARE_SUCCESS',
    CLEAR_EMPLOYEE_SEARCH: 'EMPLOYEE-CLEAR_EMPLOYEE_SEARCH',
    SET_DELEGATED_EMPLOYEE: 'EMPLOYEE-SET_DELEGATED_EMPLOYEE',
    GET_EMPLOYEES_TO_ROUND: 'EMPLOYEE-GET_EMPLOYEES_TO_ROUND',
    GET_EMPLOYEES_TO_ROUND_SUCCESS: 'EMPLOYEE-GET_EMPLOYEES_TO_ROUND_SUCCESS',
    GET_EMPLOYEES_TO_ROUND_FAILURE: 'EMPLOYEE-GET_EMPLOYEES_TO_ROUND_FAILURE',
    GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST:
      'EMPLOYEE-GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST',
    GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS:
      'EMPLOYEE-GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS',
    GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST:
      'EMPLOYEE-GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST',
    GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS:
      'EMPLOYEE-GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS',
    GET_EMPLOYEES_DELEGATED: 'EMPLOYEE-GET_EMPLOYEES_DELEGATED',
    GET_CSRN_EMPLOYEES_CONFIG: 'EMPLOYEE-GET_CSRN_EMPLOYEES_CONFIG',
    GET_CSRN_EMPLOYEES_CONFIG_SUCCESS:
      'EMPLOYEE-GET_CSRN_EMPLOYEES_CONFIG_SUCCESS',
    GET_CSRN_EMPLOYEES_CONFIG_FAILURE:
      'EMPLOYEE-GET_CSRN_EMPLOYEES_CONFIG_FAILURE',
    UPDATE_CSRN_EMPLOYEES_HIDDEN_STATUS:
      'EMPLOYEE-UPDATE_CSRN_EMPLOYEES_HIDDEN_STATUS',
    GET_CNED_EMPLOYEES_CONFIG: 'EMPLOYEE-GET_CNED_EMPLOYEES_CONFIG',
    GET_CNED_EMPLOYEES_CONFIG_SUCCESS:
      'EMPLOYEE-GET_CNED_EMPLOYEES_CONFIG_SUCCESS',
    GET_CNED_EMPLOYEES_CONFIG_FAILURE:
      'EMPLOYEE-GET_CNED_EMPLOYEES_CONFIG_FAILURE',
    UPDATE_CNED_EMPLOYEES_HIDDEN_STATUS:
      'EMPLOYEE-UPDATE_CNED_EMPLOYEES_HIDDEN_STATUS',
    GET_EMPLOYEES_DELEGATED_TO_SUCCESS:
      'EMPLOYEE-GET_EMPLOYEES_DELEGATED_TO_SUCCESS',
    GET_EMPLOYEES_DELEGATED_TO_ME_SUCCESS:
      'EMPLOYEE-GET_EMPLOYEES_DELEGATED_TO_ME_SUCCESS',
    GET_MY_EMPLOYEES: 'EMPLOYEE-GET_MY_EMPLOYEES',
    GET_MY_EMPLOYEES_SUCCESS: 'EMPLOYEE-GET_MY_EMPLOYEES_SUCCESS',
    GET_DELEGATE_SETTINGS: 'EMPLOYEE-GET_DELEGATE_SETTINGS',
    GET_DELEGATE_SETTINGS_SUCCESS: 'EMPLOYEE-GET_DELEGATE_SETTINGS_SUCCESS',
    REMOVE_FROM_MY_EMPLOYEES: 'EMPLOYEE-REMOVE_FROM_MY_EMPLOYEES',
    GET_EMPLOYEE_ACCOUNT_DETAILS: 'EMPLOYEE-GET_EMPLOYEE_ACCOUNT_DETAILS',
    GET_EMPLOYEE_ACCOUNT_DETAILS_SUCCESS:
      'EMPLOYEE-GET_EMPLOYEE_ACCOUNT_DETAILS_SUCCESS',
    ADD_NOTE: 'EMPLOYEE-ADD_NOTE',
    ADD_NOTE_SUCCESS: 'EMPLOYEE-ADD_NOTE_SUCCESS',
    REMOVE_NOTE: 'EMPLOYEE-REMOVE_NOTE',
    REMOVE_NOTE_SUCCESS: 'EMPLOYEE-REMOVE_NOTE_SUCCESS',
    CLEAR_EMPLOYEES_TO_ROUND: 'EMPLOYEE-CLEAR_EMPLOYEES_TO_ROUND',
    SET_DEPTS: 'EMPLOYEE-SET_DEPTS',
    SET_SELECTED_DEPTS: 'EMPLOYEE-SET_SELECTED_DEPTS',
    CLEAR_ALL_DEPTS: 'EMPLOYEE-CLEAR_ALL_DEPTS'
  },
  USER: {
    GET_PATIENTS_TO_ROUND: 'USER-GET_PATIENTS_TO_ROUND',
    GET_PATIENTS_TO_ROUND_SUCCESS: 'USER-GET_PATIENTS_TO_ROUND_SUCCESS',
    GET_ROUNDS: 'USER-GET_ROUNDS',
    GET_ROUNDS_SUCCESS: 'USER-GET_ROUNDS_SUCCESS',
    GET_USER_ROLES: 'USER-GET_USER_ROLES',
    GET_USER_ROLES_SUCCESS: 'USER-GET_USER_ROLES_SUCCESS',
    GET_FACILITIES: 'USER-GET_FACILITIES',
    GET_FACILITIES_SUCCESS: 'USER-GET_FACILITIES_SUCCESS',
    GET_FACILITIES_FAILURE: 'USER-GET_FACILITIES_FAILURE',
    GET_DIVISIONS: 'USER-GET_DIVISIONS',
    GET_DIVISIONS_SUCCESS: 'USER-GET_DIVISIONS_SUCCESS',
    POST_USER_ACCESS: 'USER-POST_USER_ACCESS',
    POST_USER_ACCESS_SUCCESS: 'USER-POST_USER_ACCESS_SUCCESS',
    GET_DISCHARGE_PATIENT: 'USER-GET_DISCHARGE_PATIENT',
    GET_DISCHARGE_PATIENT_SUCCESS: 'USER-GET_DISCHARGE_PATIENT_SUCCESS',
    GET_DISCHARGE_PATIENT_PARAMS: 'USER-GET_DISCHARGE_PATIENT_PARAMS',
    GET_DISCHARGE_PATIENT_PARAMS_SUCCESS:
      'USER-GET_DISCHARGE_PATIENT_PARAMS_SUCCESS',
    SET_SELECTED_PATIENT: 'USER-SET_SELECTED_PATIENT',
    UPDATE_UNLISTED_PATIENT_ROUND_PARAMS:
      'USER-UPDATE_UNLISTED_PATIENT_ROUND_PARAMS',
    SET_USER_ACTION_TYPE: 'USER-SET_USER_ACTION_TYPE',
    RESET_USER_ACTION_TYPE: 'USER-RESET_USER_ACTION_TYPE',
    ADD_EMPLOYEE_TO_USER: 'USER-ADD_EMPLOYEE_TO_USER',
    REMOVE_EMPLOYEE_FROM_USER: 'USER-REMOVE_EMPLOYEE_FROM_USER',
    REMOVE_USER_AS_DELEGATE: 'USER-REMOVE_USER_AS_DELEGATE',
    SET_SELECTED_EMPLOYEE: 'USER-SET_SELECTED_EMPLOYEE',
    CLEAR_SELECTED_PATIENT: 'USER-CLEAR_SELECTED_PATIENT',
    POST_USER_ACCESS_REVOKE: 'USER-POST_USER_ACCESS_REVOKE',
    POST_USER_ACCESS_REVOKE_SUCCESS: 'USER-POST_USER_ACCESS_REVOKE_SUCCESS',
    POST_USER_ACCESS_REVOKE_FAILURE: 'USER-POST_USER_ACCESS_REVOKE_FAILURE',
    CLEAR_PATIENTS_TO_ROUND: 'USER-CLEAR_PATIENTS_TO_ROUND',
    POST_UPDATE_USER_PIC: 'USER-POST_UPDATE_USER_PIC',
    POST_UPDATE_USER_PIC_SUCCESS: 'USER-POST_UPDATE_USER_PIC_SUCCESS',
    POST_UPDATE_USER_PIC_FAILURE: 'USER-POST_UPDATE_USER_PIC_FAILURE',
    UPDATE_UNLISTED_PATIENT_MODAL_FLAG:
      'USER-UPDATE_UNLISTED_PATIENT_MODAL_FLAG',
    UPDATE_UNLISTED_EMPLOYEE_MODAL_FLAG:
      'USER-UPDATE_UNLISTED_EMPLOYEE_MODAL_FLAG',
    GET_PATIENTS_TO_ROUND_FOR_CENSUS: 'USER-GET_PATIENTS_TO_ROUND_FOR_CENSUS',
    GET_PATIENTS_TO_ROUND_FOR_CENSUS_SUCCESS:
      'USER-GET_PATIENTS_TO_ROUND_FOR_CENSUS_SUCCESS',
    ADD_DELEGATE_TO_USER: 'USER-ADD_DELEGATE_TO_USER',
    REMOVE_DELEGATE_FROM_USER: 'USER-REMOVE_DELEGATE_FROM_USER',
    CLEAR_SELECTED_EMPLOYEE: 'USER-CLEAR_SELECTED_EMPLOYEE',
    POST_EMPLOYEE_CONFIG: 'USER-POST_EMPLOYEE_CONFIG',
    POST_EMPLOYEE_CONFIG_SUCCESS: 'USER-POST_EMPLOYEE_CONFIG_SUCCESS',
    GET_EMPLOYEE_NOTES: 'USER-GET_EMPLOYEE_NOTES'
  },
  STOPLIGHT: {
    GET_STOPLIGHTS_BY_LOCATION: 'STOPLIGHT-GET_STOPLIGHTS_BY_LOCATION',
    GET_ALL_STOPLIGHTS_BY_LOCATION: 'STOPLIGHT-GET_ALL_STOPLIGHTS_BY_LOCATION',
    GET_STOPLIGHTS_BY_LOCATION_SUCCESS:
      'STOPLIGHT-GET_STOPLIGHTS_BY_LOCATION_SUCCESS',
    GET_STOPLIGHTS_BY_LOCATION_FAILED:
      'STOPLIGHT-GET_STOPLIGHTS_BY_LOCATION_FAILED',
    POST_NEW_STOPLIGHT: 'STOPLIGHT-POST_NEW_STOPLIGHT',
    POST_NEW_STOPLIGHT_SUCCESS: 'STOPLIGHT-POST_NEW_STOPLIGHT_SUCCESS',
    GET_STOPLIGHT_BY_ID: 'STOPLIGHT-GET_STOPLIGHT_BY_ID',
    GET_STOPLIGHT_BY_ID_SUCCESS: 'STOPLIGHT-GET_STOPLIGHT_BY_ID_SUCCESS',
    UPDATE_STOPLIGHT: 'STOPLIGHT-UPDATE_STOPLIGHT',
    UPDATE_STOPLIGHT_SUCCESS: 'STOPLIGHT-UPDATE_STOPLIGHT_SUCCESS',
    DELETE_STOPLIGHT: 'STOPLIGHT-DELETE_STOPLIGHT',
    DELETE_STOPLIGHT_SUCCESS: 'STOPLIGHT-DELETE_STOPLIGHT_SUCCESS',
    SEARCH_STOPLIGHTS: 'STOPLIGHT-SEARCH_STOPLIGHTS',
    SEARCH_STOPLIGHTS_SUCCESS: 'STOPLIGHT-SEARCH_STOPLIGHT_SUCCESS',
    CLEAR_SEARCH_STOPLIGHTS: 'STOPLIGHT-CLEAR_SEARCH_STOPLIGHTS',
    SHARE_STOPLIGHT: 'STOPLIGHT-SHARE_STOPLIGHT',
    SHARE_STOPLIGHT_SUCCESS: 'STOPLIGHT-SHARE_STOPLIGHT_SUCCESS',
    SHARE_STOPLIGHT_FAILED: 'STOPLIGHT-SHARE_STOPLIGHT_FAILED',
    CLEAR_STOPLIGHT_RESPONSE: 'STOPLIGHT-CLEAR_STOPLIGHT_RESPONSE',
    GET_DISCUSSION: 'STOPLIGHT-GET_DISCUSSION',
    GET_DISCUSSION_SUCCESS: 'STOPLIGHT-GET_DISCUSSION_SUCCESS',
    POST_COMMENT_TO_STOPLIGHT: 'STOPLIGHT-POST_COMMENT_TO_STOPLIGHT',
    POST_COMMENT_TO_STOPLIGHT_SUCCESS:
      'STOPLIGHT-POST_COMMENT_TO_STOPLIGHT_SUCCESS',
    SET_STOPLIGHT_FILTER: 'STOPLIGHT-SET_STOPLIGHT_FILTER',
    CLEAR_STOPLIGHT_FILTER: 'STOPLIGHT-CLEAR_STOPLIGHT_FILTER',
    SET_STOPLIGHT_SORT: 'STOPLIGHT-SET_STOPLIGHT_SORT',
    SET_CURRENT_STOPLIGHT_PAGE: 'STOPLIGHT-SET_CURRENT_STOPLIGHT_PAGE',
    CLEAR_SELECTED_STOPLIGHT: 'STOPLIGHT-CLEAR_SELECTED_STOPLIGHT',
    SAVE_STOPLIGHT_DATA: 'STOPLIGHT-SAVE_STOPLIGHT_DATA',
    SET_STOPLIGHT_SEARCH_QUERY: 'STOPLIGHT-SET_STOPLIGHT_SEARCH_QUERY',
    ADD_STOPLIGHT_TO_SHARING_Q: 'STOPLIGHT-ADD_STOPLIGHT_TO_SHARING_Q'
  },
  TASK: {
    GET_ISSUES_BY_FACILITY: 'GET_ISSUES_BY_FACILITY',
    GET_ALL_ISSUES_BY_FACILITY: 'TASK-GET_ALL_ISSUES_BY_FACILITY',
    GET_ISSUES_BY_FACILITY_SUCCESS: 'GET_ISSUES_BY_FACILITY_SUCCESS',
    CLEAR_FACILITY_ISSUES: 'TASK-CLEAR_FACILITY_ISSUES',
    POST_NEW_TASK: 'TASK-POST_NEW_TASK',
    POST_NEW_TASK_SUCCESS: 'TASK-POST_NEW_TASK_SUCCESS',
    POST_NEW_TASK_FAIL: 'TASK-POST_NEW_TASK_FAIL',
    ADD_TASK_TO_SHARING_Q: 'TASK-ADD_TASK_TO_SHARING_Q',
    CLEAR_SHARING_Q: 'TASK-CLEAR_SHARING_Q',
    SHARE_TASK: 'TASK-SHARE_TASK',
    SHARE_TASK_SUCCESS: 'TASK-SHARE_TASK_SUCCESS',
    SHARE_TASK_FAILED: 'TASK-SHARE_TASK_FAILED',
    SHARE_ALL_TASK_SUCCESS: 'TASK-SHARE_ALL_TASK_SUCCESS',
    GET_CATEGORIES: 'TASK-GET_CATEGORIES',
    GET_CATEGORIES_SUCCESS: 'TASK-GET_CATEGORIES_SUCCESS',
    GET_CATEGORIES_FAILURE: 'TASK-GET_CATEGORIES_FAILURE',
    GET_FEEDBACK_CATEGORIES: 'TASK-GET_FEEDBACK_CATEGORIES',
    GET_FEEDBACK_CATEGORIES_SUCCESS: 'TASK-GET_FEEDBACK_CATEGORIES_SUCCESS',
    SELECT_DATA_FOR_EDIT: 'TASK-SELECT_DATA_FOR_EDIT',
    RESET_SELECTED_DATA_FOR_EDIT: 'TASK-RESET_SELECTED_DATA_FOR_EDIT',
    GET_EMP_SHARED_WITH: 'TASK-GET_EMP_SHARED_WITH',
    GET_EMP_SHARED_WITH_SUCCESS: 'TASK-GET_EMP_SHARED_WITH_SUCCESS',
    RESET_EMP_SHARED_WITH: 'TASK-RESET_EMP_SHARED_WITH',
    COMPLETE_TASK: 'TASK-COMPLETE_TASK',
    COMPLETE_TASK_SUCCESS: 'TASK-COMPLETE_TASK_SUCCESS',
    DELETE_TASK: 'TASK-DELETE_TASK',
    DELETE_TASK_SUCCESS: 'TASK-DELETE_TASK_SUCCESS',
    GET_TASKS_BY_ACCOUNT_NUMBER: 'TASK-GET_TASKS_BY_ACCOUNT_NUMBER',
    GET_TASKS_BY_ACCOUNT_NUMBER_SUCCESS:
      'TASK-GET_TASKS_BY_ACCOUNT_NUMBER_SUCCESS',
    CLEAR_TASKS_BY_ACCOUNT_NUMBER: 'TASK-CLEAR_TASKS_BY_ACCOUNT_NUMBER',
    CLEAR_TASKS_BY_EMPLOYEE_ID: 'TASK-CLEAR_TASKS_BY_EMPLOYEE_ID',
    SET_CURRENT_ISSUE_PAGE: 'TASK-SET_CURRENT_ISSUE_PAGE',
    GET_ISSUE_ON_ROUND_BY_EMPLOYEE_ID: 'TASK-GET_ISSUE_ON_ROUND_BY_EMPLOYEE_ID',
    GET_ISSUE_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS:
      'TASK-GET_ISSUE_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS',
    SEARCH_ISSUES: 'TASK-SEARCH_ISSUES',
    SEARCH_ISSUES_SUCCESS: 'TASK-SEARCH_ISSUES_SUCCESS',
    CLEAR_SEARCH_ISSUES: 'TASK-CLEAR_SEARCH_ISSUES',
    SAVE_TASK_DATA: 'TASK-SAVE_TASK_DATA',
    CLEAR_TASK_DATA: 'TASK-CLEAR_TASK_DATA',
    SAVE_TASK_MODAL_STATE: 'TASK-SAVE_TASK_MODAL_STATE',
    CLEAR_TASK_MODAL_STATE: 'TASK-CLEAR_TASK_MODAL_STATE',
    GET_TICKET_DETAILS: 'TASK-GET_TICKET_DETAILS',
    GET_TICKET_DETAILS_SUCCESS: 'TASK-GET_TICKET_DETAILS_SUCCESS',
    CLEAR_TICKET_DETAILS: 'TASK-CLEAR_TICKET_DETAILS',
    GET_SC_LOCATIONS: 'TASK-GET_SC_LOCATIONS',
    GET_SC_LOCATIONS_SUCCESS: 'TASK-GET_SC_LOCATIONS_SUCCESS',
    GET_GE_LOCATIONS: 'TASK-GET_GE_LOCATIONS',
    GET_GE_LOCATIONS_SUCCESS: 'TASK-GET_GE_LOCATIONS_SUCCESS',
    VERIFY_CONTROL_NUMBER: 'TASK-VERIFY_CONTROL_NUMBER',
    VERIFY_CONTROL_NUMBER_SUCCESS: 'TASK-VERIFY_CONTROL_NUMBER_SUCCESS',
    VERIFY_CONTROL_NUMBER_FAILURE: 'TASK-VERIFY_CONTROL_NUMBER_FAILURE',
    CLEAR_VERIFY_CONTROL_NUMBER: 'TASK-CLEAR_VERIFY_CONTROL_NUMBER',
    GET_SINGLE_TASK_TO_COMPLETE: 'TASK-GET_SINGLE_TASK_TO_COMPLETE',
    GET_SINGLE_TASK_TO_COMPLETE_SUCCESS:
      'TASK-GET_SINGLE_TASK_TO_COMPLETE_SUCCESS',
    CLEAR_SINGLE_TASK_TO_COMPLETE: 'TASK-CLEAR_SINGLE_TASK_TO_COMPLETE',
    GET_FEEDBACK_BY_UNIT_IDS: 'TASK-GET_FEEDBACK_BY_UNIT_IDS',
    GET_FEEDBACK_BY_UNIT_IDS_SUCCESS: 'TASK-GET_FEDBACK_BY_UNIT_IDS_SUCCESS',
    GET_FEEDBACK_BY_UNIT_IDS_FAILED: 'TASK-GET_FEDBACK_BY_UNIT_IDS_FAILED',
    GET_FEEDBACK_BY_EMPLOYEE_LIST: 'TASK-GET_FEEDBACK_BY_EMPLOYEE_LIST',
    GET_FEEDBACK_BY_EMPLOYEE_LIST_SUCCESS:
      'TASK-GET_FEEDBACK_BY_EMPLOYEE_LIST_SUCCESS',
    GET_FEEDBACK_BY_EMPLOYEE_LIST_FAILED:
      'TASK-GET_FEEDBACK_BY_EMPLOYEE_LIST_FAILED',
    SET_CURRENT_FEEDBACK_PAGE: 'TASK-SET_CURRENT_FEEDBACK_PAGE',
    GET_FEEDBACK_ON_ROUND_BY_EMPLOYEE_ID:
      'TASK-GET_FEEDBACK_ON_ROUND_BY_EMPLOYEE_ID',
    GET_FEEDBACK_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS:
      'TASK-GET_FEEDBACK_ON_ROUND_BY_EMPLOYEE_ID_SUCCESS',
    SET_ISSUE_FILTER: 'TASK-SET_ISSUE_FILTER',
    SET_FEEDBACK_FILTER: 'TASK-SET_FEEDBACK_FILTER',
    CLEAR_ISSUE_FILTER: 'TASK-CLEAR_ISSUE_FILTER',
    CLEAR_FEEDBACK_FILTER: 'TASK-CLEAR_FEEDBACK_FILTER',
    SET_TASK_SORT: 'TASK-SET_TASK_SORT',
    GET_TASK_DISCUSSION: 'TASK-GET_TASK_DISCUSSION',
    POST_TASK_COMMENT: 'TASK-POST_TASK_COMMENT',
    GET_ISSUE_BY_ID: 'TASK-GET_ISSUE_BY_ID',
    GET_ISSUE_BY_ID_SUCCESS: 'TASK-GET_ISSUE_BY_ID_SUCCESS',
    CLEAR_ISSUE_BY_ID: 'TASK-CLEAR_ISSUE_BY_ID',
    GET_ISSUE_LIST_BY_FACILITY: 'TASK-GET_ISSUE_LIST_BY_FACILITY',
    GET_ALL_ISSUES_LIST_BY_FACILITY: 'TASK-GET_ALL_ISSUES_LIST_BY_FACILITY',
    GET_ISSUE_LIST_BY_FACILITY_SUCCESS:
      'TASK-GET_ISSUE_LIST_BY_FACILITY_SUCCESS',
    GET_ISSUE_LIST_BY_FACILITY_FAILURE:
      'TASK-GET_ISSUE_LIST_BY_FACILITY_FAILURE',
    GET_FEEDBACK_LIST_BY_FACILITY: 'TASK-GET_FEEDBACK_LIST_BY_FACILITY',
    GET_FEEDBACK_LIST_BY_FACILITY_SUCCESS:
      'TASK-GET_FEEDBACK_LIST_BY_FACILITY_SUCCESS',
    GET_FEEDBACK_LIST_BY_FACILITY_FAILURE:
      'TASK-GET_FEEDBACK_LIST_BY_FACILITY_FAILURE',
    SET_TASK_SEARCH_QUERY: 'TASK-SET_TASK_SEARCH_QUERY',
    GET_ALL_FACILITY_FEEDBACK: 'TASK-GET_ALL_FACILITY_FEEDBACK',
    GET_ALL_FEEDBACK_LIST_BY_FACILITY_ID:
      'TASK-GET_ALL_FEEDBACK_LIST_BY_FACILITY_ID'
  },
  ADMIN: {
    // TODO: Should these have User in the string
    GET_USERS: 'USER-GET_USERS',
    GET_USERS_SUCCESS: 'USER-GET_USERS_SUCCESS',
    GET_USERS_FAIL: 'USER-GET_USERS_FAIL',
    GET_USERS_TO_EXPORT: 'USER-GET_USERS_TO_EXPORT',
    GET_CSV_TO_EXPORT: 'USER-GET_CSV_TO_EXPORT',
    GET_CSV_TO_EXPORT_SUCCESS: 'USER-GET_CSV_TO_EXPORT_SUCCESS',
    GET_CSV_TO_EXPORT_FAIL: 'USER-GET_CSV_TO_EXPORT_FAIL',
    CLEAR_CSV_TO_EXPORT: 'USER-CLEAR_CSV_TO_EXPORT',
    GET_ENCOUNTERS: 'USER-GET_ENCOUNTERS',
    GET_ENCOUNTERS_SUCCESS: 'USER-GET_ENCOUNTERS_SUCCESS',
    GET_ENCOUNTERS_FAIL: 'USER-GET_ENCOUNTERS_FAIL',
    SET_ADMIN_USER_TAB: 'ADMIN-SET_ADMIN_USER_TAB',
    SET_ADMIN_USER_FILTER: 'ADMIN-SET_ADMIN_USER_FILTER',
    CLEAR_ADMIN_USER_FILTER: 'ADMIN-CLEAR_ADMIN_USER_FILTER',
    SET_ADMIN_FACILITY: 'ADMIN-SET_ADMIN_FACILITY',
    SET_ADMIN_SEARCH_TERM: 'ADMIN-SET_ADMIN_SEARCH_TERM',
    GET_API_VERSION: 'ADMIN-GET_API_VERSION',
    GET_API_VERSION_SUCCESS: 'ADMIN-GET_API_VERSION_SUCCESS'
  },
  REPORT: {
    SEARCH_PATIENT_HISTORY: 'REPORT-SEARCH_PATIENT_HISTORY',
    SEARCH_PATIENT_HISTORY_SUCCESS: 'REPORT-SEARCH_PATIENT_HISTORY_SUCCESS',
    CLEAR_PATIENT_SEARCH: 'REPORT-CLEAR_PATIENT_SEARCH',
    GET_PATIENT_ROUND_HISTORY: 'REPORT-GET_PATIENT_ROUND_HISTORY',
    GET_PATIENT_ROUND_HISTORY_SUCCESS:
      'REPORT-GET_PATIENT_ROUND_HISTORY_SUCCESS',
    CLEAR_PATIENT_ROUND_HISTORY: 'REPORT-CLEAR_PATIENT_ROUND_HISTORY',
    GET_EMPLOYEE_ROUND_HISTORY: 'REPORT-GET_EMPLOYEE_ROUND_HISTORY',
    GET_EMPLOYEE_ROUND_HISTORY_SUCCESS:
      'REPORT-GET_EMPLOYEE_ROUND_HISTORY_SUCCESS',
    CLEAR_EMPLOYEE_ROUND_HISTORY: 'REPORT-CLEAR_EMPLOYEE_ROUND_HISTORY',
    GET_PATIENT_ROUNDS: 'REPORT-GET_PATIENT_ROUNDS',
    GET_PATIENT_ROUNDS_SUCCESS: 'REPORT-GET_PATIENT_ROUNDS_SUCCESS',
    GET_PATIENT_ROUNDS_FAILURE: 'REPORT-GET_PATIENT_ROUNDS_FAILURE',
    SET_CURRENT_PATIENT_ROUNDS_PAGE: 'REPORT-SET_CURRENT_PATIENT_ROUNDS_PAGE',
    SET_PATIENT_ROUNDS_SEARCH_QUERY: 'REPORT-SET_PATIENT_ROUNDS_SEARCH_QUERY',
    SET_PATIENT_ROUNDS_SORT: 'SET_PATIENT_ROUNDS_SORT',
    GET_PATIENT_ROUNDS_REPORT: 'REPORT-GET_PATIENT_ROUNDS_REPORT',
    GET_EMPLOYEE_ROUNDS: 'REPORT-GET_EMPLOYEE_ROUNDS',
    GET_EMPLOYEE_ROUNDS_SUCCESS: 'REPORT-GET_EMPLOYEE_ROUNDS_SUCCESS',
    GET_EMPLOYEE_ROUNDS_FAILURE: 'REPORT-GET_EMPLOYEE_ROUNDS_FAILURE',
    SET_CURRENT_EMPLOYEE_ROUNDS_PAGE: 'REPORT-SET_CURRENT_EMPLOYEE_ROUNDS_PAGE',
    SET_EMPLOYEE_ROUNDS_SEARCH_QUERY: 'REPORT-SET_EMPLOYEE_ROUNDS_SEARCH_QUERY',
    SET_EMPLOYEE_ROUNDS_SORT: 'SET_EMPLOYEE_ROUNDS_SORT',
    CLEAR_EMPLOYEE_ROUNDS: 'REPORT-CLEAR_EMPLOYEE_ROUNDS',
    GET_EMPLOYEE_FREQ: 'REPORT-GET_EMPLOYEE_FREQ',
    GET_EMPLOYEE_FREQ_SUCCESS: 'REPORT-GET_EMPLOYEE_FREQ_SUCCESS',
    GET_EMPLOYEE_FREQ_FAILURE: 'REPORT-GET_EMPLOYEE_FREQ_FAILURE',
    SET_CURRENT_EMPLOYEE_FREQ_PAGE: 'REPORT-SET_CURRENT_EMPLOYEE_FREQ_PAGE',
    SET_EMPLOYEE_FREQ_SEARCH_QUERY: 'REPORT-SET_EMPLOYEE_FREQ_SEARCH_QUERY',
    SET_EMPLOYEE_FREQ_SORT: 'SET_EMPLOYEE_FREQ_SORT',
    CLEAR_EMPLOYEE_FREQ: 'REPORT-CLEAR_EMPLOYEE_FREQ',
    GET_EMPLOYEE_ROUNDS_REPORT: 'REPORT-GET_EMPLOYEE_ROUNDS_REPORT',
    GET_EMPLOYEE_FREQ_REPORT: 'REPORT-GET_EMPLOYEE_FREQ_REPORT',
    SET_ROUNDS_REPORT_FILTER: 'REPORT-SET_ROUNDS_REPORT_FILTER',
    CLEAR_ROUNDS_REPORT_FILTER: 'REPORT-CLEAR_ROUNDS_REPORT_FILTER',
    GET_COMPLIANCE_REPORT: 'REPORT-GET_COMPLIANCE_REPORT',
    GET_COMPLIANCE_REPORT_SUCCESS: 'REPORT-GET_COMPLIANCE_REPORT_SUCCESS',
    GET_COMPLIANCE_REPORT_FAILURE: 'REPORT-GET_COMPLIANCE_REPORT_FAILURE',
    GET_CENSUS_REPORT: 'REPORT-GET_CENSUS_REPORT',
    GET_CENSUS_REPORT_SUCCESS: 'REPORT-GET_CENSUS_REPORT_SUCCESS',
    GET_CENSUS_REPORT_FAILURE: 'REPORT-GET_CENSUS_REPORT_FAILURE'
  },
  MODAL: {
    OPEN_MODAL: 'MODAL-OPEN_MODAL',
    CLOSE_MODAL: 'MODAL-CLOSE_MODAL',
    SET_MODAL_TYPE: 'MODAL-SET_MODAL_TYPE',
    SET_MODAL_ACTION: 'MODAL-SET_MODAL_ACTION',
    SET_MODAL_DATA: 'MODAL-MODAL_DATA'
  },
  TEMPLATE: {
    GET_TEMPLATE_MASTER: 'TEMPLATE-GET_TEMPLATE_MASTER',
    GET_TEMPLATE_MASTER_SUCCESS: 'TEMPLATE-GET_TEMPLATE_MASTER_SUCCESS',
    CLEAR_CHOSEN_TEMPLATE: 'TEMPLATE-CLEAR_CHOSEN_TEMPLATE',
    SET_CHOSEN_TEMPLATE: 'TEMPLATE-SET_CHOSEN_TEMPLATE',
    GET_ROUND_SURVEY: 'TEMPLATE-GET_ROUND_SURVEY',
    GET_ROUND_SURVEY_SUCCESS: 'TEMPLATE-GET_ROUND_SURVEY_SUCCESS',
    SET_CHOSEN_ROUND_TEMPLATE: 'TEMPLATE-SET_CHOSEN_ROUND_TEMPLATE',
    GET_ALL_QUESTIONS: 'TEMPLATE-GET_ALL_QUESTIONS',
    GET_ALL_QUESTIONS_SUCCESS: 'TEMPLATE-GET_ALL_QUESTIONS_SUCCESS',
    SET_QUESTIONS_OF_TEMPLATE: 'TEMPLATE-SET_QUESTIONS_OF_TEMPLATE',
    ADD_NEW_QUESTION: 'TEMPLATE-ADD_NEW_QUESTION',
    ADD_NEW_QUESTION_SUCCESS: 'TEMPLATE-ADD_NEW_QUESTION_SUCCESS',
    GET_TEMPLATE_BY_ID: 'TEMPLATE-GET_TEMPLATE_BY_ID',
    GET_TEMPLATE_BY_ID_SUCCESS: 'TEMPLATE-GET_TEMPLATE_BY_ID_SUCCESS',
    EDIT_SELECTED_QUESTION: 'EDIT_SELECTED_QUESTION',
    EDIT_QUESTION: 'EDIT_QUESTION',
    EDIT_QUESTION_SUCCESS: 'EDIT_QUESTION_SUCCESS',
    RESET_EDIT_QUESTION: 'RESET_EDIT_QUESTION',
    GET_TEMPLATE_TREE_VIEW: 'TEMPLATE-GET_TEMPLATE_TREE_VIEW',
    GET_TEMPLATE_TREE_VIEW_SUCCESS: 'TEMPLATE-GET_TEMPLATE_TREE_VIEW_SUCCESS',
    POST_TEMPLATE_CONFIG: 'TEMPLATE-POST_TEMPLATE_CONFIG',
    POST_TEMPLATE_CONFIG_SUCCESS: 'TEMPLATE-POST_TEMPLATE_CONFIG_SUCCESS',
    POST_MASTER_TEMPLATE: 'TEMPLATE-POST_MASTER_TEMPLATE',
    EDIT_MASTER_TEMPLATE_SUCCESS: 'TEMPLATE-EDIT_MASTER_TEMPLATE_SUCCESS',
    CREATE_MASTER_TEMPLATE_SUCCESS: 'TEMPLATE-CREATE_MASTER_TEMPLATE_SUCCESS',
    UPDATE_MASTER_TEMPLATE_SUCCESS_FLAGS:
      'TEMPLATE-UPDATE_MASTER_TEMPLATE_SUCCESS_FLAGS',
    UPDATE_TEMPLATE_CONFIG_SUCCESS_FLAGS:
      'TEMPLATE-UPDATE_TEMPLATE_CONFIG_SUCCESS_FLAGS',
    GET_TEMPLATE_DOC: 'TEMPLATE-GET_TEMPLATE_DOC',
    GET_TEMPLATE_DOC_SUCCESS: 'TEMPLATE-GET_TEMPLATE_DOC_SUCCESS',
    GET_ACTIVE_PATIENT_TEMPLATES: 'TEMPLATE-GET_ACTIVE_PATIENT_TEMPLATES',
    GET_ACTIVE_PATIENT_TEMPLATES_SUCCESS:
      'TEMPLATE-GET_ACTIVE_PATIENT_TEMPLATES_SUCCESS'
  },
  ROUND: {
    SET_ROUND_STARTED: 'ROUND-SET_ROUND_STARTED',
    SET_ROUND_CANCELLED: 'ROUND-SET_ROUND_CANCELLED',
    SET_CURRENT_ROUND: 'ROUND-SET_CURRENT_ROUND',
    COMPLETE_ROUND: 'ROUND-COMPLETE_ROUND',
    COMPLETE_ROUND_SUCCESS: 'ROUND-COMPLETE_ROUND_SUCCESS',
    COMPLETE_ROUND_FAILURE: 'ROUND-COMPLETE_ROUND_FAILURE',
    SET_PENDING_FEEDBACK: 'ROUND-SET_PENDING_FEEDBACK',
    SET_PENDING_ISSUE: 'ROUND-SET_PENDING_ISSUE',
    GET_ROUND: 'ROUND-GET_ROUND',
    GET_ROUND_SUCCESS: 'ROUND-GET_ROUND_SUCCESS',
    GET_ROUND_FAILURE: 'ROUND-GET_ROUND_FAILURE',
    SET_ROUND_EDITING: 'ROUND-SET_ROUND_EDITING',
    SET_ROUND_VIEW_ONLY: 'ROUND-SET_ROUND_VIEW_ONLY',
    CLEAR_ROUNDING_STATE: 'ROUND-CLEAR_ROUNDING_STATE',
    SET_PAST_ROUND_ID: 'ROUND-SET_PAST_ROUND_ID'
  },
  PATIENT: {
    ADD_NOTE: 'PATIENT-ADD_NOTE',
    ADD_NOTE_SUCCESS: 'PATIENT-ADD_NOTE_SUCCESS',
    REMOVE_NOTE: 'PATIENT-REMOVE_NOTE',
    REMOVE_NOTE_SUCCESS: 'PATIENT-REMOVE_NOTE_SUCCESS',
    PATIENT_DETAILS: 'PATIENT-PATIENT_DETAILS',
    PATIENT_DETAILS_SUCCESS: 'PATIENT-PATIENT_DETAILS_SUCCESS'
  },
  ACCOUNT: {
    POST_ADD_PRIMARY: 'ACCOUNT-POST_ADD_PRIMARY',
    POST_ADD_PRIMARY_SUCCESS: 'ACCOUNT-POST_ADD_PRIMARY_SUCCESS',
    POST_ADD_PRIMARY_FAILURE: 'ACCOUNT-POST_ADD_PRIMARY_FAILURE',
    POST_REMOVE_PRIMARY: 'ACCOUNT-POST_REMOVE_PRIMARY',
    POST_REMOVE_PRIMARY_SUCCESS: 'ACCOUNT-POST_REMOVE_PRIMARY_SUCCESS',
    POST_REMOVE_PRIMARY_FAILURE: 'ACCOUNT-POST_REMOVE_PRIMARY_FAILURE',
    POST_CURRENT_FACILITY: 'ACCOUNT-POST_CURRENT_FACILITY',
    POST_CURRENT_FACILITY_SUCCESS: 'ACCOUNT-POST_CURRENT_FACILITY_SUCCESS',
    POST_CURRENT_FACILITY_FAILURE: 'ACCOUNT-POST_CURRENT_FACILITY_FAILURE'
  },
  TOAST: {
    SLIDE_IN_TOAST: 'SLIDE-IN-TOAST',
    SLIDE_OUT_TOAST: 'SLIDE-OUT-TOAST',
    HIDE_TOAST: 'HIDE-TOAST',
    SET_TOAST_OPTIONS: 'SET-TOAST-OPTIONS',
    SET_TOAST_TYPE: 'SET-TOAST-TYPE'
  },
  FEATURE: {
    SET_FEATURES: 'SET-FEATURES'
  }
};

export default ACTIONS;

import { FC } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { NeuButton, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { openModal, setModalType } from '../../../redux/actions/Modal.action';
import { logTaskClick } from '../../../utils/analyticsHelpers';
import { RootState } from '../../../config/interfaces';

const AddBtn: FC<{}> = () => {
  const dispatch = useDispatch();

  let addType: string;
  const { logTrackingEvent } = useAnalyticsApi();
  const { '*': view } = useParams();

  // Determining which modal the button should open based on view
  if (view === 'issues') {
    addType = 'Add Issue';
  } else if (view === 'feedback') {
    addType = 'Add Feedback';
  } else {
    addType = 'Add Stoplight';
  }

  const openTheModal = () => {
    if (view) {
      logTrackingEvent(logTaskClick(view, 'add'));
    } else {
      console.log('No matching type for event!');
    }
    dispatch(setModalType(addType));
    dispatch(openModal());
  };

  return (
    <NeuButton
      id="Add-Button"
      className="mr-5"
      color="primary"
      fill="raised"
      onClick={() => openTheModal()}
    >
      <NeuIcon className="mr-2" feedback="default">
        add
      </NeuIcon>
      {view === 'issues' && 'Add Issue'}
      {view === 'feedback' && 'Add Feedback'}
      {view === 'stoplight' && 'Add Stoplight'}
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    modalState: state.ModalReducer.modal
  };
};

export default connect(mapReduxStateToProps)(AddBtn);

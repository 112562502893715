// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  authorizedUser: {},
  isAuthenticated: Boolean(window.localStorage.getItem('authToken')),
  user34: '',
  loading: false,
  authRoles: [],
  employeesDelegatedTo: []
};

const AuthorizedUser = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };
  switch (action.type) {
    case ACTIONS.AUTH.GET_USER: {
      return {
        ...state,
        loading: true
      };
    }
    case ACTIONS.AUTH.GET_USER_SUCCESS: {
      return {
        ...state,
        authorizedUser: action.data,
        authRoles: action.data.roles,
        employeesDelegatedTo:
          action.data.employeeInfo?.employeesDelegatedTo ?? [],
        loading: false
      };
    }
    case ACTIONS.AUTH.GET_AUTH_TOKEN_SUCCESS: {
      return {
        ...state,
        isAuthenticated: true
      };
    }
    case ACTIONS.AUTH.GET_USER34_SUCCESS: {
      return {
        ...state,
        user34: action.data
      };
    }
    case ACTIONS.AUTH.SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.data
      };
    case ACTIONS.AUTH.CLEAR_USER:
      return {
        ...state,
        authorizedUser: {},
        isAuthenticated: false,
        user34: '',
        authRoles: [],
        employeesDelegatedTo: []
      };
    default:
      return tempState;
  }
};

export default AuthorizedUser;

import { call, put, select } from 'redux-saga/effects';
import { getData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';

import { toast } from '../../services/Toast';
import { setCsvToExport } from '../actions/Admin.action';

import { date, employeeHireDateHelper } from '../../utils/helpers';

// Report sagas
export function* searchPatientHistory(action) {
  let url;
  const {
    data: { facilityId, patientSearch }
  } = action;
  if (patientSearch.match(/\d+/g)) {
    url = `${API.patientHistorySearchUrl}?facilityId=${facilityId}&mrn=${patientSearch}&page=-1`;
  } else {
    url = `${API.patientHistorySearchUrl}?facilityId=${facilityId}&name=${patientSearch}&page=-1`;
  }
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.REPORT.SEARCH_PATIENT_HISTORY_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getPatientRoundHistory(action) {
  const { facilityId, patientId } = action.data;
  const url = `${API.patientRoundHistoryUrl}?facilityId=${facilityId}&patientId=${patientId}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.REPORT.GET_PATIENT_ROUND_HISTORY_SUCCESS,
        data
      });
    } else {
      console.log(`ERROR :${status}`);
    }
  } catch (error) {
    console.log(`ERROR IS :${error}`);
  }
}

export function* getEmployeeRoundHistory(action) {
  const { employeeId, type, supportType } = action.data;
  const url = `${API.employeeRoundHistoryUrl}?employeeId=${employeeId}${
    type === 'support' ? `&supportType=${supportType}` : ''
  }`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.REPORT.GET_EMPLOYEE_ROUND_HISTORY_SUCCESS,
        data
      });
    } else {
      console.log(`ERROR :${status}`);
    }
  } catch (error) {
    console.log(`ERROR IS :${error}`);
  }
}

export function* getPatientRounds(action) {
  const params = new URLSearchParams(action.data).toString();
  const url = `${API.getPatientRoundsUrl}?${params}`;

  if (action.data.unitId.length === 0)
    return yield put({
      type: ACTIONS.REPORT.GET_PATIENT_ROUNDS_SUCCESS,
      data: {
        roundReportList: [],
        totalRecordCount: 0
      }
    });
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      return yield put({
        type: ACTIONS.REPORT.GET_PATIENT_ROUNDS_SUCCESS,
        data
      });
    }
    return yield put({
      type: ACTIONS.REPORT.GET_PATIENT_ROUNDS_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.REPORT.GET_PATIENT_ROUNDS_FAILURE
    });
  }
}

export function* getPatientRoundsReport(action) {
  const {
    facilityId,
    unitId,
    query,
    roundedBy,
    sortBy,
    sortOrder,
    startday,
    endday
  } = action.data;

  const params = new URLSearchParams({
    facilityId,
    unitId,
    query,
    roundedBy,
    sortBy,
    sortOrder,
    startday,
    endday,
    isExport: true
  });
  const url = `${API.getPatientRoundsReport}?${params}`;

  yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT });

  try {
    const { data, status } = yield call(getData, url, action.data);
    if (status === 200) {
      const patientRoundsReportList =
        data?.map(round => {
          // This is mapping out the answers
          const answersWithIndex =
            round.answers?.map((ans, index) => {
              return {
                [`question${index + 1}`]: ans?.question?.replaceAll('"', ''),
                [`answer${index + 1}`]:
                  ans?.answer && ans.answer.length > 0
                    ? ans?.answer[0].value
                    : ''
              };
            }) || [];

          const ansObject = answersWithIndex.reduce((acc, value) => {
            return {
              ...acc,
              ...value
            };
          }, {});

          return {
            unit: round?.unit || '',
            room: round?.room || '',
            patientId: round?.patientId || '',
            patient: `${round?.patientFirstName} ${round?.patientLastName}`,
            patientFirstName: round?.patientFirstName || '',
            patientLastName: round?.patientLastName || '',
            patientMRN: round?.patientMRN || '',
            createdUserName: `${round?.userFirstName} ${round?.userLastName}`,
            createdUserId: round?.userId || '',
            accountNumber: round?.accountNum || '',
            facilityId: round?.facilityId || '',
            status: round.status === 'rounded' ? 'Rounded' : 'Unable To Round',
            date:
              round?.roundDate && date.isOrbitTimestamp(round?.roundDate)
                ? date.parseDate(round?.roundDate).toLocaleString([], {
                    day: '2-digit',
                    month: '2-digit',
                    year: '2-digit'
                  })
                : '',
            time:
              round?.roundDate && date.isOrbitTimestamp(round?.roundDate)
                ? date.parseDate(round?.roundDate).toLocaleString([], {
                    hour: '2-digit',
                    minute: '2-digit'
                  })
                : '',
            templateName: round?.templateName || '',
            ...ansObject
          };
        }) || [];

      return yield put(setCsvToExport(patientRoundsReportList));
    }
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL });
  } catch (error) {
    console.log(error);
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL });
  }
}

export function* getEmployeeRounds(action) {
  const params = new URLSearchParams(action.data).toString();
  const url = `${API.getEmployeeRoundsUrl}?${params}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      return yield put({
        type: ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_SUCCESS,
        data
      });
    }
    return yield put({
      type: ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.REPORT.GET_EMPLOYEE_ROUNDS_FAILURE
    });
  }
}

export function* getEmployeeRoundsReport(action) {
  const { coreEmpRoundingTemplates } = yield select(
    state => state.ConfigReducer
  );

  const {
    facilityId,
    query,
    roundedBy,
    templateName,
    sortBy,
    sortOrder,
    startday,
    endday
  } = action.data;

  const params = new URLSearchParams({
    facilityId,
    query,
    roundedBy,
    templateName,
    startday,
    endday
  });
  const url = `${API.getEmployeeRoundsReport}?${params}`;

  yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT });

  try {
    const { data, status } = yield call(getData, url, action.data);
    if (status === 200) {
      const mostRecentRoundCleanData =
        data
          ?.filter(round =>
            coreEmpRoundingTemplates.includes(round.templateName)
          )
          .reduce((acc, round) => {
            const foundEmpIndex = acc.findIndex(
              r => r.employeeId === round.employeeId
            );
            if (foundEmpIndex === -1) {
              return [
                ...acc,
                {
                  roundId: round?.roundId || '',
                  department: round?.employeeDept || 'Unknown',
                  employee:
                    `${round?.employeeFirstName} ${round?.employeeLastName}` ||
                    '',
                  employeeId: round?.employeeId || '',
                  employeeFirstName: round?.employeeFirstName || '',
                  employeeLastName: round?.employeeLastName || '',
                  employeeHireDate: employeeHireDateHelper(
                    round?.employeeHireDate
                  )
                    ? date.datePrettier(round?.employeeHireDate)
                    : 'Unknown',
                  roundedBy: `${round?.userFirstName} ${round?.userLastName}`,
                  createdUserId: round?.userId || '',
                  facilityId: round?.facilityId || '',
                  date: round?.roundDate || '',
                  lastRound: round?.roundDate
                    ? date.dayOfYr(round?.roundDate)
                    : '',
                  status: 'Rounded',
                  templateName: round?.templateName || '',
                  answers: round?.answers || []
                }
              ];
            }
            if (
              round?.roundDate &&
              round?.roundDate > acc[foundEmpIndex].date
            ) {
              acc[foundEmpIndex].roundId = round?.roundId || '';
              acc[foundEmpIndex].department = round?.employeeDept || 'Unknown';
              acc[foundEmpIndex].employee =
                `${round?.employeeFirstName} ${round?.employeeLastName}` || '';
              acc[foundEmpIndex].employeeFirstName =
                round?.employeeFirstName || '';
              acc[foundEmpIndex].employeeLastName =
                round?.employeeLastName || '';
              acc[foundEmpIndex].employeeHireDate = employeeHireDateHelper(
                round?.employeeHireDate
              )
                ? date.datePrettier(round?.employeeHireDate)
                : 'Unknown';
              acc[
                foundEmpIndex
              ].roundedBy = `${round?.userFirstName} ${round?.userLastName}`;
              acc[foundEmpIndex].createdUserId = round?.userId || '';
              acc[foundEmpIndex].facilityId = round?.facilityId || '';
              acc[foundEmpIndex].date = round?.roundDate || '';
              acc[foundEmpIndex].lastRound = round?.roundDate
                ? date.dayOfYr(round?.roundDate)
                : '';
              acc[foundEmpIndex].status = 'Rounded';
              acc[foundEmpIndex].templateName = round?.templateName || '';
              acc[foundEmpIndex].answers = round?.answers || [];
            }
            return [...acc];
          }, []) || [];

      // TODO: Front-end has to handle sorting until mobile no longer needs export endpoint
      if (sortBy === 'employeeDept') {
        if (sortOrder === 'asc') {
          mostRecentRoundCleanData.sort((a, b) =>
            a.department.localeCompare(b.department)
          );
        } else {
          mostRecentRoundCleanData.sort((a, b) =>
            b.department.localeCompare(a.department)
          );
        }
      } else if (sortBy === 'employeeName') {
        if (sortOrder === 'asc') {
          mostRecentRoundCleanData.sort((a, b) =>
            a.employee.localeCompare(b.employee)
          );
        } else {
          mostRecentRoundCleanData.sort((a, b) =>
            b.employee.localeCompare(a.employee)
          );
        }
      } else if (sortBy === 'status') {
        if (sortOrder === 'asc') {
          mostRecentRoundCleanData.sort(() => 0);
        } else {
          mostRecentRoundCleanData.sort(() => 0);
        }
      } else if (sortBy === 'roundedBy') {
        if (sortOrder === 'asc') {
          mostRecentRoundCleanData.sort((a, b) =>
            a.roundedBy.localeCompare(b.roundedBy)
          );
        } else {
          mostRecentRoundCleanData.sort((a, b) =>
            b.roundedBy.localeCompare(a.roundedBy)
          );
        }
      } else if (sortBy === 'templateName') {
        if (sortOrder === 'asc') {
          mostRecentRoundCleanData.sort((a, b) =>
            a[sortBy].localeCompare(b[sortBy])
          );
        } else {
          mostRecentRoundCleanData.sort((a, b) =>
            b[sortBy].localeCompare(a[sortBy])
          );
        }
      } else if (sortBy === 'roundDate') {
        if (sortOrder === 'asc') {
          mostRecentRoundCleanData.sort((a, b) => a.date.localeCompare(b.date));
        } else {
          mostRecentRoundCleanData.sort((a, b) => b.date.localeCompare(a.date));
        }
      }

      const extrapolatedAnswersRoundsData = mostRecentRoundCleanData.map(
        round => {
          // This is mapping out the answers
          const answers =
            round.answers?.map(ans => {
              return {
                question: ans.question,
                answer:
                  ans?.answer && ans.answer.length > 0
                    ? ans?.answer[0].value
                    : ''
              };
            }) || [];

          const answersWithIndex = answers.map((obj, index) => {
            return {
              [`question${index + 1}`]: obj?.question?.replaceAll('"', ''),
              [`answer${index + 1}`]: obj?.answer
            };
          });
          const ansObject = answersWithIndex.reduce((acc, value) => {
            return {
              ...acc,
              ...value
            };
          }, {});

          return {
            ...round,
            type: round.templateName,
            ...ansObject
          };
        }
      );

      return yield put(setCsvToExport(extrapolatedAnswersRoundsData));
    }
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL });
  } catch (error) {
    console.log(error);
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL });
  }
}

export function* getEmployeeFreq(action) {
  const params = new URLSearchParams(action.data).toString();
  const url = `${API.getEmployeeFreqUrl}?${params}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      return yield put({
        type: ACTIONS.REPORT.GET_EMPLOYEE_FREQ_SUCCESS,
        data
      });
    }
    return yield put({
      type: ACTIONS.REPORT.GET_EMPLOYEE_FREQ_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.REPORT.GET_EMPLOYEE_FREQ_FAILURE
    });
  }
}

export function* getEmployeeFreqReport(action) {
  const { facilityId, query, sortBy, sortOrder, startday, endday } =
    action.data;

  const params = new URLSearchParams({
    facilityId,
    query,
    startday,
    endday
  });
  const url = `${API.getEmployeeRoundsReport}?${params}`;

  yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT });

  try {
    const { data, status } = yield call(getData, url, action.data);
    if (status === 200) {
      const collapsedFreqReportWithAnswersExtrapolated =
        data?.reduce((acc, round) => {
          const foundEmpIndex = acc.findIndex(
            r =>
              `${r?.employeeId}:${r?.department || 'Unknown'}` ===
              `${round?.employeeId}:${round?.employeeDept || 'Unknown'}`
          );
          const answers =
            round.answers?.map(ans => {
              return {
                question: ans.question,
                answer:
                  ans?.answer && ans.answer.length > 0
                    ? ans?.answer[0].value
                    : ''
              };
            }) || [];
          const answersWithIndex = answers.map((obj, index) => {
            return {
              [`question${index + 1}`]: obj?.question?.replaceAll('"', ''),
              [`answer${index + 1}`]: obj?.answer
            };
          });
          const ansObject = answersWithIndex.reduce((ans, value) => {
            return {
              ...ans,
              ...value
            };
          }, {});
          const newRound = {
            department: round?.employeeDept || 'Unknown',
            employee:
              `${round?.employeeFirstName} ${round?.employeeLastName}` || '',
            employeeId: round?.employeeId || '',
            employeeFirstName: round?.employeeFirstName || '',
            employeeLastName: round?.employeeLastName || '',
            roundedBy: `${round?.userFirstName} ${round?.userLastName}`,
            createdUserId: round?.userId || '',
            date: round?.roundDate || '',
            type: round?.templateName || '',
            ...ansObject
          };
          if (foundEmpIndex === -1) {
            return [
              ...acc,
              {
                department: round?.employeeDept || 'Unknown',
                employeeId: round?.employeeId || '',
                employee:
                  `${round?.employeeFirstName} ${round?.employeeLastName}` ||
                  '',
                employeeFirstName: round?.employeeFirstName || '',
                employeeLastName: round?.employeeLastName || '',
                count: 1,
                rounds: [newRound]
              }
            ];
          }
          acc[foundEmpIndex].count += 1;
          acc[foundEmpIndex].rounds.push(newRound);
          return [...acc];
        }, []) || [];

      // TODO: Front-end has to handle sorting until mobile no longer needs export endpoint
      if (sortBy === 'employeeDept') {
        if (sortOrder === 'asc') {
          collapsedFreqReportWithAnswersExtrapolated.sort((a, b) =>
            a.department.localeCompare(b.department)
          );
        } else {
          collapsedFreqReportWithAnswersExtrapolated.sort((a, b) =>
            b.department.localeCompare(a.department)
          );
        }
      } else if (sortBy === 'employeeName') {
        if (sortOrder === 'asc') {
          collapsedFreqReportWithAnswersExtrapolated.sort((a, b) =>
            a.employee.localeCompare(b.employee)
          );
        } else {
          collapsedFreqReportWithAnswersExtrapolated.sort((a, b) =>
            b.employee.localeCompare(a.employee)
          );
        }
      } else if (sortBy === 'total') {
        if (sortOrder === 'asc') {
          collapsedFreqReportWithAnswersExtrapolated.sort(
            (a, b) => a.count - b.count
          );
        } else {
          collapsedFreqReportWithAnswersExtrapolated.sort(
            (a, b) => b.count - a.count
          );
        }
      }

      return yield put(
        setCsvToExport(collapsedFreqReportWithAnswersExtrapolated)
      );
    }
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL });
  } catch (error) {
    console.log(error);
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({ type: ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL });
  }
}

export function* getComplianceReport(action) {
  const { facilityId, startday, endday } = action.data;

  const url = `${API.getComplianceReportUrl}?facilityId=${facilityId}&startday=${startday}&endday=${endday}`;
  try {
    const { data, status } = yield call(getData, url, action.data);
    if (status === 200) {
      return yield put({
        type: ACTIONS.REPORT.GET_COMPLIANCE_REPORT_SUCCESS,
        data
      });
    }
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({
      type: ACTIONS.REPORT.GET_COMPLIANCE_REPORT_FAILURE
    });
  } catch (error) {
    console.log(error);
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({
      type: ACTIONS.REPORT.GET_COMPLIANCE_REPORT_FAILURE
    });
  }
}

export function* getCensusReport(action) {
  const { facilityId, startday, endday } = action.data;

  const url = `${API.getCensusReport}?facilityId=${facilityId}&startday=${startday}&endday=${endday}`;
  try {
    const { data, status } = yield call(getData, url, action.data);

    if (status === 200) {
      return yield put({
        type: ACTIONS.REPORT.GET_CENSUS_REPORT_SUCCESS,
        data
      });
    }
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({
      type: ACTIONS.REPORT.GET_CENSUS_REPORT_FAILURE
    });
  } catch (error) {
    console.log(error);
    toast('Failed to retrieve report!', 'error', 1200, 350, true);
    return yield put({
      type: ACTIONS.REPORT.GET_CENSUS_REPORT_FAILURE
    });
  }
}

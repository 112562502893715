/**
 * External Imports
 */
import {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import { connect } from 'react-redux';

import { NeuButton, NeuIcon } from '@neutron/react';
/**
 * Internal Imports
 */
import TemplateTreeTable from '../customTables/templateTreeTable';
import SelectTemplateDropDown from '../searchHeader/selectTemplatesDropdown';
import Loading from '../shared/Loading';
import NewMasterTemplate from '../newMasterTemplate/NewMasterTemplate';

import store from '../../redux/store';
import { getAllQuestions } from '../../redux/actions/Template.action';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  RootState,
  Template,
  TreeNode
} from '../../config/interfaces';
/**
 * Style Imports
 */
import {
  TemplatesTreeContainer,
  HeaderItemsContainer,
  TreeContainer
} from './TemplatesTree.styles';

interface TemplatesTreeProps {
  authorizedUser: AuthUserProps;
  templatesTree: TreeNode[];
  roundingType: string;
  isTemplateTreeLoading: boolean;
  selectedTemplate: Template;
  templateActiveInactiveLoader: boolean;
  setEditTemplateFlag: Dispatch<SetStateAction<boolean>>;
}

const TemplatesTree: FC<TemplatesTreeProps> = ({
  authorizedUser,
  templatesTree,
  roundingType,
  isTemplateTreeLoading,
  selectedTemplate,
  templateActiveInactiveLoader,
  setEditTemplateFlag
}) => {
  const [facilityLevelData, setFacilityLevelData] = useState<TreeNode[]>([]);
  const [unitLevelData, setUnitLevelData] = useState<TreeNode[]>([]);
  const [rendered, setRendered] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState<string>('');
  const [selectedDivisionId, setSelectedDivisionId] = useState<string>('');
  const [selectedFacility, setSelectedFacility] = useState<string>('');
  const [selectedFacilityId, setSelectedFacilityId] = useState<string>('');
  const [showNewMasterTemplate, setShowNewMasterTemplate] =
    useState<boolean>(false);

  const divisionTableRef = useRef<null | HTMLDivElement>(null);
  const facilityTableRef = useRef<null | HTMLDivElement>(null);
  const unitTableRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    store.dispatch(getAllQuestions());
    setRendered(true);
  }, []);

  useEffect(() => {
    if (selectedDivision !== '') {
      templatesTree.forEach((item: TreeNode) => {
        if (item.data.levelId === selectedDivisionId) {
          // filter based on access
          const filteredFacilities =
            authorizedUser.access.accessLevel === 'facility'
              ? item.children.filter(
                  (child: TreeNode) =>
                    authorizedUser.access.accessLevelIds.indexOf(
                      child.data.levelId
                    ) !== -1
                )
              : item.children;
          setFacilityLevelData(filteredFacilities);
        }
      });
    } else {
      setFacilityLevelData([]);
    }
    if (selectedFacility !== '') {
      templatesTree.forEach((item: TreeNode) => {
        if (item.data.levelId === selectedDivisionId) {
          item.children.forEach((unit: TreeNode) => {
            if (unit.data.levelId === selectedFacilityId) {
              setUnitLevelData(unit.children);
            }
          });
        }
      });
    } else {
      setUnitLevelData([]);
    }
  }, [templatesTree]);

  const handleTableRowClick = (data: TreeNode, level: string) => {
    if (level === 'Division') {
      setSelectedDivision(data.data.name);
      setSelectedDivisionId(data.data.levelId);
      setFacilityLevelData(
        authorizedUser.access.accessLevel === 'facility'
          ? data.children.filter(
              (child: TreeNode) =>
                authorizedUser.access.accessLevelIds.indexOf(
                  child.data.levelId
                ) !== -1
            )
          : data.children
      );
      setUnitLevelData([]);
      facilityTableRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
    if (level === 'Facility') {
      setSelectedFacility(data.data.name);
      setSelectedFacilityId(data.data.levelId);
      setUnitLevelData(data.children);
      unitTableRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  const handleNewMasterTemplate = () => {
    setShowNewMasterTemplate(true);
    // appInsights.trackEvent({
    //   name: 'Master_Template_Started'
    // });
  };

  const handleEditTemplate = () => {
    setEditTemplateFlag(true);
    // appInsights.trackEvent({
    //   name: 'Master_Template_Edited'
    // });
  };

  return (
    <TemplatesTreeContainer>
      <HeaderItemsContainer>
        <SelectTemplateDropDown
          type="templateTree"
          roundingType={roundingType}
        />
        {authorizedUser.roles.includes('enterpriseAdmin') && (
          <>
            <NeuButton
              id="Edit-Button"
              class="mr-5"
              color="primary"
              fill="raised"
              onClick={handleEditTemplate}
            >
              <NeuIcon class="mr-2" feedback="default">
                edit
              </NeuIcon>
              Edit Master Template
            </NeuButton>
            <NeuButton
              id="Add-Button"
              class="mr-5"
              color="primary"
              fill="raised"
              style={{ marginLeft: 'auto' }}
              onClick={handleNewMasterTemplate}
            >
              <NeuIcon class="mr-2" feedback="default">
                add
              </NeuIcon>
              New Master Template
            </NeuButton>
          </>
        )}
      </HeaderItemsContainer>
      {showNewMasterTemplate ? (
        <NewMasterTemplate />
      ) : (
        <div style={{ overflowY: 'scroll' }}>
          {rendered &&
          !isTemplateTreeLoading &&
          Object.keys(selectedTemplate).length > 0 ? (
            <TreeContainer>
              <div ref={divisionTableRef}>
                <TemplateTreeTable
                  level="Division"
                  treeData={templatesTree}
                  handleTableRowClick={handleTableRowClick}
                  roundingType={selectedTemplate?.roundingType}
                  setEditTemplateFlag={setEditTemplateFlag}
                />
              </div>
              <div ref={facilityTableRef}>
                <TemplateTreeTable
                  level="Facility"
                  treeData={facilityLevelData}
                  handleTableRowClick={handleTableRowClick}
                  roundingType={selectedTemplate.roundingType}
                  setEditTemplateFlag={setEditTemplateFlag}
                />
              </div>
              <div ref={unitTableRef}>
                <TemplateTreeTable
                  level="Unit"
                  treeData={unitLevelData}
                  handleTableRowClick={handleTableRowClick}
                  roundingType={selectedTemplate.roundingType}
                  setEditTemplateFlag={setEditTemplateFlag}
                />
              </div>
            </TreeContainer>
          ) : (
            <Loading centered={false} />
          )}
        </div>
      )}
      {templateActiveInactiveLoader && !isTemplateTreeLoading && (
        <div
          style={{
            zIndex: 10,
            position: 'absolute',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        >
          <Loading centered={false} />
        </div>
      )}
    </TemplatesTreeContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  templatesTree: state.TemplateReducer.templateTree,
  isTemplateTreeLoading: state.TemplateReducer.isTemplateTreeLoading,
  templateActiveInactiveLoader:
    state.TemplateReducer.templateActiveInactiveLoader,
  selectedTemplate: state.TemplateReducer.selectedTemplate
});

export default connect(mapReduxStateToProps)(TemplatesTree);

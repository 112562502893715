import { FC, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import MyEmployeeListTable from '../../components/customTables/myEmployeeListTable/MyEmployeeListTable';
import MyDelegatesListTable from '../../components/customTables/myDelegatesTable/MyDelegatesTable';
import DelegatedToMeTable from '../../components/customTables/delegatedToMeTable';
import AccountSideNav from '../../components/sideNav/AccountSideNav';
import CSRNListSettingsTable from '../../components/customTables/csrnListSettingsTable';
import CNEdListSettingsTable from '../../components/customTables/cnedListSettingsTable';

import { ContentContainer } from './Account.styles';
import NoDataSplashView from '../../components/noDataSplashView/NoDataSplashView';

const EmployeeRoundingSettings: FC<{}> = () => {
  const { empRoundSettingsTable = 'employee-list' } = useParams();

  const currentlySelectedSettingsTable = useMemo(() => {
    switch (empRoundSettingsTable) {
      case 'employee-list':
        return <MyEmployeeListTable />;
      case 'delegate-list':
        return <MyDelegatesListTable />;
      case 'delegated-to-me':
        return <DelegatedToMeTable />;
      case 'csrn-list':
        return <CSRNListSettingsTable />;
      case 'cned-list':
        return <CNEdListSettingsTable />;
      default:
        return <NoDataSplashView type="" />;
    }
  }, [empRoundSettingsTable]);

  return (
    <ContentContainer id="Employee-Rounding-Settings">
      <AccountSideNav />
      {currentlySelectedSettingsTable}
    </ContentContainer>
  );
};

export default EmployeeRoundingSettings;

/**
 * External Imports
 */
import {
  FC,
  MouseEvent,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef
} from 'react';
import { connect, useDispatch } from 'react-redux';
import { format } from 'date-fns';

import {
  NeuInputChangeEventDetail,
  NeuRadioGroupChangeEventDetail
} from '@neutron/core';
import {
  NeuIcon,
  NeuButton,
  NeuCardTitle,
  NeuRadio,
  NeuOption,
  NeuRadioGroup,
  NeuDivider,
  NeuContainer,
  NeuAvatar,
  NeuLabel
} from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */
import { BlueChip } from '../../shared/chips/blueChip';
import { Dropdown } from '../../shared/dropdowns';
import Loading from '../../shared/Loading';
import SearchView from '../reusableModalPieces/searchView';
import StopSubHeader from '../reusableModalPieces/subHeader';
import { toast } from '../../../services/Toast';

import store from '../../../redux/store';
import {
  clearEmployeeSearch,
  searchEmployeesToShare
} from '../../../redux/actions/Employee.action';
import {
  clearModalState,
  getCategories,
  resetEmployeeSharedWith,
  saveModalState
} from '../../../redux/actions/Task.action';
import {
  openModal,
  setModalType,
  setModalAction,
  setModalData,
  closeModal
} from '../../../redux/actions/Modal.action';
import {
  deleteStoplight,
  postNewStoplight,
  updateStoplight,
  postCommentToStoplight,
  clearSelectedStoplight,
  setCurrentStoplightPage,
  addStoplightToSharingQ,
  clearStoplightResponse
} from '../../../redux/actions/Stoplight.action';
import { setToastType } from '../../../redux/actions/Toast.action';

import { handleModal } from '../../../services/ModalPortal/utils';
import { logAdHocClick, logTaskClick } from '../../../utils/analyticsHelpers';
import { useDebounceValue } from '../../../utils/debouncers';
import { date, toTitleCase, text } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import {
  AuthUserProps,
  Stoplight,
  IFacility,
  Unit,
  Task,
  Employee,
  RootState,
  Category,
  Issue,
  TaskPost
} from '../../../config/interfaces';
/**
 * Style Imports
 */
import { LabelContentBold } from '../feedbackModal/FeedbackModal.styles';
import {
  StoplightCard,
  CardHeader,
  ModalCardContent,
  InputHeading,
  RadioContainer,
  RadioLabel,
  ModalInput,
  ModalTextArea,
  DropDownSearchContainer,
  DropItem,
  ModalFooter,
  TitleSpan,
  TitleIcon,
  ChipWrapper,
  InProgressIcon,
  CompletedIcon,
  BlockedIcon,
  RadioLabelContainer,
  RadioLabelWithIcon,
  StyledEditHeaderCol,
  StyledRightAlignButton,
  StyledEditHeaderRow,
  StyledBoldHeading,
  StyledCircleDoneIcon,
  ViewIssuesClickableLabel
} from './StoplightModal.styles';
import { StyledBoldLabel } from '../../shared/table/Table.styles';

interface IStoplightModalProps {
  authorizedUser: AuthUserProps;
  searchedEmployeesToShare: [];
  units: Unit[];
  categories: [];
  modalAction: string;
  modalData: Stoplight;
  tempTaskObject?: Task;
  tempModalState?: {
    modal: string;
    modalType: string;
    modalAction: string;
    redirect: boolean;
  };
  employeeSharedWith?: [];
  isLoadingSharedWith?: boolean;
  loading?: boolean;
  globalUserSection: string;
  facilities: IFacility[];
  stoplightById: Stoplight;
}

type RequiredItemTypes = {
  [key: string]: string | [] | {};
};
const requiredItems: RequiredItemTypes = {
  status: '',
  category: '',
  details: ''
};

const StoplightModal: FC<IStoplightModalProps> = ({
  authorizedUser,
  searchedEmployeesToShare,
  units,
  categories,
  employeeSharedWith,
  isLoadingSharedWith,
  loading,
  globalUserSection,
  modalAction,
  modalData,
  facilities,
  stoplightById,
  tempTaskObject,
  tempModalState
}) => {
  const dispatch = useDispatch();
  const [userSection, setUserSection] = useState(globalUserSection);
  const [sharedSearchTerm, setSharedSearchTerm] = useState('');
  const [sharedPopState, setSharedPopState] = useState<boolean>(false);
  const [sharedSearchState, setSharedSearchState] = useState<boolean>(true);
  const [subCategories, setSubCategories] = useState<string[]>([]);

  const [sharedWithDisplay, setSharedWithDisplay] = useState<Array<string>>([]);
  const [attachIssueDisplay, setAttachIssueDisplay] = useState<Issue[]>([]);
  const [shareObject, setShareObject] = useState<{
    userName: string;
    email: string[];
  }>({ userName: '', email: [] });

  const [commentObj, setCommentObj] = useState<{
    id: string;
    comment: { body: string; authorId: string; authorFullName: string };
  }>({
    id: '',
    comment: { body: '', authorId: '', authorFullName: '' }
  });
  const [requiredFields, setRequiredFields] = useState(requiredItems);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [view, setView] = useState<'info' | 'search'>('info');
  const [openCat, setOpenCat] = useState(false);
  const [openSubcat, setOpenSubcat] = useState(false);
  const [openUnit, setOpenUnit] = useState(false);
  const modalContentRef = useRef<HTMLNeuCardElement>(null);

  // debouncing search terms
  const dbSharedSearchTerm = useDebounceValue(sharedSearchTerm, 250);

  const { logTrackingEvent } = useAnalyticsApi();

  const isAnimated = useMemo(
    () => (!tempModalState?.redirect ? 'show-modal' : ''),
    [tempModalState]
  );

  const filteredUnits = useMemo(
    () => units.filter(unit => unit.unit !== 'ADMIN'),
    [units]
  );

  const userFacility = useMemo(
    () =>
      facilities.filter(
        facility => facility.id === authorizedUser.facilityId
      )[0],
    [facilities, authorizedUser.facilityId]
  );

  const [stoplightObject, setStoplightObject] = useState<Stoplight>({
    locations: {
      division: [{ id: userFacility.division, displayName: '' }],
      facility: [{ id: userFacility.id, displayName: '' }],
      department:
        userSection === 'employee' && authorizedUser.departmentId
          ? [
              {
                id: authorizedUser.departmentId,
                displayName: authorizedUser.dept
              }
            ]
          : [],
      unit: userSection === 'patient' ? [{ id: '', displayName: '' }] : []
    },
    createdBy: `${authorizedUser.firstName} ${authorizedUser.lastName}`,
    authorId: authorizedUser.hcaid,
    status: 'In Progress',
    issueIds: [],
    created: date.now(),
    origin: tempTaskObject?.roundingType || userSection,
    category: '',
    subCategory: '',
    details: '',
    sharedWith: []
  });

  /** Shared with - search */
  useEffect(() => {
    if (sharedSearchTerm.length > 2) {
      store.dispatch(searchEmployeesToShare(dbSharedSearchTerm));
    }
  }, [dbSharedSearchTerm]);

  useEffect(() => {
    const selectedCat: Category = categories.filter((category: Category) => {
      return category.name === stoplightObject.category;
    })[0];
    if (selectedCat) setSubCategories(selectedCat.subCategories);
    else setSubCategories([]);
  }, [stoplightObject.category]);

  // watching for search results, then opening the respective dropdowns
  useEffect(() => {
    if (searchedEmployeesToShare.length > 0 && sharedSearchTerm.length > 0) {
      setSharedPopState(true);
    }
  }, [searchedEmployeesToShare]);

  useEffect(() => {
    const tempObject = {
      origin: text.capFirst(userSection),
      status: 'In Progress'
    };
    setStoplightObject({ ...stoplightObject, ...tempObject });
    if (userSection === 'patient') {
      setRequiredFields({
        ...requiredFields,
        ...tempObject,
        unit: ''
      });
    } else {
      setRequiredFields({ ...requiredFields, ...tempObject });
    }
    return () => {
      dispatch(clearSelectedStoplight());
      dispatch(resetEmployeeSharedWith());
    };
  }, []);

  useEffect(() => {
    if (
      (modalAction === 'EDIT' || modalAction === 'VIEW') &&
      Object.keys(stoplightById).length > 0
    ) {
      /**
       * In order to add sharedWith property to the feedback object if its not present in the
       * selected feedback for edit/partial edit.
       */
      const tempStoplight = stoplightById.sharedWith
        ? {
            ...stoplightById,
            details: stoplightObject.details || stoplightById.details
          }
        : { ...stoplightById, sharedWith: [] };
      setStoplightObject(tempStoplight);

      if (stoplightById.issues && stoplightById.issues.length > 0) {
        const displayIssues = stoplightById.issues.map(issue => ({
          ...issue,
          id: issue.taskId
        }));
        setAttachIssueDisplay(displayIssues);
      }

      /**
       * Validation block
       */
      const { status, category, details } = stoplightById;
      const { unit = [] } = stoplightById.locations;
      if (stoplightObject.origin === 'patient') {
        setRequiredFields({
          ...requiredFields,
          status,
          unit: unit[0]?.id,
          category,
          details: stoplightObject.details || details
        });
      } else {
        setRequiredFields({
          ...requiredFields,
          status,
          category,
          details: stoplightObject.details || details
        });
      }
    }
  }, [stoplightById]);

  useEffect(() => {
    if (employeeSharedWith && employeeSharedWith.length > 0) {
      employeeSharedWith.forEach((item: Employee) => {
        if (
          !sharedWithDisplay.includes(
            `${item.firstName} ${item.lastName} ${item.employeeId}`
          )
        ) {
          setSharedWithDisplay([
            ...sharedWithDisplay,
            `${item.firstName} ${item.lastName} ${item.employeeId}`
          ]);
        }
      });
    }
  }, [employeeSharedWith]);

  const setRequiredFieldValues = (stopObj: Stoplight) => {
    const { category, status, details, subCategory } = stopObj;
    const { unit } = stopObj.locations;
    if (stoplightObject.origin === 'patient') {
      if (subCategories.length > 0) {
        setRequiredFields({
          unit: unit[0] ? unit[0].id : [],
          category: category || tempTaskObject?.category || '',
          status,
          details,
          subCategory: subCategory || tempTaskObject?.subCategory || ''
        });
      } else {
        setRequiredFields({
          unit: unit[0] ? unit[0].id : [],
          category: category || tempTaskObject?.category || '',
          status,
          details
        });
      }
    } else if (subCategories.length > 0) {
      setRequiredFields({
        category: category || tempTaskObject?.category || '',
        status,
        details,
        subCategory: subCategory || tempTaskObject?.subCategory || ''
      });
    } else {
      setRequiredFields({
        category: category || tempTaskObject?.category || '',
        status,
        details
      });
    }
    setStoplightObject({
      ...stoplightObject,
      locations: {
        ...stoplightObject.locations,
        unit: unit[0]
          ? [{ id: unit[0].id, displayName: unit[0].displayName }]
          : []
      },
      category: category || tempTaskObject?.category || '',
      subCategory: subCategory || tempTaskObject?.subCategory || ''
    });
  };
  // watching for origin change
  useEffect(() => {
    if (userSection) {
      const categoryData = {
        CategoryFor: userSection,
        CategoryType: 'stoplight' // TODO to test change to issues
      };
      dispatch(getCategories(categoryData));
    }
    setRequiredFieldValues(stoplightObject);
  }, [userSection]);

  const changeIssueOrigin = (
    event: CustomEvent<NeuRadioGroupChangeEventDetail>
  ) => {
    const newOrigin = event.detail.value;
    setStoplightObject({
      ...stoplightObject,
      origin: newOrigin,
      locations: {
        division: [{ id: userFacility.division, displayName: '' }],
        facility: [{ id: userFacility.id, displayName: '' }],
        department:
          newOrigin === 'employee' && authorizedUser.departmentId
            ? [
                {
                  id: authorizedUser.departmentId,
                  displayName: authorizedUser.dept
                }
              ]
            : [],
        unit: newOrigin === 'patient' ? [{ id: '', displayName: '' }] : []
      }
    });
    setUserSection(newOrigin);
  };

  /**
   * Validate fields on change and disable button
   */
  useEffect(() => {
    let count = 0;
    Object.keys(requiredFields).forEach(item => {
      const tempVar = requiredFields[item];
      if (tempVar === '' || (Array.isArray(tempVar) && tempVar.length === 0)) {
        count += 1;
      }
      if (modalAction === 'VIEW') {
        setShowButton(true);
      } else if (count === 0) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, [requiredFields]);

  useEffect(() => {
    setRequiredFieldValues(stoplightObject);
  }, [subCategories]);

  const handleSearching = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const target = e.target as HTMLInputElement;
    setSharedSearchState(true);
    setSharedSearchTerm(target.value);
  };

  const setEmployeeToShare = (emp: Employee) => {
    const empId = emp.employeeId.toUpperCase();
    const empExists = stoplightObject.sharedWith.includes(empId.toLowerCase());
    if (!empExists) {
      setStoplightObject({
        ...stoplightObject,
        sharedWith: stoplightObject.sharedWith
          ? [...stoplightObject.sharedWith, `${emp.employeeId}`]
          : [`${emp.employeeId}`]
      });
      setSharedWithDisplay([
        ...sharedWithDisplay,
        `${emp.firstName} ${emp.lastName} ${empId}`
      ]);
      setShareObject({
        email: [...shareObject.email, emp.email],
        userName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
      });
    } else {
      // TODO future development add a toast here saying 'user already selected'
      console.log('User already selected');
    }
    setSharedSearchState(false);
    setSharedSearchTerm('');
    setSharedPopState(false);
    dispatch(clearEmployeeSearch());
  };

  const handleClose = () => {
    setStoplightObject({
      locations: {
        division: [],
        facility: [],
        department: [],
        unit: []
      },
      createdBy: '',
      authorId: '',
      status: '',
      issueIds: [],
      created: '',
      origin: '',
      category: '',
      subCategory: '',
      details: '',
      sharedWith: []
    });
    setShareObject({ userName: '', email: [] });
    setSharedWithDisplay([]);
    dispatch(clearEmployeeSearch());
    dispatch(resetEmployeeSharedWith());
    dispatch(clearSelectedStoplight());
    dispatch(clearModalState());
    if (!tempTaskObject?.taskId) {
      dispatch(clearStoplightResponse());
      handleModal(modalContentRef);
    } else {
      dispatch(closeModal());
    }
    if (
      tempTaskObject &&
      tempTaskObject.taskId &&
      tempModalState &&
      tempModalState.modal
    ) {
      dispatch(setModalAction(tempModalState.modalAction));
      dispatch(setModalType(tempModalState.modalType));
      dispatch(setModalData(tempTaskObject));
      dispatch(saveModalState({ redirect: true }));
      dispatch(openModal());
    }
  };

  const handleDelete = () => {
    dispatch(setToastType({ type: 'Delete Stoplight' }));
    toast(
      stoplightObject.issues && stoplightObject.issues.length > 0
        ? `The stoplight you are about to delete has ${
            stoplightObject.issues?.length
          } ${
            stoplightObject.issues?.length === 1 ? 'issue' : 'issues'
          } attached!`
        : 'Are you sure you want to delete this stoplight?',
      'info',
      null,
      500,
      true,
      stoplightObject.issues && stoplightObject.issues.length > 0
        ? `This won't delete the issues, but they will no longer be attached to the stoplight.`
        : '',
      () => {
        if (stoplightById.id) dispatch(deleteStoplight(stoplightById.id));
        handleClose();
      }
    );
  };

  const handleRemoveSharedWith = (emp: string) => {
    const removedEmpName = emp.split(' ').slice(0, 2).join('.');
    const removedEmpId: string = emp?.split(/[\s+]+/).pop() || '';
    // Update sharedWith in task object
    const newSharedWith = stoplightObject.sharedWith.filter((item: string) => {
      return item.includes(removedEmpId.toLowerCase()) === false;
    });
    setStoplightObject({
      ...stoplightObject,
      sharedWith: newSharedWith
    });

    // update Emails for sharing
    const emailsAfterRemove: string[] = shareObject.email.filter(
      (email: string) => {
        const existingString = email.split('@')[0];
        return existingString !== removedEmpName;
      }
    );
    setShareObject({ ...shareObject, email: emailsAfterRemove });
  };

  const handleInputs = (e: CustomEvent<NeuRadioGroupChangeEventDetail>) => {
    const target = e.target as HTMLNeuInputElement;
    const { name } = target;
    const value = target.value as string;
    if (name === 'unit') {
      setStoplightObject({
        ...stoplightObject,
        locations: {
          ...stoplightObject.locations,
          unit: [{ id: value, displayName: '' }]
        }
      });
    } else {
      setStoplightObject({ ...stoplightObject, [name]: value });
    }
    // For validation
    if (Object.keys(requiredFields).includes(name)) {
      setRequiredFields({ ...requiredFields, [name]: value });
    }
  };

  const handleDropdownChange = useCallback(
    (value: string, name: string) => {
      if (name === 'unit') {
        setStoplightObject({
          ...stoplightObject,
          locations: {
            ...stoplightObject.locations,
            unit: [{ id: value, displayName: '' }]
          }
        });
      } else if (name === 'category') {
        setStoplightObject({
          ...stoplightObject,
          [name]: value,
          subCategory: ''
        });
      } else {
        setStoplightObject({ ...stoplightObject, [name]: value });
      }
      // For validation
      if (Object.keys(requiredFields).includes(name)) {
        setRequiredFields({ ...requiredFields, [name]: value });
      }
    },
    [requiredFields, stoplightObject]
  );

  const handlePost = () => {
    if (modalAction === 'UNIVERSAL-ADD') {
      logTrackingEvent(logAdHocClick('Add Stoplight', 'save'));
    } else {
      logTrackingEvent(logTaskClick('stoplight', 'save'));
    }
    if (stoplightObject.status === 'Completed') {
      dispatch(setToastType({ type: 'Complete' }));
      toast(
        stoplightObject.issues && stoplightObject.issues.length > 0
          ? `The stoplight you are about to complete has ${stoplightObject.issues.length} attached.`
          : 'Are you sure you would like to complete this stoplight?',
        'info',
        null,
        400,
        true,
        stoplightObject.issues && stoplightObject.issues.length > 0
          ? `Completing this stoplight will result in all attached issues being closed.`
          : null,
        () => {
          if (modalAction === 'EDIT' || modalAction === 'VIEW') {
            dispatch(addStoplightToSharingQ(shareObject));
            dispatch(updateStoplight(stoplightObject));
          } else if (showButton) {
            dispatch(addStoplightToSharingQ(shareObject));
            dispatch(postNewStoplight(stoplightObject));
          }
          setTimeout(() => {
            handleClose();
          }, 500);
        }
      );
    } else {
      if (modalAction === 'EDIT' || modalAction === 'VIEW') {
        dispatch(addStoplightToSharingQ(shareObject));
        dispatch(updateStoplight(stoplightObject));
      } else if (showButton) {
        dispatch(addStoplightToSharingQ(shareObject));
        dispatch(postNewStoplight(stoplightObject));
      }
      setTimeout(() => {
        handleClose();
      }, 500);
    }
  };

  const handleCommentText = (e: CustomEvent<KeyboardEvent>) => {
    const target = e.target as HTMLInputElement;
    const value = target.value as string;
    if (stoplightById.id)
      setCommentObj({
        id: stoplightById.id,
        comment: {
          body: value,
          authorId: authorizedUser.hcaid,
          authorFullName: `${authorizedUser.firstName} ${authorizedUser.lastName}`
        }
      });
  };

  const handleCommentPost = useCallback(() => {
    if (commentObj) {
      dispatch(postCommentToStoplight(commentObj));
      setCommentObj({
        id: '',
        comment: { body: '', authorId: '', authorFullName: '' }
      });
      dispatch(setCurrentStoplightPage(1));
    }
  }, [commentObj]);

  // removing issues from stoplight
  const handleRemoveAttachIssue = useCallback(
    (event: MouseEvent<HTMLNeuIconElement>, iss: Issue) => {
      event.stopPropagation();
      const removedIssue = iss;
      // Update issues in stoplight object
      const remainingIssues = stoplightObject.issueIds.filter(
        (item: string) => {
          return item !== removedIssue.id;
        }
      );
      dispatch(setToastType({ type: 'Delete Issue' }));
      if (stoplightById.issueIds?.includes(iss.id)) {
        toast(
          `Are you sure you would like to un-attach issue ${removedIssue.friendlyId} ?`,
          'info',
          null,
          400,
          true,
          null,
          () => {
            setStoplightObject({
              ...stoplightObject,
              issueIds: remainingIssues
            });
            setAttachIssueDisplay(
              attachIssueDisplay.filter(
                (issue: Issue) => issue.id !== removedIssue.id
              )
            );
          }
        );
      } else {
        setStoplightObject({
          ...stoplightObject,
          issueIds: remainingIssues
        });
        setAttachIssueDisplay(
          attachIssueDisplay.filter(
            (issue: Issue) => issue.id !== removedIssue.id
          )
        );
      }
    },
    [attachIssueDisplay, stoplightById, stoplightObject]
  );

  // adding issues to stoplight
  const setIssuesToAttach = useCallback(
    (issue: Issue) => {
      const issueExists = attachIssueDisplay.some(
        (item: Issue) => item.id === issue.id
      );
      // In order to check whether the immediately removed one can be added again
      const issueExistsInTaskObj = stoplightObject.issueIds?.includes(issue.id);
      if (!issueExists && (!issue.isAttached || issueExistsInTaskObj)) {
        setStoplightObject({
          ...stoplightObject,
          issueIds: stoplightObject.issueIds
            ? [...stoplightObject.issueIds, `${issue.id}`]
            : [issue.id]
        });
        setAttachIssueDisplay([
          ...attachIssueDisplay,
          { ...issue, taskSubtype: issue.taskSubtype || issue.historicCategory }
        ]);
      }
    },
    [attachIssueDisplay, stoplightObject]
  );

  return (
    <StoplightCard
      className={isAnimated}
      id="Stoplight-Modal"
      type="submit"
      height="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <NeuCardTitle>
          <TitleSpan id="Stoplight-Modal-Title">
            <TitleIcon large>traffic</TitleIcon>
            {modalAction === 'UNIVERSAL-ADD' ? 'New Stoplight' : ''}
            {modalAction === 'VIEW' || modalAction === 'EDIT'
              ? `${toTitleCase(stoplightObject.origin)} | Stoplight`
              : ''}
            {modalAction !== 'EDIT' &&
            modalAction !== 'UNIVERSAL-ADD' &&
            modalAction !== 'VIEW'
              ? `New ${toTitleCase(stoplightObject.origin)} Stoplight`
              : ''}
          </TitleSpan>
        </NeuCardTitle>
        <NeuButton
          id="Stoplight-Modal-Close-Button"
          fill="flat"
          onClick={() => {
            if (modalAction === 'UNIVERSAL-ADD') {
              logTrackingEvent(logAdHocClick('Add Stoplight', 'close'));
            } else {
              logTrackingEvent(logTaskClick('stoplight', 'close'));
            }
            handleClose();
          }}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <StopSubHeader
        completed={stoplightObject.status === 'Completed'}
        items={stoplightObject.issueIds}
        type="stoplight"
        view={view}
        setView={setView}
      />
      {(modalAction !== 'EDIT' ||
        (view === 'info' && modalAction === 'EDIT')) &&
        view !== 'search' &&
        (loading || isLoadingSharedWith ? (
          <ModalCardContent id="Stoplight-Modal-Loading-Card">
            <Loading />
          </ModalCardContent>
        ) : (
          <ModalCardContent className="mb-4" id="Stoplight-Modal-Card">
            {modalAction === 'UNIVERSAL-ADD' &&
              !(stoplightObject.origin && tempTaskObject?.roundingType) && (
                <NeuRadioGroup
                  onNeuChange={changeIssueOrigin}
                  name="origin"
                  value={stoplightObject.origin ? stoplightObject.origin : ''}
                >
                  <InputHeading>Stoplight Origin</InputHeading>
                  <RadioContainer id="Stoplight-Modal-Type-Radio-Buttons">
                    <NeuRadio value="patient">Patient</NeuRadio>
                    <RadioLabel>Patient</RadioLabel>
                    <NeuRadio value="employee">Employee</NeuRadio>
                    <RadioLabel>Employee</RadioLabel>
                  </RadioContainer>
                </NeuRadioGroup>
              )}

            <NeuRadioGroup
              id="Stoplight-Modal-Status-Radio-Buttons"
              onNeuChange={handleInputs}
              name="status"
              value={stoplightObject.status ? stoplightObject.status : ''}
            >
              {(modalAction === 'EDIT' || modalAction === 'VIEW') && (
                <>
                  <InputHeading>Stoplight ID</InputHeading>
                  <LabelContentBold>
                    {stoplightById.friendlyId}
                  </LabelContentBold>
                  <InputHeading>Creator</InputHeading>
                  <LabelContentBold className="my-1">
                    {stoplightObject.createdBy}
                  </LabelContentBold>
                </>
              )}

              <InputHeading>Status</InputHeading>
              {stoplightById.status !== 'Completed' ? (
                <RadioContainer style={{ justifyContent: 'space-between' }}>
                  <RadioLabelContainer>
                    <NeuRadio
                      name="status"
                      value="Completed"
                      disabled={
                        !(modalAction === 'EDIT' || modalAction === 'VIEW')
                          ? true
                          : undefined
                      }
                    >
                      Completed
                    </NeuRadio>
                    <RadioLabelWithIcon>
                      <CompletedIcon>traffic</CompletedIcon>Completed
                    </RadioLabelWithIcon>
                  </RadioLabelContainer>
                  <RadioLabelContainer>
                    <NeuRadio name="status" value="In Progress">
                      In Progress
                    </NeuRadio>
                    <RadioLabelWithIcon>
                      <InProgressIcon>traffic</InProgressIcon> In Progress
                    </RadioLabelWithIcon>
                  </RadioLabelContainer>
                  <RadioLabelContainer>
                    <NeuRadio name="status" value="Deferred">
                      Deferred
                    </NeuRadio>
                    <RadioLabelWithIcon>
                      <BlockedIcon>traffic</BlockedIcon>Deferred
                    </RadioLabelWithIcon>
                  </RadioLabelContainer>
                </RadioContainer>
              ) : (
                <LabelContentBold>{stoplightById.status}</LabelContentBold>
              )}
            </NeuRadioGroup>
            {(modalAction === 'VIEW' ||
              stoplightById.status === 'Completed') && (
              <>
                {stoplightById.origin === 'patient' && (
                  <>
                    <InputHeading>Unit</InputHeading>
                    <LabelContentBold>
                      {stoplightById.locations.unit[0]?.displayName ||
                        'Unknown'}
                    </LabelContentBold>
                  </>
                )}

                <InputHeading>Stoplight Category</InputHeading>
                <LabelContentBold>{stoplightById.category}</LabelContentBold>
                {stoplightById.subCategory && (
                  <>
                    <InputHeading>Stoplight Subcategory</InputHeading>
                    <LabelContentBold>
                      {stoplightById.subCategory
                        ? stoplightById.subCategory
                        : 'Unknown'}
                    </LabelContentBold>
                  </>
                )}
                <InputHeading>Details</InputHeading>
                <LabelContentBold>{stoplightById.details}</LabelContentBold>
              </>
            )}

            {stoplightObject.origin === 'patient' &&
              modalAction !== 'VIEW' &&
              stoplightById.status !== 'Completed' && (
                <>
                  <InputHeading>Unit</InputHeading>
                  <Dropdown
                    id="Stoplight-Modal-Unit-Select"
                    classes="pl-0 pr-2"
                    name="unit"
                    open={openUnit}
                    placeholder={
                      units.find(
                        unit =>
                          unit.unitId === stoplightObject.locations.unit[0]?.id
                      )?.unit || 'Select a Unit'
                    }
                    width="97%"
                    setOpen={setOpenUnit}
                  >
                    {filteredUnits &&
                      filteredUnits.map((unit: Unit) => (
                        <NeuOption
                          className={`dropdown-hover-item${
                            unit.unitId ===
                            stoplightObject.locations.unit[0]?.id
                              ? ' selected'
                              : ''
                          }`}
                          value={unit.unitId}
                          key={unit.unitId}
                          onClick={() =>
                            handleDropdownChange(unit.unitId, 'unit')
                          }
                        >
                          {unit.unit}
                        </NeuOption>
                      ))}
                  </Dropdown>
                </>
              )}
            {modalAction !== 'VIEW' && stoplightById.status !== 'Completed' && (
              <>
                <InputHeading>Stoplight Category</InputHeading>
                <Dropdown
                  id="Stoplight-Modal-Category-Select"
                  classes="pl-0 pr-2"
                  name="category"
                  open={openCat}
                  placeholder={
                    stoplightObject.category
                      ? stoplightObject.category
                      : 'Select a Category'
                  }
                  width="97%"
                  setOpen={setOpenCat}
                >
                  {categories.map((category: Category) => (
                    <NeuOption
                      key={category.name}
                      className={`dropdown-hover-item${
                        category.name === stoplightObject.category
                          ? ' selected'
                          : ''
                      }`}
                      value={category.name}
                      onClick={() =>
                        handleDropdownChange(category.name, 'category')
                      }
                    >
                      {category.name}
                    </NeuOption>
                  ))}
                </Dropdown>
                <InputHeading>Stoplight Subcategory</InputHeading>
                <Dropdown
                  id="Stoplight-Modal-SubCategory-Select"
                  classes="pl-0 pr-2"
                  disabled={
                    !stoplightObject.category ||
                    (categories.length > 0 && subCategories.length < 1)
                  }
                  name="subCategory"
                  open={openSubcat}
                  placeholder={
                    stoplightObject.subCategory
                      ? stoplightObject.subCategory
                      : stoplightObject.category && subCategories.length === 0
                      ? 'No subcategories'
                      : 'Select a Subcategory'
                  }
                  width="97%"
                  setOpen={setOpenSubcat}
                >
                  {subCategories.map((subcat: string) => (
                    <NeuOption
                      key={subcat}
                      className={`dropdown-hover-item${
                        subcat === stoplightObject.subCategory
                          ? ' selected'
                          : ''
                      }`}
                      value={subcat}
                      onClick={() =>
                        handleDropdownChange(subcat, 'subCategory')
                      }
                    >
                      {subcat}
                    </NeuOption>
                  ))}
                </Dropdown>
                {(stoplightObject.category === 'ITG' ||
                  stoplightObject.category.includes('Maintenance/Access') ||
                  stoplightObject.category === 'GE Biomed' ||
                  stoplightObject.category.includes(
                    'Building Maintenance'
                  )) && (
                  <StyledBoldLabel>
                    Create an issue in addition to this Stoplight if a fix agent
                    is required.
                  </StyledBoldLabel>
                )}
                <InputHeading>Details</InputHeading>
                <ModalTextArea
                  id="Stoplight-Modal-Details-Text-Area"
                  placeholder="Do not include PHI"
                  wrap="hard"
                  inputmode="text"
                  enterkeyhint="next"
                  name="details"
                  required
                  spellcheck
                  value={stoplightObject.details ? stoplightObject.details : ''}
                  onNeuChange={handleInputs}
                />
              </>
            )}

            <InputHeading>
              {stoplightById.status !== 'Completed'
                ? 'Share With (Optional)'
                : 'Shared With'}
            </InputHeading>
            <div
              className="mb-5"
              id="Stoplight-Modal-Share-With"
              onBlur={() => setSharedPopState(false)}
            >
              {sharedWithDisplay.length > 0 &&
                sharedWithDisplay.map(emp => {
                  return (
                    <ChipWrapper
                      key={emp}
                      onClick={() => handleRemoveSharedWith(emp)}
                    >
                      <BlueChip key={emp} name={emp} />
                    </ChipWrapper>
                  );
                })}
              <div style={{ position: 'relative' }}>
                {stoplightById.status !== 'Completed' && (
                  <ModalInput
                    id="Stoplight-Modal-Share-With-Input"
                    type="search"
                    name="sharedWith"
                    autocomplete="off"
                    placeholder="Start typing name or 3-4 ID"
                    inputmode="search"
                    enterkeyhint="search"
                    autocorrect="off"
                    value={sharedSearchState ? sharedSearchTerm : null}
                    onNeuChange={sharedSearchState ? handleSearching : () => {}}
                    onNeuFocus={() => setSharedSearchState(true)}
                  />
                )}
                <DropDownSearchContainer
                  id="Stoplight-Modal-Employee-Dropdown"
                  showPopover={sharedPopState}
                >
                  {searchedEmployeesToShare.map((emp: Employee) => (
                    <div key={emp.employeeId}>
                      <DropItem
                        onMouseDown={() => setEmployeeToShare(emp)}
                        button
                      >
                        <p>
                          {emp.firstName.toUpperCase()}{' '}
                          {emp.lastName.toUpperCase()}
                        </p>
                        <p style={{ fontSize: '14px' }}>{emp.employeeId}</p>
                        <p style={{ fontSize: '14px' }}>{emp.title}</p>
                      </DropItem>
                      {searchedEmployeesToShare.length > 1 && <NeuDivider />}
                    </div>
                  ))}
                </DropDownSearchContainer>
              </div>
            </div>

            <ViewIssuesClickableLabel onClick={() => setView('search')}>
              {stoplightObject.issueIds?.length > 0 ||
              (stoplightObject.status === 'Completed' &&
                stoplightObject.issueIds?.length === 0)
                ? 'Attached Issues'
                : 'Attach an Issue'}
            </ViewIssuesClickableLabel>

            {/* ***************COMMENTS**************** */}
            {(modalAction === 'EDIT' || modalAction === 'VIEW') && (
              <>
                <NeuDivider
                  className="my-5"
                  color="gray-30"
                  style={{ width: '98%' }}
                />
                {stoplightById.discussion &&
                  stoplightById.discussion.comments.map((comment, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <StyledEditHeaderRow key={comment.date + i}>
                      <NeuContainer className="px-1">
                        <NeuAvatar
                          color="primary"
                          initials={text.getInitials(comment.authorFullName)}
                        />
                      </NeuContainer>
                      <NeuContainer>
                        <StyledEditHeaderRow className="pl-0">
                          <StyledBoldHeading
                            className="mt-0"
                            color="primary"
                            autoWidth
                          >
                            {comment.authorFullName}
                          </StyledBoldHeading>
                          <InputHeading
                            className="mt-0 ml-2"
                            autoWidth
                            style={{ fontSize: '0.75rem' }}
                          >
                            {format(
                              date.parseStopDate(comment.date),
                              'MM/dd/yyyy'
                            )}
                          </InputHeading>
                        </StyledEditHeaderRow>
                        <StyledEditHeaderRow className="pl-0">
                          <InputHeading className="mt-0">
                            {comment.body}
                          </InputHeading>
                        </StyledEditHeaderRow>
                      </NeuContainer>
                    </StyledEditHeaderRow>
                  ))}

                {/* **************COMMENT TEXT AREA*************** */}
                {stoplightById.status !== 'Completed' && (
                  <>
                    <InputHeading className="mx-3">Add Comment</InputHeading>
                    <StyledEditHeaderCol className="ml-0 mr-2">
                      <ModalTextArea
                        className="mr-4 mb-2"
                        placeholder="Do not include PHI"
                        wrap="hard"
                        inputmode="text"
                        enterkeyhint="next"
                        name="comment"
                        required
                        spellcheck
                        value={commentObj.comment.body}
                        onNeuInput={handleCommentText}
                      />
                      <StyledRightAlignButton
                        className="mr-4"
                        color="primary"
                        onClick={handleCommentPost}
                        size="small"
                      >
                        Add Comment
                      </StyledRightAlignButton>
                    </StyledEditHeaderCol>
                  </>
                )}
              </>
            )}
          </ModalCardContent>
        ))}
      {modalData && view === 'search' && (
        // Issue info
        <ModalCardContent id="Stoplight-Modal-Issues-Card" className="pb-4">
          <SearchView
            attachedDisplay={attachIssueDisplay as (Issue & Stoplight)[]}
            attachToItem={stoplightObject as TaskPost & Stoplight}
            attachToItemCompleted={stoplightObject.status === 'Completed'}
            prevAttached={stoplightById.issueIds || []}
            type="issue"
            handleRemoveAttached={handleRemoveAttachIssue}
            setItemsToAttach={setIssuesToAttach}
          />
        </ModalCardContent>
      )}
      {!loading && (
        <ModalFooter
          className={
            stoplightById && stoplightById.authorId === authorizedUser.hcaid
              ? 'justify-content-between'
              : ''
          }
          small
        >
          {stoplightById.status === 'Completed' ? (
            <NeuContainer className="flex-row align-items-center justify-content-start">
              <NeuContainer className="px-1 w-auto">
                <StyledCircleDoneIcon color="plain-0">
                  done
                </StyledCircleDoneIcon>
              </NeuContainer>
              <NeuContainer>
                <NeuContainer className="flex-row pl-0 align-items-center">
                  <NeuLabel className="mt-0 bold">Completed</NeuLabel>
                </NeuContainer>
                {stoplightById.completedDate && (
                  <NeuContainer className="flex-row pl-0 align-items-start">
                    <NeuLabel
                      className="mt-0 w-auto custom-modal-heading"
                      style={{ fontSize: '0.80rem' }}
                    >
                      {date.dayOfYr(stoplightById.completedDate)} @{' '}
                      {date.localTimeOfDay(stoplightById.completedDate)}
                    </NeuLabel>
                  </NeuContainer>
                )}
              </NeuContainer>
            </NeuContainer>
          ) : (
            <>
              {modalAction === 'EDIT' &&
                stoplightObject &&
                stoplightObject.authorId === authorizedUser.hcaid && (
                  <NeuButton
                    id="Stoplight-Modal-Delete-Button"
                    color="danger"
                    onClick={handleDelete}
                  >
                    Delete
                  </NeuButton>
                )}
              <NeuButton
                id="Stoplight-Modal-Save-Button"
                color="primary"
                onClick={handlePost}
                style={{ float: 'right' }}
                disabled={!showButton ? true : undefined}
              >
                Save Stoplight
              </NeuButton>
            </>
          )}
        </ModalFooter>
      )}
    </StoplightCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal,
    authorizedUser: state.AuthorizedUser.authorizedUser,
    searchedEmployeesToShare: state.EmployeeReducer.searchedEmpsToShare,
    units: state.ConfigReducer.units,
    categories: state.TaskReducer.categories,
    employeeSharedWith: state.TaskReducer.employeeSharedWith,
    modalAction: state.ModalReducer.modalAction,
    modalData: state.ModalReducer.modalData,
    isLoadingSharedWith: state.TaskReducer.isLoadingSharedWith,
    loading: state.StoplightReducer.loadingStoplight,
    globalUserSection: state.UserReducer.userSection,
    facilities: state.UserReducer.facilities,
    stoplightById: state.StoplightReducer.stoplightById,
    tempTaskObject: state.TaskReducer.tempTaskObject,
    tempModalState: state.TaskReducer.tempModalState
  };
};

export default connect(mapReduxStateToProps)(StoplightModal);

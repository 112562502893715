/**
 * External Imports
 */
import { FC, useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { NeuTab, NeuIcon } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

/**
 * Internal Imports
 */

import { logTableStart } from '../../../utils/analyticsHelpers';
import { setLastPath } from '../../../utils/helpers';

/**
 * Global Type Definition Imports
 */
import { RootState } from '../../../config/interfaces';

/**
 * Styling Imports
 */
import {
  PopItem,
  IconPositioning,
  InlineRelativeContainer,
  PopContent,
  SupportDropDownContainer
} from '../../Header/mainNav/MainNav.styles';

interface SupportDropdownProps {
  authUserRoles: Array<string>;
}

const SupportDropdown: FC<SupportDropdownProps> = ({ authUserRoles }) => {
  const [supportDropdownState, setSupportDropdownState] = useState(false);
  const [, setShowPopover] = useState(false);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { logTrackingEvent } = useAnalyticsApi();

  return (
    <>
      {authUserRoles &&
        ((authUserRoles.includes('csrnRounder') &&
          authUserRoles.includes('cnedRounder')) ||
          authUserRoles.includes('enterpriseAdmin')) && (
          <>
            <NeuTab
              id="Supports-Tab"
              onBlur={() => setSupportDropdownState(false)}
              navy
              active={pathname.includes('support')}
              onClick={() => setSupportDropdownState(!supportDropdownState)}
            >
              <IconPositioning>
                Support
                <NeuIcon small>arrow_drop_down</NeuIcon>
              </IconPositioning>
            </NeuTab>
            <InlineRelativeContainer onBlur={() => setShowPopover(false)}>
              <SupportDropDownContainer
                showReportDropdown={supportDropdownState}
              >
                {(authUserRoles?.includes('csrnRounder') ||
                  authUserRoles?.includes('enterpriseAdmin')) && (
                  <PopItem
                    button
                    onMouseDown={() => {
                      // store.dispatch(clearSelectedEmployee());
                      setLastPath('/support/csrn-list');
                      navigate('/support/csrn-list');
                      logTrackingEvent(logTableStart('/support/csrn-list'));
                    }}
                  >
                    <PopContent>CSRN Rounding</PopContent>
                  </PopItem>
                )}
                {(authUserRoles?.includes('cnedRounder') ||
                  authUserRoles?.includes('enterpriseAdmin')) && (
                  <PopItem
                    button
                    onMouseDown={() => {
                      // store.dispatch(clearSelectedEmployee());
                      setLastPath('/support/cned-list');
                      navigate('/support/cned-list');
                      logTrackingEvent(logTableStart('/support/cned-list'));
                    }}
                  >
                    <PopContent>CNEd Rounding</PopContent>
                  </PopItem>
                )}
              </SupportDropDownContainer>
            </InlineRelativeContainer>
          </>
        )}
      {authUserRoles &&
        ((authUserRoles.length === 1 &&
          authUserRoles.includes('csrnRounder')) ||
          (authUserRoles.length > 1 &&
            !authUserRoles.includes('cnedRounder'))) && (
          <Link
            onClick={() => {
              setLastPath('/support/csrn-list');
            }}
            key="CSRN-Tab"
            id="CSRN-Tab"
            to="/support/csrn-list"
          >
            <NeuTab active={pathname.includes('support')} navy>
              CSRN Rounding
            </NeuTab>
          </Link>
        )}
      {authUserRoles &&
        ((authUserRoles.length === 1 &&
          authUserRoles.includes('cnedRounder')) ||
          (authUserRoles.length > 1 &&
            !authUserRoles.includes('csrnRounder'))) && (
          <Link
            onClick={() => {
              setLastPath('/support/cned-list');
            }}
            key="CNED-Tab"
            id="CNED-Tab"
            to="/support/cned-list"
          >
            <NeuTab active={pathname.includes('support')} navy>
              CNEd Rounding
            </NeuTab>
          </Link>
        )}
    </>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authUserRoles: state.AuthorizedUser.authorizedUser?.roles
  };
};

export default connect(mapReduxStateToProps)(SupportDropdown);

import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { format, isFirstDayOfMonth, sub } from 'date-fns';

import { CReportContainer } from '../styles/ReportsViews.styles';
import { setCsvToExport } from '../../../redux/actions/Admin.action';
import { getComplianceReport } from '../../../redux/actions/Report.action';
import ComplianceHeader from '../../../components/searchHeader/complianceHeader/ComplianceHeader';
import ComplianceReportTable from '../../../components/customTables/complianceReportTable';
import {
  calculateTotalCompliance,
  reduceComplianceByUnit
} from '../../../utils/complianceFunctions';
import { date as dt } from '../../../utils/helpers';
import {
  AuthUserProps,
  ComplianceObj,
  ComplianceReport as IComplianceReport,
  DatesStartEnd,
  ReducedUnitComp,
  RootState,
  Unit
} from '../../../config/interfaces';

interface ComplianceReportProps {
  complianceReport: ComplianceObj[];
  authorizedUser: AuthUserProps;
  units: Unit[];
  selectedUnits: { id: string; unit: string }[];
}

const ComplianceReport: FC<ComplianceReportProps> = ({
  complianceReport = [],
  authorizedUser,
  units = [],
  selectedUnits
}) => {
  const dispatch = useDispatch();
  const today = new Date();

  const [csvData, setCsvData] = useState<IComplianceReport[]>([]);
  const [listToDisplay, setListToDisplay] = useState<IComplianceReport[]>([]);
  const [listToDisplayTotal, setListToDisplayTotal] =
    useState<IComplianceReport | null>(null);
  const [customDates, setCustomDates] = useState<DatesStartEnd>({
    startDate: format(
      new Date(today.getFullYear(), today.getMonth(), 1),
      'yyyy-MM-dd'
    ),
    endDate: format(
      isFirstDayOfMonth(today) ? today : sub(today, { days: 1 }),
      'yyyy-MM-dd'
    )
  });

  useEffect(() => {
    // when dates change
    dispatch(
      getComplianceReport({
        facilityId: authorizedUser?.facilityId,
        startday: customDates.startDate.replace(/-/g, ''),
        endday: dt.addDayToDayOfYearDate(customDates.endDate).replace(/-/g, '')
      })
    );
  }, [authorizedUser?.facilityId, customDates]);

  useEffect(() => {
    if (units.length > 0) {
      const reducedCompliance = reduceComplianceByUnit(complianceReport, units);
      const complianceValues: ReducedUnitComp[] =
        Object.values(reducedCompliance);
      const complianceByUnitFilter = complianceValues.filter(
        (comp: ReducedUnitComp) =>
          selectedUnits.some(unit => unit.id === comp.groupId)
      );

      const cleanCompliance = complianceByUnitFilter.map(
        (item: ReducedUnitComp) => {
          return {
            unit: item.groupName,
            unitId: item.groupId,
            unitType: item.locTypeDisplay,
            census: item.combinedData.census,
            roundedPercent: item.combinedData.roundedPct,
            roundedNumber: item.combinedData.roundedTotal, // Future: possibly change to just rounded Yes
            unablePercent: item.combinedData.unablePct,
            unableNumber: item.combinedData.unable
          };
        }
      );
      setListToDisplay(cleanCompliance);
      if (selectedUnits.length > 0 && selectedUnits.length === units.length) {
        setListToDisplayTotal(calculateTotalCompliance(cleanCompliance));
      } else {
        setListToDisplayTotal(null);
      }
      setCsvData(cleanCompliance);
    }
  }, [complianceReport, selectedUnits, units]);

  const handleExportCsv = () => {
    dispatch(setCsvToExport(csvData));
  };

  return (
    <CReportContainer id="Compliance-Report-Container">
      <ComplianceHeader
        customDates={customDates}
        setCustomDates={setCustomDates}
        handleExportCsv={handleExportCsv}
      />
      <ComplianceReportTable
        complianceList={listToDisplay}
        complianceTotal={listToDisplayTotal}
      />
    </CReportContainer>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUser: state.AuthorizedUser.authorizedUser,
    complianceReport: state.ReportReducer.complianceReport,
    selectedUnits: state.ConfigReducer.selectedUnits,
    units: state.ConfigReducer.units
  };
};

export default connect(mapReduxStateToProps)(ComplianceReport);

import { FC, ReactChild, useRef } from 'react';
import { connect } from 'react-redux';
import { NeuButton, NeuIcon } from '@neutron/react';
/**
 * Global Type Definition Imports
 */
import { RootState } from '../../../../config/interfaces';
/**
 * Style Imports
 */
import {
  ModCard,
  CardHeader,
  ModalTitle,
  TitleIcon,
  ModalCardContent,
  ModalFooter
} from './ModalCard.styles';
import { handleModal } from '../../../../services/ModalPortal/utils';

interface ModalCardProps {
  headerIcon: string;
  headerTitle: string;
  modalRightBtnText: string;
  modalLeftBtnText: string;
  children: ReactChild[] | ReactChild;
  modalLeftBtnAction: () => void;
  modalRightBtnAction: () => void;
}

const ModalCard: FC<ModalCardProps> = ({
  headerIcon,
  headerTitle,
  modalRightBtnText,
  modalLeftBtnText,
  children,
  modalLeftBtnAction,
  modalRightBtnAction
}) => {
  const modalContentRef = useRef<HTMLNeuCardElement>(null);
  return (
    <ModCard
      className="show-modal"
      id="Modal-Card"
      type="submit"
      maxWidth="413px"
      minHeight="100vh"
      ref={modalContentRef}
    >
      <CardHeader>
        <ModalTitle>
          <TitleIcon large color="primary-40">
            {headerIcon}
          </TitleIcon>
          {headerTitle}
        </ModalTitle>
        <NeuButton
          id="Modal-Card-Close-Button"
          fill="flat"
          onClick={() => handleModal(modalContentRef)}
        >
          <NeuIcon large color="primary">
            close
          </NeuIcon>
          Close
        </NeuButton>
      </CardHeader>
      <ModalCardContent>{children}</ModalCardContent>
      <ModalFooter small>
        <NeuButton
          color="primary"
          fill="outline"
          onClick={e => {
            handleModal(modalContentRef);
            modalLeftBtnAction();
          }}
        >
          {modalLeftBtnText}
        </NeuButton>
        <NeuButton
          color="primary"
          onClick={() => {
            handleModal(modalContentRef);
            modalRightBtnAction();
          }}
        >
          {modalRightBtnText}
        </NeuButton>
      </ModalFooter>
    </ModCard>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    modalState: state.ModalReducer.modal
  };
};
export default connect(mapReduxStateToProps)(ModalCard);

// Importing action file
import ACTIONS from '../actions/actionType';

import { ActionType } from '../../config/interfaces';

const initialState = {
  usersList: [],
  encounters: [],
  adminUserTab: 'allUsers',
  adminUserFilter: {},
  dataToCsvExport: [],
  isLoadingCsvExport: false,
  selectedFacility: '',
  totalUsers: 0,
  adminSearchTerm: '',
  isLoading: false
};

const AdminReducer = (state = initialState, action: ActionType = {}) => {
  const tempState = { ...state };

  switch (action.type) {
    case ACTIONS.ADMIN.GET_USERS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case ACTIONS.ADMIN.GET_USERS_SUCCESS: {
      return {
        ...state,
        usersList: action.data.usersList,
        totalUsers: action.data.totalCount,
        isLoading: false
      };
    }
    case ACTIONS.ADMIN.GET_ENCOUNTERS_SUCCESS: {
      return {
        ...state,
        encounters: action.data
      };
    }
    case ACTIONS.ADMIN.SET_ADMIN_USER_TAB: {
      return {
        ...state,
        adminUserTab: action.data
      };
    }
    case ACTIONS.ADMIN.SET_ADMIN_SEARCH_TERM: {
      return {
        ...state,
        adminSearchTerm: action.data
      };
    }
    case ACTIONS.ADMIN.GET_USERS_TO_EXPORT: {
      return {
        ...state,
        isLoadingCsvExport: true
      };
    }
    case ACTIONS.ADMIN.GET_CSV_TO_EXPORT: {
      return {
        ...state,
        isLoadingCsvExport: true
      };
    }
    case ACTIONS.ADMIN.GET_CSV_TO_EXPORT_SUCCESS: {
      return {
        ...state,
        dataToCsvExport: action.data,
        isLoadingCsvExport: false
      };
    }
    case ACTIONS.ADMIN.GET_CSV_TO_EXPORT_FAIL: {
      return {
        ...state,
        isLoadingCsvExport: false
      };
    }
    case ACTIONS.ADMIN.CLEAR_CSV_TO_EXPORT: {
      return {
        ...state,
        dataToCsvExport: [],
        isLoadingCsvExport: false
      };
    }
    case ACTIONS.ADMIN.SET_ADMIN_FACILITY: {
      return {
        ...state,
        selectedFacility: action.data
      };
    }
    case ACTIONS.ADMIN.GET_API_VERSION_SUCCESS: {
      return {
        ...state,
        apiVersion: action.data
      };
    }
    case ACTIONS.ADMIN.SET_ADMIN_USER_FILTER: {
      return {
        ...state,
        adminUserFilter: action.data
      };
    }
    case ACTIONS.ADMIN.CLEAR_ADMIN_USER_FILTER: {
      return {
        ...state,
        adminUserFilter: {}
      };
    }
    default:
      return tempState;
  }
};

export default AdminReducer;

/* eslint-disable no-nested-ternary */

import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';

import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';

import Loading from '../../shared/Loading';

import { isPercentLessThanOne } from '../../../utils/complianceFunctions';

import { ComplianceReport, RootState } from '../../../config/interfaces';

interface ComplianceReportTableProps {
  complianceList: ComplianceReport[];
  complianceTotal?: ComplianceReport | null;
  loading: boolean;
}
const ComplianceReportTable: FC<ComplianceReportTableProps> = ({
  complianceList,
  complianceTotal,
  loading
}) => {
  const [listToDisplay, setListToDisplay] = useState<ComplianceReport[]>([]);
  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('asc');
  const [active, setActive] = useState<string>('unit');
  const [, setPageNumber] = useState<number>(1);

  useEffect(() => {
    setListToDisplay(complianceList);
  }, [complianceList]);
  const sortedCompliance = useMemo(() => {
    const stateCopy = [...listToDisplay];
    const sorted = stateCopy.sort(
      (a: ComplianceReport, b: ComplianceReport) => {
        if (!(active === 'unit' || active === 'unitType')) {
          if (a[active] === b[active]) {
            return 0;
          }
          if (sortDir === 'asc') {
            return (a[active] as number) - (b[active] as number);
          }
          return (b[active] as number) - (a[active] as number);
        }
        if (a[active] === b[active]) {
          return 0;
        }
        if (!a[active]) {
          return sortDir === 'asc' ? 1 : -1;
        }
        if (!b[active]) {
          return sortDir === 'asc' ? -1 : 1;
        }
        if (sortDir === 'asc') {
          return a[active]! > b[active]! ? 1 : -1;
        }
        return a[active]! > b[active]! ? -1 : 1;
      }
    );
    return sorted;
  }, [listToDisplay, sortDir, active]);

  const sortByHeading = (key: string) => {
    if (key === active && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(key);
  };

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  return (
    <NeuTable>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Unit': '25%', 'Unit Type': '12%', 'Census': '11%', 'Rounded %': '13%', 'Rounded #': '13%', 'Unable to Round %':'13%', 'Unable to Round #':'13%'}"
        header
      >
        <NeuTableHeading
          slot="Unit"
          onClick={() => sortByHeading('unit')}
          icon={
            !(active === 'unit') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'unit'}
        >
          Unit
        </NeuTableHeading>
        <NeuTableHeading
          slot="Unit Type"
          icon={
            !(active === 'unitType')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'unitType'}
          onClick={() => sortByHeading('unitType')}
        >
          Unit Type
        </NeuTableHeading>
        <NeuTableHeading
          slot="Census"
          onClick={() => sortByHeading('census')}
          icon={
            !(active === 'census') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'census'}
        >
          Census
        </NeuTableHeading>
        <NeuTableHeading
          slot="Rounded %"
          icon={
            !(active === 'roundedPercent')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'roundedPercent'}
          onClick={() => sortByHeading('roundedPercent')}
        >
          Rounded %
        </NeuTableHeading>
        <NeuTableHeading
          slot="Rounded #"
          icon={
            !(active === 'roundedNumber')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'roundedNumber'}
          onClick={() => sortByHeading('roundedNumber')}
        >
          Rounded #
        </NeuTableHeading>
        <NeuTableHeading
          slot="Unable to Round %"
          icon={
            !(active === 'unablePercent')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'unablePercent'}
          onClick={() => sortByHeading('unablePercent')}
        >
          Unable to Round %
        </NeuTableHeading>
        <NeuTableHeading
          slot="Unable to Round #"
          icon={
            !(active === 'unableNumber')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'unableNumber'}
          onClick={() => sortByHeading('unableNumber')}
        >
          Unable to Round #
        </NeuTableHeading>
      </NeuTableRow>
      {loading ? (
        <Loading centered={false} />
      ) : !loading && sortedCompliance && sortedCompliance.length === 0 ? (
        <NoDataSplashView type="complianceReport" />
      ) : (
        <>
          <NeuTableBody>
            {(complianceTotal
              ? [{ ...complianceTotal, totalRow: true }, ...sortedCompliance]
              : sortedCompliance
            ).map((item: ComplianceReport) => {
              const isRoundedPercentLessThanOne = isPercentLessThanOne(
                item.roundedNumber,
                item.census
              );
              const isUnablePercentLessThanOne = isPercentLessThanOne(
                item.unableNumber,
                item.roundedNumber
              );
              return (
                <NeuTableRow
                  columns="{'Unit': '25%', 'Unit Type': '12%', 'Census': '11%', 'Rounded %': '13%', 'Rounded #': '13%', 'Unable to Round %':'13%', 'Unable to Round #':'13%'}"
                  size="large"
                  key={item.unitId}
                  style={
                    item.totalRow ? { color: '#093865', fontWeight: 700 } : {}
                  }
                >
                  <NeuLabel style={{ whiteSpace: 'normal' }} slot="Unit">
                    {item.unit}
                  </NeuLabel>
                  <NeuLabel style={{ whiteSpace: 'normal' }} slot="Unit Type">
                    <div>{item.unitType}</div>
                  </NeuLabel>
                  <NeuLabel slot="Census" style={{ whiteSpace: 'normal' }}>
                    <div>{item.census}</div>
                  </NeuLabel>
                  <NeuLabel
                    slot="Rounded %"
                    className={!isRoundedPercentLessThanOne ? 'pl-1' : ''}
                  >
                    {isRoundedPercentLessThanOne ? '<' : ''}
                    {item.roundedPercent}%
                  </NeuLabel>
                  <NeuLabel slot="Rounded #">{item.roundedNumber}</NeuLabel>
                  <NeuLabel
                    slot="Unable to Round %"
                    className={!isUnablePercentLessThanOne ? 'pl-1' : ''}
                  >
                    {isUnablePercentLessThanOne ? '<' : ''}
                    {item.unablePercent}%
                  </NeuLabel>
                  <NeuLabel slot="Unable to Round #">
                    {item.unableNumber}
                  </NeuLabel>
                </NeuTableRow>
              );
            })}
          </NeuTableBody>
          <div>
            <NeuPaginator
              total={complianceList?.length || 1}
              rowsPerPage={50}
              onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
            />
          </div>
        </>
      )}
    </NeuTable>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    loading: state.ReportReducer.loading
  };
};

export default connect(mapReduxStateToProps)(ComplianceReportTable);

import styled from 'styled-components';
import { NeuLabel } from '@neutron/react';

interface DisableProps {
  disabled?: boolean;
}

export const SubTitle = styled.p`
  color: #7C7C7C;
  font-weight: bold;
  font-size 14px;
  line-height: 22px;
`;

export const SubToolbar = styled.div`
  position: static;
  width: 100%;
  height: 60px;
  left: 0px;
  top: 56px;
  background: white;
  display: flex;
  flex-direction: row;
`;

export const TitleContainer = styled.div`
  margin-left: 16px;
  min-width: fit-content;
  padding: 8px 5px 4px 5px;
  justify-content: flex-start;
`;

export const Title = styled.p`
  color: #093865;
  font-weight: bold;
  font-size 16px;
  line-height: 26px;
`;

export const StyledOptionDiv = styled.div`
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0.75rem 0;
  margin-left: 1rem;
`;

export const StyledCheckmark = styled.span<DisableProps>`
  position: absolute;
  top: 3px;
  left: 0;
  height: 1.125rem;
  width: 1.125rem;
  background: transparent;
  border-color: black;
  z-index: 2;
  border-radius: 2px;
  border-style: solid;
  border-width: 2px;
  border-color: #6f6f6f;
  color: var(--checkmark-color);
  &:focus,
  &:active {
    outline: ${({ disabled }) => (disabled ? 'none' : '2px solid #aecde5')};
    outline - offset: ${({ disabled }) => (disabled ? 0 : '1px')};
  }
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 4px;
    top: 0;
    width: 6px;
    height: 0.75rem;
    border: solid white;
    border-width: 0 0.15rem 0.15rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;

export const StyledCheckbox = styled.input<DisableProps>`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ span {
    background-color: ${({ disabled }) => (disabled ? '#c9c9c9' : '#2075ad')};
  }
  &:not(:checked) ~ span {
    background-color: ${({ disabled }) =>
      disabled ? '#c9c9c9' : 'transparent'};
  }
  &:checked ~ span:after {
    display: block;
  }
`;

export const StyledCheckboxLabel = styled(NeuLabel)`
  display: block;
  font-size: 0.85rem;
  margin-left: 2rem;
`;

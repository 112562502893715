import { FC, useEffect, useMemo } from 'react';
import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';

import store from '../../redux/store';

import ReusableTabs from '../../components/reusableTabs';
import FacilityAndUnitSettings from './FacilityAndUnitSettings';
import EmployeeRoundingSettings from './EmployeeRoundingSettings';

import { getEmployeeAccountDetails } from '../../redux/actions/Employee.action';

import { AuthUserProps, RootState } from '../../config/interfaces';

interface AccountProps {
  authorizedUser: AuthUserProps;
  authRoles: string[];
  supportRoundingTypes: string[];
}

const Account: FC<AccountProps> = ({
  authorizedUser,
  authRoles,
  supportRoundingTypes
}) => {
  const isOnlySupportRounder = useMemo(
    () =>
      !(
        authRoles.length > 0 &&
        authRoles.find(role => !supportRoundingTypes.includes(role))
      ),
    [authRoles]
  );

  const isEmployeeRounder = useMemo(
    () =>
      authRoles.includes('enterpriseAdmin') ||
      authRoles.includes('employeeRounder') ||
      authRoles.includes('csrnRounder') ||
      authRoles.includes('cnedRounder'),
    [authRoles]
  );

  const isPatientRounder = useMemo(
    () => authRoles.includes('patientRounder'),
    [authRoles]
  );

  const isCSRNRounder = useMemo(
    () => authRoles.includes('csrnRounder'),
    [authRoles]
  );

  const isCNEdRounder = useMemo(
    () => authRoles.includes('cnedRounder'),
    [authRoles]
  );

  useEffect(() => {
    if (authorizedUser.hcaid) {
      store.dispatch(getEmployeeAccountDetails(authorizedUser.hcaid));
    }
  }, [authorizedUser]);

  const reusableTabItems = {
    base: '/account',
    links: [
      // TODO removed until notifications are managed in orbit
      // { label: 'Notifications', path: '/notifications' },
      ...(!isOnlySupportRounder
        ? [
            {
              label: 'Facility and Unit Settings',
              path: '/facility-and-unit-settings'
            }
          ]
        : []),
      ...(isEmployeeRounder
        ? [
            {
              label: 'Employee Rounding Settings',
              path:
                (isOnlySupportRounder || isPatientRounder) && isCSRNRounder
                  ? '/employee-rounding-settings/csrn-list'
                  : (isOnlySupportRounder || isPatientRounder) && isCNEdRounder
                  ? '/employee-rounding-settings/cned-list'
                  : '/employee-rounding-settings'
            }
          ]
        : [])
    ]
  };

  return (
    <div id="Account">
      <div>
        <ReusableTabs tabItems={reusableTabItems} />
        <Routes>
          {/* TODO add back when notifications are managed in Orbit */}
          {/* <Route path="notifications" element="Notifications" /> */}
          {!isOnlySupportRounder && (
            <Route
              path="facility-and-unit-settings"
              element={<FacilityAndUnitSettings />}
            />
          )}
          {isEmployeeRounder && (
            <Route path="employee-rounding-settings" element={<Outlet />}>
              <Route index element={<EmployeeRoundingSettings />} />
              <Route
                path=":empRoundSettingsTable"
                element={<EmployeeRoundingSettings />}
              />
            </Route>
          )}
          <Route
            path="*"
            element={<Navigate to={localStorage.getItem('lastPath') || '/'} />}
          />
        </Routes>
      </div>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  authorizedUser: state.AuthorizedUser.authorizedUser,
  authRoles: state.AuthorizedUser.authRoles,
  supportRoundingTypes: state.ConfigReducer.supportRoundingTypes
});

export default connect(mapReduxStateToProps)(Account);

import { ReactElement } from 'react';

import { Employee } from './pages/employeeViews';
import Admin from './pages/adminViews/Admin';
import Account from './pages/account/Account';
import Patient from './pages/patientViews/Patient';
import { Reports } from './pages/reportsViews';
import { Support } from './pages/supportViews';
import SupportDropdown from './components/customDropdowns/NavDropdown/SupportDropdown';
import ReportDropdown from './components/customDropdowns/NavDropdown/ReportDropdown';

export interface IMainNavLinkObject {
  url: string;
  navItemText?: string;
  allowedRoles: string[];
  dropdown: boolean;
  component?: ReactElement;
}

export const mainNavRouteObjects = [
  {
    path: '/patients/*',
    allowedRoles: ['enterpriseAdmin', 'patientRounder'],
    page: <Patient />
  },
  {
    path: '/employees/*',
    allowedRoles: ['enterpriseAdmin', 'employeeRounder'],
    page: <Employee />
  },
  {
    path: '/support/*',
    allowedRoles: ['enterpriseAdmin', 'csrnRounder', 'cnedRounder'],
    page: <Support />
  },
  {
    path: '/reports/*',
    allowedRoles: ['enterpriseAdmin', 'patientRounder', 'employeeRounder'],
    page: <Reports />
  },
  {
    path: '/admin/*',
    allowedRoles: ['enterpriseAdmin', 'userAdmin', 'templateConfigurator'],
    page: <Admin />
  },
  {
    path: '/account/*',
    allowedRoles: [
      'enterpriseAdmin',
      'userAdmin',
      'templateConfigurator',
      'patientRounder',
      'employeeRounder',
      'csrnRounder',
      'cnedRounder'
    ],
    page: <Account />
  },
  {
    path: '/task/*',
    allowedRoles: [
      'enterpriseAdmin',
      'userAdmin',
      'templateConfigurator',
      'patientRounder',
      'employeeRounder'
    ]
  }
];

export const mainNavLinkObjects: IMainNavLinkObject[] = [
  {
    url: '/patients/census',
    navItemText: 'Patients',
    allowedRoles: ['enterpriseAdmin', 'patientRounder'],
    dropdown: false
  },
  {
    url: '/employees/list',
    navItemText: 'Employees',
    allowedRoles: ['enterpriseAdmin', 'employeeRounder'],
    dropdown: false
  },
  {
    url: '/support',
    navItemText: 'Support',
    allowedRoles: ['enterpriseAdmin', 'csrnRounder', 'cnedRounder'],
    dropdown: true,
    component: <SupportDropdown key="Support-Tab" />
  },
  {
    url: '/reports',
    navItemText: 'Reports',
    allowedRoles: [
      'enterpriseAdmin',
      'patientRounder',
      'employeeRounder',
      'csrnRounder'
    ],
    dropdown: true,
    component: <ReportDropdown key="Report-Tab" />
  },
  {
    url: '/admin',
    navItemText: 'Admin',
    allowedRoles: ['enterpriseAdmin', 'userAdmin', 'templateConfigurator'],
    dropdown: false
  },
  {
    url: '/account',
    allowedRoles: [
      'enterpriseAdmin',
      'userAdmin',
      'templateConfigurator',
      'patientRounder',
      'employeeRounder',
      'csrnRounder',
      'cnedRounder'
    ],
    dropdown: false
  },
  {
    url: 'task/complete',
    allowedRoles: [
      'enterpriseAdmin',
      'userAdmin',
      'templateConfigurator',
      'patientRounder',
      'employeeRounder'
    ],
    dropdown: false
  }
];

export const subNavReportLinkObjects = [
  {
    type: 'patient',
    url: '/compliance',
    navItemText: 'Compliance',
    allowedRoles: ['enterpriseAdmin', 'patientRounder']
  },
  {
    type: 'patient',
    url: '/rounds',
    navItemText: 'Rounds',
    allowedRoles: ['enterpriseAdmin', 'patientRounder']
  },
  {
    type: 'patient',
    url: '/census',
    navItemText: 'Census',
    allowedRoles: ['enterpriseAdmin', 'patientRounder']
  },
  {
    type: 'employee',
    url: '/rounds',
    navItemText: 'Rounds',
    allowedRoles: ['enterpriseAdmin', 'employeeRounder']
  },
  {
    type: 'employee',
    url: '/frequency',
    navItemText: 'Frequency',
    allowedRoles: ['enterpriseAdmin', 'employeeRounder']
  }
];

export const subNavAdminLinkObjects = [
  {
    url: '/users',
    navItemText: 'Users',
    allowedRoles: ['enterpriseAdmin', 'userAdmin']
  },
  {
    url: '/areas',
    navItemText: 'Areas',
    allowedRoles: ['enterpriseAdmin', 'userAdmin', 'templateConfigurator']
  },
  {
    url: '/templates',
    navItemText: 'Templates',
    allowedRoles: ['enterpriseAdmin', 'templateConfigurator']
  },
  {
    url: '/question-bank',
    navItemText: 'Question Bank',
    allowedRoles: ['enterpriseAdmin', 'templateConfigurator']
  },
  {
    url: '/encounters',
    navItemText: 'Encounters',
    allowedRoles: ['enterpriseAdmin']
  },
  {
    url: '/discharges',
    navItemText: 'Discharges',
    allowedRoles: ['enterpriseAdmin']
  }
];

import { FC, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { NeuOption } from '@neutron/react';
import { NeuInputChangeEventDetail } from '@neutron/core';
import { format, previousSunday, startOfQuarter, sub } from 'date-fns';

import { InputHeading, ModalInput } from '../FilterModal.styles';
import EmployeeSearch from '../../reusableModalPieces/employeeSearch';
import { Dropdown } from '../../../shared/dropdowns';

import { date as dt } from '../../../../utils/helpers';

import { Employee, Filter, RootState } from '../../../../config/interfaces';

const dateRanges = [
  'Custom',
  'Month to date',
  'Last full week',
  'Last full month',
  'Last full quarter'
];

const empRoundingTemplates = [
  'Standard Employee Rounding',
  '30 Day Rounding Template',
  '60 Day Rounding Template',
  '90 Day Rounding Template'
];

const RoundsReportFilterSection: FC<{
  filterValues: Filter;
  type: string;
  setFilterValues: (data: Filter) => void;
  handleRemoveChip: (type: string) => void;
  searchedEmployees?: Employee[];
}> = ({
  filterValues,
  type,
  setFilterValues,
  handleRemoveChip,
  searchedEmployees
}) => {
  const [reportDateRangeType, setReportDateRangeType] = useState<string>('');
  const [showCustomDate, setShowCustomDate] = useState<boolean>(
    filterValues.dateRangeText === 'Custom'
  );
  const [openDateRange, setOpenDateRange] = useState(false);
  const [openTemplate, setOpenTemplate] = useState(false);

  useEffect(() => {
    if (filterValues.dateRangeText === 'Custom') {
      setShowCustomDate(true);
    } else {
      setShowCustomDate(false);
    }
    setReportDateRangeType(filterValues.dateRangeText || 'Month to date');
  }, [filterValues]);

  const handleInputs = (e: CustomEvent<NeuInputChangeEventDetail>) => {
    const { name, value } = e.target as HTMLNeuInputElement;
    return setFilterValues({
      ...filterValues,
      [name]: value
    });
  };

  const handleEmployeeSearchSelected = (employee: Employee) => {
    setFilterValues({ ...filterValues, employee: employee.hcaid });
  };

  const handleTemplateSelected = (ev: React.BaseSyntheticEvent<MouseEvent>) => {
    const selectedTemplate = ev.target.value;
    return selectedTemplate
      ? setFilterValues({
          ...filterValues,
          template: selectedTemplate
        })
      : undefined;
  };

  const handleDateRangeSelect = (ev: React.BaseSyntheticEvent<MouseEvent>) => {
    const selectedRange = ev.target.value;
    setReportDateRangeType(selectedRange);

    const today = new Date();

    if (selectedRange === 'Month to date') {
      setShowCustomDate(false);

      const firstDayOfMonth = format(
        new Date(today.getFullYear(), today.getMonth(), 1),
        'yyyy-MM-dd'
      );

      const dayToday = format(
        new Date(today.getFullYear(), today.getMonth(), today.getDate()),
        'yyyy-MM-dd'
      );

      setFilterValues({
        ...filterValues,
        dateRangeText: selectedRange,
        startDate: firstDayOfMonth,
        endDate: dayToday
      });
    }

    if (selectedRange === 'Last full week') {
      setShowCustomDate(false);
      const lastSunday = previousSunday(new Date());
      const lastSaturdayFormatted = format(
        new Date(
          lastSunday.getFullYear(),
          lastSunday.getMonth(),
          lastSunday.getDate() - 1
        ),
        'yyyy-MM-dd'
      );

      const secondLastSunday = format(
        new Date(
          lastSunday.getFullYear(),
          lastSunday.getMonth(),
          lastSunday.getDate() - 7
        ),
        'yyyy-MM-dd'
      );

      setFilterValues({
        ...filterValues,
        dateRangeText: selectedRange,
        startDate: secondLastSunday,
        endDate: lastSaturdayFormatted
      });
    }

    if (selectedRange === 'Last full month') {
      setShowCustomDate(false);

      const lastMonthFirstDay = format(
        new Date(today.getFullYear(), today.getMonth() - 1, 1),
        'yyyy-MM-dd'
      );

      const lastMonthLastDay = format(
        sub(new Date(today.getFullYear(), today.getMonth(), 1), { days: 1 }),
        'yyyy-MM-dd'
      );
      setFilterValues({
        ...filterValues,
        dateRangeText: selectedRange,
        startDate: lastMonthFirstDay,
        endDate: lastMonthLastDay
      });
    }

    if (selectedRange === 'Last full quarter') {
      setShowCustomDate(false);

      const thisQuarterFirstDay = startOfQuarter(today);
      const lastQuarterLastDayFormatted = format(
        new Date(
          thisQuarterFirstDay.getFullYear(),
          thisQuarterFirstDay.getMonth(),
          thisQuarterFirstDay.getDate() - 1
        ),
        'yyyy-MM-dd'
      );
      const lastQuarterFirstDay = format(
        startOfQuarter(
          new Date(
            thisQuarterFirstDay.getFullYear(),
            thisQuarterFirstDay.getMonth(),
            thisQuarterFirstDay.getDate() - 1
          )
        ),
        'yyyy-MM-dd'
      );
      setFilterValues({
        ...filterValues,
        dateRangeText: selectedRange,
        startDate: lastQuarterFirstDay,
        endDate: lastQuarterLastDayFormatted
      });
    }

    if (selectedRange === 'Custom') {
      setShowCustomDate(true);
      setFilterValues({
        ...filterValues,
        dateRangeText: selectedRange
      });
    }
  };

  const minStartDate = useMemo(
    () => dt.convertToDayOfYear(sub(new Date(), { years: 2 })),
    []
  );
  const maxStartDate = useMemo(
    () =>
      filterValues.endDate &&
      dt.convertToDayOfYear(dt.localDayOfYearDate(filterValues.endDate)),
    [filterValues.endDate]
  );
  const minEndDate = useMemo(
    () =>
      filterValues.startDate &&
      dt.convertToDayOfYear(dt.localDayOfYearDate(filterValues.startDate)),
    [filterValues.startDate]
  );
  const maxEndDate = useMemo(() => dt.convertToDayOfYear(new Date()), []);

  return (
    <div id="Rounds-Report-Filter-Section">
      <InputHeading>Rounded By</InputHeading>
      <EmployeeSearch
        handleRemoveEmployee={handleRemoveChip}
        val={searchedEmployees}
        chipVal={filterValues.employee}
        setEmployeeSelection={(emp: Employee) =>
          handleEmployeeSearchSelected(emp)
        }
        showLabel={false}
      />

      {type === 'patient' && (
        <>
          <InputHeading>Date Range</InputHeading>
          <Dropdown
            classes="pl-0"
            open={openDateRange}
            placeholder={reportDateRangeType}
            width="96%"
            setOpen={setOpenDateRange}
          >
            {dateRanges.map((range: string) => (
              <NeuOption
                key={range}
                className={`dropdown-hover-item${
                  reportDateRangeType === range ? ' selected' : ''
                }`}
                value={range}
                onClick={
                  reportDateRangeType
                    ? (e: React.BaseSyntheticEvent<MouseEvent>) =>
                        handleDateRangeSelect(e)
                    : undefined
                }
              >
                {range}
              </NeuOption>
            ))}
          </Dropdown>
          {showCustomDate ? (
            <div>
              <InputHeading>Starting</InputHeading>
              <ModalInput
                id="start-date"
                type="date"
                name="startDate"
                onNeuChange={handleInputs}
                value={filterValues.startDate}
                min={minStartDate}
                max={maxStartDate}
              />
              <InputHeading>Ending</InputHeading>
              <ModalInput
                id="end-date"
                type="date"
                name="endDate"
                onNeuChange={handleInputs}
                value={filterValues.endDate}
                min={minEndDate}
                max={maxEndDate}
              />
            </div>
          ) : null}
        </>
      )}
      {type === 'employee' && (
        <>
          <InputHeading>Round Type</InputHeading>
          <Dropdown
            classes="pl-0"
            open={openTemplate}
            placeholder={filterValues.template || 'Select a rounding template'}
            setOpen={setOpenTemplate}
          >
            {empRoundingTemplates.map((template: string) => (
              <NeuOption
                className={`dropdown-hover-item${
                  filterValues.template === template ? ' selected' : ''
                }`}
                key={template}
                value={template}
                onClick={handleTemplateSelected}
              >
                {template}
              </NeuOption>
            ))}
          </Dropdown>
        </>
      )}
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    searchedEmployees: state.EmployeeReducer.searchedEmployees
  };
};

export default connect(mapReduxStateToProps)(RoundsReportFilterSection);

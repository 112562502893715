/**
 * External Imports
 */
import {
  FC,
  useEffect,
  useState,
  SetStateAction,
  Dispatch,
  useMemo
} from 'react';
import {
  NeuTable,
  NeuTableRow,
  NeuTableHeading,
  NeuTableBody,
  NeuLabel,
  NeuPaginator
} from '@neutron/react';
import { parse, format } from 'date-fns';
/**
 * Internal Imports
 */
import NoDataSplashView from '../../noDataSplashView/NoDataSplashView';

import store from '../../../redux/store';
import { getPatientRoundHistory } from '../../../redux/actions/Report.action';

import { toTitleCase } from '../../../utils/helpers';
/**
 * Global Type Definition Imports
 */
import { Encounter } from '../../../config/interfaces';
/**
 * Style Imports
 */
import { PaginationContainer } from './EncountersTable.styles';

interface EncountersTableProps {
  encountersList?: Encounter[];
  setEncounterHistory: Dispatch<SetStateAction<Encounter | null>>;
  setShowHistory: Dispatch<SetStateAction<boolean>>;
}
const EncountersTable: FC<EncountersTableProps> = ({
  encountersList,
  setEncounterHistory,
  setShowHistory
}) => {
  const [filteredPtr, setFilteredPtr] = useState<Encounter[]>([]);

  const [pageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);

  const [sortDir, setSortDir] = useState<'desc' | 'asc'>('desc');
  const [sortKey, setSortKey] = useState<string>('admittime');
  const [active, setActive] = useState<string>('Admit Time');

  const sortedPtr = useMemo(() => {
    const stateCopy = [...filteredPtr];
    const sorted = stateCopy.sort((a: Encounter, b: Encounter) => {
      if (a[sortKey] === b[sortKey]) {
        return 0;
      }
      if (!a[sortKey]) {
        return sortDir === 'asc' ? 1 : -1;
      }
      if (!b[sortKey]) {
        return sortDir === 'asc' ? -1 : 1;
      }
      if (sortDir === 'asc') {
        return a[sortKey]! > b[sortKey]! ? 1 : -1;
      }
      return a[sortKey]! > b[sortKey]! ? -1 : 1;
    });
    return sorted;
  }, [filteredPtr, sortDir, sortKey]);

  useEffect(() => {
    if (encountersList) {
      setFilteredPtr(encountersList);
    }
  }, [encountersList]);

  const encounterDataToList = useMemo(() => {
    return sortedPtr.slice(
      pageNumber * pageSize - pageSize,
      pageNumber * pageSize
    );
  }, [pageNumber, pageSize, sortedPtr]);

  const pageNumberChanged = (pageNum: number) => {
    if (pageNum) setPageNumber(pageNum);
  };

  const sortByHeading = (key: string, column: string) => {
    if (key === sortKey && sortDir === 'asc') {
      setSortDir('desc');
    } else {
      setSortDir('asc');
    }
    setActive(column);
    setSortKey(key);
  };

  const handleGetHistory = (encounter: Encounter) => {
    store.dispatch(
      getPatientRoundHistory({
        facilityId: encounter.facility,
        patientId: encounter.urn
      })
    );
    setEncounterHistory(encounter);
    setShowHistory(true);
  };

  return (
    <NeuTable style={{ height: '100%' }}>
      <NeuTableRow
        style={{ backgroundColor: 'white', height: '56px' }}
        columns="{'Admit Date': '10%', 'Patient Name': '15%', 'MRN': '15%', 'Account Number': '15%', 'Patient Type': '15%', 'Unit': '10%','Room': '10%','Bed': '10%'}"
        header
      >
        <NeuTableHeading
          id="Encounter-Table-Admit-Column"
          slot="Admit Date"
          onClick={() => sortByHeading('admittime', 'Admit Date')}
          icon={!(active === 'Admit Date') ? 'asc' : sortDir}
          active={active === 'Admit Date'}
        >
          Admit Date
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-Patient-Name-Column"
          slot="Patient Name"
          onClick={() => sortByHeading('firstname', 'Patient Name')}
          icon={
            !(active === 'Patient Name')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'Patient Name'}
        >
          Patient Name
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-MRN-Column"
          slot="MRN"
          onClick={() => sortByHeading('mrn', 'MRN')}
          icon={
            !(active === 'MRN') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'MRN'}
        >
          MRN
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-Account-Column"
          slot="Account Number"
          onClick={() => sortByHeading('accountnumber', 'Account Number')}
          icon={
            !(active === 'Account Number')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'Account Number'}
        >
          Account Number
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-Patient-Type-Column"
          slot="Patient Type"
          onClick={() => sortByHeading('patienttype', 'Patient Type')}
          icon={
            !(active === 'Patient Type')
              ? 'asc'
              : sortDir === 'asc'
              ? 'desc'
              : 'asc'
          }
          active={active === 'Patient Type'}
        >
          Patient Type
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-Unit-Column"
          slot="Unit"
          onClick={() => sortByHeading('pointofcare', 'Unit')}
          icon={
            !(active === 'Unit') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'Unit'}
        >
          Unit
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-Room-Column"
          slot="Room"
          onClick={() => sortByHeading('room', 'Room')}
          icon={
            !(active === 'Room') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'Room'}
        >
          Room
        </NeuTableHeading>
        <NeuTableHeading
          id="Encounter-Table-Bed-Column"
          slot="Bed"
          onClick={() => sortByHeading('bed', 'Bed')}
          icon={
            !(active === 'Bed') ? 'asc' : sortDir === 'asc' ? 'desc' : 'asc'
          }
          active={active === 'Bed'}
        >
          Bed
        </NeuTableHeading>
      </NeuTableRow>
      {!encounterDataToList || encounterDataToList.length === 0 ? (
        <NoDataSplashView type="encounters" />
      ) : (
        <>
          <NeuTableBody>
            {encounterDataToList?.map((encounter: Encounter) => {
              return (
                <NeuTableRow
                  id={`Encounter-Row-${encounter.msgid}`}
                  columns="{'Admit Date': '10%', 'Patient Name': '15%', 'MRN': '15%', 'Account Number': '15%', 'Patient Type': '15%', 'Unit': '10%','Room': '10%','Bed': '10%'}"
                  size="large"
                  key={encounter.msgid}
                  onClick={() => {
                    handleGetHistory(encounter);
                  }}
                >
                  <NeuLabel slot="Admit Date" style={{ whiteSpace: 'normal' }}>
                    {encounter?.admittime?.length === 14
                      ? format(
                          parse(
                            encounter?.admittime,
                            'yyyyMMddHHmmss',
                            new Date()
                          ),
                          'Pp'
                        ).replace(',', ' ')
                      : encounter?.admittime?.length === 12
                      ? format(
                          parse(
                            encounter?.admittime,
                            'yyyyMMddHHmm',
                            new Date()
                          ),
                          'Pp'
                        ).replace(',', ' ')
                      : encounter?.admittime?.length === 8
                      ? format(
                          parse(encounter?.admittime, 'yyyyMMdd', new Date()),
                          'P'
                        ).replace(',', ' ')
                      : null}
                  </NeuLabel>
                  <NeuLabel slot="Patient Name">
                    {toTitleCase(
                      `${encounter.firstname} ${encounter.lastname}`
                    )}
                  </NeuLabel>
                  <NeuLabel slot="MRN">{encounter.mrn}</NeuLabel>
                  <NeuLabel slot="Account Number">
                    {encounter.accountnumber}
                  </NeuLabel>
                  <NeuLabel slot="Patient Type">
                    {encounter.patienttype}
                  </NeuLabel>
                  <NeuLabel slot="Unit">{encounter.pointofcare}</NeuLabel>
                  <NeuLabel slot="Room">
                    {encounter.room ? encounter.room : 'N/A'}
                  </NeuLabel>
                  <NeuLabel slot="Bed">
                    {encounter.bed ? encounter.bed : 'N/A'}
                  </NeuLabel>
                </NeuTableRow>
              );
            })}
          </NeuTableBody>
          <PaginationContainer>
            <NeuPaginator
              total={encountersList?.length || 1}
              rowsPerPage={pageSize}
              onNeuChange={e => pageNumberChanged(e.detail.pageNumber)}
            />
          </PaginationContainer>
        </>
      )}
    </NeuTable>
  );
};

export default EncountersTable;

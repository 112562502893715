import { FC, useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useParams
} from 'react-router-dom';

import EmployeeList from './EmployeeList';
import EmployeeProfile from './EmployeeProfile';
import EmployeeStoplight from './EmployeeStoplight';
import EmployeeFeedback from './EmployeeFeedback';
import EmployeeIssues from './EmployeeIssues';
import ReusableTabs from '../../components/reusableTabs';

import { getEmployeesConfig } from '../../redux/actions/Employee.action';
import { setStoplightSearchQuery } from '../../redux/actions/Stoplight.action';
import store from '../../redux/store';
import { setTaskSearchQuery } from '../../redux/actions/Task.action';
import {
  setUserSectionType,
  resetUserSectionType
} from '../../redux/actions/User.action';

import { useDebounceValue } from '../../utils/debouncers';
import { RootState } from '../../config/interfaces';

const Employee: FC<{
  authorizedUserId: string;
}> = ({ authorizedUserId }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>('');

  const dbSearchTerm = useDebounceValue(searchTerm, 250);

  const { '*': view } = useParams();
  const { pathname } = useLocation();

  /**
   * In order to identify whether the user is in Employee or Patient section.
   * store variable: userSection
   */
  useEffect(() => {
    store.dispatch(setUserSectionType('employee'));
    return () => {
      store.dispatch(resetUserSectionType());
    };
  }, []);

  useEffect(() => {
    store.dispatch(getEmployeesConfig(authorizedUserId));
  }, []);

  useEffect(() => {
    if (view === 'issues') dispatch(setTaskSearchQuery(searchTerm));
    if (view === 'feedback') dispatch(setTaskSearchQuery(searchTerm));
    if (view === 'stoplight') dispatch(setStoplightSearchQuery(searchTerm));
  }, [dbSearchTerm]);

  const tabItems = {
    base: '/employees',
    links: [
      { path: '/list', label: 'List' },
      { path: '/issues', label: 'Issues' },
      { path: '/feedback', label: 'Feedback' },
      { path: '/stoplight', label: 'Stoplight' }
    ]
  };

  return (
    <div>
      {!pathname.includes('/profile') && <ReusableTabs tabItems={tabItems} />}
      <Routes>
        <Route index element={<Navigate to="list" />} />
        <Route
          path="list"
          element={
            <EmployeeList
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />

        <Route
          path="issues"
          element={
            <EmployeeIssues
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />

        <Route
          path="feedback"
          element={
            <EmployeeFeedback
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />

        <Route
          path="stoplight"
          element={
            <EmployeeStoplight
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
            />
          }
        />
        <Route path="profile" element={<EmployeeProfile />} />
      </Routes>
    </div>
  );
};

const mapReduxStateToProps = (state: RootState) => {
  return {
    authorizedUserId: state.AuthorizedUser.authorizedUser.hcaid
  };
};

export default connect(mapReduxStateToProps)(Employee);

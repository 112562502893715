import { FC, useCallback, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';

import { NeuSubLeftNav, NeuItem, NeuStepper, NeuLabel } from '@neutron/react';
import { useAnalyticsApi } from '@shared-web-analytics/react/dist';

import { logListClick, ListFilters } from '../../utils/analyticsHelpers';
import { setLastPath } from '../../utils/helpers';

const SupportSideNav: FC<{ type: string }> = ({ type }) => {
  const { csrnFilter = 'all', cnedFilter = 'all' } = useParams();

  const { logTrackingEvent } = useAnalyticsApi();

  const supportFilters = useMemo(
    () => [
      'All',
      'No Round',
      'Recently Completed',
      'New Nurses',
      'New to HCA Nurses',
      'New to Dept Nurses'
      // 'Travel Nurses'
    ],
    []
  );

  const supportSubPath = useMemo(
    () => (type === 'csrn' ? 'csrn-list' : 'cned-list'),
    [type]
  );

  const changeTab = useCallback(
    (filter: string) => {
      switch (filter) {
        case 'All':
        case 'No Round':
        case 'Recently Completed':
        case 'New Nurses':
        case 'New to HCA Nurses':
        case 'New to Dept Nurses':
          logTrackingEvent(
            logListClick(
              filter?.toLowerCase().replace(/ /g, '-') as ListFilters,
              type
            )
          );
          setLastPath(
            filter === 'All'
              ? `/support/${supportSubPath}`
              : `/support/${supportSubPath}/${filter
                  .replace(/ /g, '-')
                  .toLowerCase()}`
          );
          break;
        default:
          console.log('No event for tab filter');
      }
    },
    [type, logTrackingEvent, logListClick]
  );

  // to do: edit Links to show blue/bold when active
  return (
    <NeuSubLeftNav
      id="support-side-nav"
      className="sideNavCard"
      side="left"
      name="MENU"
      color="plain"
      style={{ marginTop: '-3px', zIndex: 1 }}
    >
      {supportFilters &&
        supportFilters.map((filter: string) => (
          <Link
            key={filter}
            to={
              filter === 'All'
                ? `/support/${supportSubPath}`
                : `/support/${supportSubPath}/${filter
                    .replace(/ /g, '-')
                    .toLowerCase()}`
            }
            onClick={() => changeTab(filter)}
          >
            <NeuItem
              id={`Side-Nav-Support-${filter}-Link`}
              className="pl-3"
              color={
                (type === 'csrn' ? csrnFilter : cnedFilter) ===
                filter.replace(/ /g, '-').toLowerCase()
                  ? 'primary-10'
                  : 'plain-0'
              }
            >
              <NeuStepper slot="start" />
              <NeuLabel
                id={`Side-Nav-Support-${filter}-Label`}
                className="border border-dark"
              >
                {filter}
              </NeuLabel>
            </NeuItem>
          </Link>
        ))}
    </NeuSubLeftNav>
  );
};
export default SupportSideNav;

import { FC, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { NeuButton, NeuSpinner } from '@neutron/react';
import { deleteTask } from '../../../../redux/actions/Task.action';
import { setToastType } from '../../../../redux/actions/Toast.action';

import { toast } from '../../../../services/Toast';
import {
  FeedbackPost,
  RootState,
  TaskPost
} from '../../../../config/interfaces';

interface ICompleteProps {
  task?: FeedbackPost | TaskPost;
  loading: boolean;
}

const DeleteBtn: FC<ICompleteProps> = ({ task, loading }) => {
  const dispatch = useDispatch();
  const handleDelete = useCallback(e => {
    e.stopPropagation();
    dispatch(setToastType({ type: 'Delete Issue' }));
    toast(
      `Are you sure you want to delete this ${task?.taskType}?`,
      'info',
      null,
      500,
      true,
      null,
      () => {
        if (task) {
          dispatch(deleteTask(task));
        }
      }
    );
  }, []);

  return (
    <NeuButton
      id="Delete-Button"
      color="red-60"
      onClick={e => handleDelete(e)}
      style={{ textAlign: 'right' }}
    >
      {loading ? (
        <NeuSpinner ariaLabel="spinner" color="plain-0" tiny />
      ) : (
        'Delete'
      )}
    </NeuButton>
  );
};

const mapReduxStateToProps = (state: RootState) => ({
  loading: state.TaskReducer.loading,
  taskData: state.ModalReducer.modalData
});

export default connect(mapReduxStateToProps)(DeleteBtn);

import Events from '../services/Analytics';
import store from '../redux/store';

export type TaskEvents =
  | 'filter'
  | 'add'
  | 'save'
  | 'close'
  | 'download'
  | 'search';
export type ListFilters =
  | 'to-do'
  | 'completed'
  | 'new-hires'
  | 'all'
  | 'no-round'
  | 'recently-completed'
  | 'new-nurses'
  | 'new-to-hca-nurses'
  | 'new-to-dept-nurses'
  | 'search';
export type HistorySideNavFilters =
  | 'past-notes'
  | 'save-notes'
  | 'past-rounds'
  | 'past-issues'
  | 'past-feedback';
export type RoundEvents = 'start' | 'cancel' | 'complete';
export type ReportEvents =
  | 'calendar'
  | 'date-range'
  | 'filter'
  | 'info'
  | 'export'
  | 'search';
export type HelpEvents = 'sharepoint' | 'health-stream' | 'hr-emp-rounding';

// Tasks actions
export function logAdHocClick(type: string, event: TaskEvents) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultModuleTypeOrg = {
    module: Events.AddNew.Module,
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (event) {
    case 'add':
      switch (type) {
        case 'Add Issue':
          return [
            {
              screen: Events.AddNew.Screen.AddNewIssue.Name,
              eventName:
                Events.AddNew.Screen.AddNewIssue.Events.AddNewIssue_Click,
              ...defaultModuleTypeOrg
            }
          ];
        case 'Feedback':
          return [
            {
              screen: Events.AddNew.Screen.AddNewFeedback.Name,
              eventName:
                Events.AddNew.Screen.AddNewFeedback.Events.AddNewFeedback_Click,
              ...defaultModuleTypeOrg
            }
          ];
        case 'Add Stoplight':
          return [
            {
              screen: Events.AddNew.Screen.AddNewStoplight.Name,
              eventName:
                Events.AddNew.Screen.AddNewStoplight.Events
                  .AddNewStoplight_Click,
              ...defaultModuleTypeOrg
            }
          ];
        default:
          return [];
      }
    case 'save':
      switch (type) {
        case 'Add Issue':
          return [
            {
              screen: Events.AddNew.Screen.AddNewIssue.Name,
              eventName:
                Events.AddNew.Screen.AddNewIssue.Events.SaveIssue_Click,
              ...defaultModuleTypeOrg
            }
          ];
        case 'Feedback':
          return [
            {
              screen: Events.AddNew.Screen.AddNewFeedback.Name,
              eventName:
                Events.AddNew.Screen.AddNewFeedback.Events.SaveFeedback_Click,
              ...defaultModuleTypeOrg
            }
          ];
        case 'Add Stoplight':
          return [
            {
              screen: Events.AddNew.Screen.AddNewStoplight.Name,
              eventName:
                Events.AddNew.Screen.AddNewStoplight.Events.SaveStoplight_Click,
              ...defaultModuleTypeOrg
            }
          ];
        default:
          return [];
      }
    case 'close':
      switch (type) {
        case 'Add Issue':
          return [
            {
              screen: Events.AddNew.Screen.AddNewIssue.Name,
              eventName:
                Events.AddNew.Screen.AddNewIssue.Events.CloseIssue_Click,
              ...defaultModuleTypeOrg
            }
          ];
        case 'Feedback':
          return [
            {
              screen: Events.AddNew.Screen.AddNewFeedback.Name,
              eventName:
                Events.AddNew.Screen.AddNewFeedback.Events.CloseFeedback_Click,
              ...defaultModuleTypeOrg
            }
          ];
        case 'Add Stoplight':
          return [
            {
              screen: Events.AddNew.Screen.AddNewStoplight.Name,
              eventName:
                Events.AddNew.Screen.AddNewStoplight.Events
                  .CloseStoplight_Click,
              ...defaultModuleTypeOrg
            }
          ];
        default:
          return [];
      }
    default:
      return [];
  }
}

export function logTaskClick(view: string, event: TaskEvents) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;
  const { userSection } = store.getState().UserReducer;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (view) {
    case 'issues':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Issues.Name,
              eventName:
                event === 'filter'
                  ? Events.Employee.Screen.Issues.Events.Filter_Click
                  : event === 'add'
                  ? Events.Employee.Screen.Issues.Events.Add_Issue_Click
                  : event === 'save'
                  ? Events.Employee.Screen.Issues.Events.Save_Issue_Click
                  : event === 'close'
                  ? Events.Employee.Screen.Issues.Events.Close_Issue_Click
                  : event === 'download'
                  ? Events.Employee.Screen.Issues.Events.Download_Click
                  : Events.Employee.Screen.Issues.Events.Search_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Issues.Name,
              eventName:
                event === 'filter'
                  ? Events.Patient.Screen.Issues.Events.Filter_Click
                  : event === 'add'
                  ? Events.Patient.Screen.Issues.Events.Add_Issue_Click
                  : event === 'save'
                  ? Events.Patient.Screen.Issues.Events.Save_Issue_Click
                  : event === 'close'
                  ? Events.Patient.Screen.Issues.Events.Close_Issue_Click
                  : event === 'download'
                  ? Events.Patient.Screen.Issues.Events.Download_Click
                  : Events.Patient.Screen.Issues.Events.Search_Click,
              ...defaultTypeOrg
            }
      ];
    case 'feedback':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Feedback.Name,
              eventName:
                event === 'filter'
                  ? Events.Employee.Screen.Feedback.Events.Filter_Click
                  : event === 'add'
                  ? Events.Employee.Screen.Feedback.Events.Add_Feedback_Click
                  : event === 'save'
                  ? Events.Employee.Screen.Feedback.Events.Save_Feedback_Click
                  : event === 'close'
                  ? Events.Employee.Screen.Feedback.Events.Close_Feedback_Click
                  : event === 'download'
                  ? Events.Employee.Screen.Feedback.Events.Download_Click
                  : Events.Employee.Screen.Feedback.Events.Search_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Feedback.Name,
              eventName:
                event === 'filter'
                  ? Events.Patient.Screen.Feedback.Events.Filter_Click
                  : event === 'add'
                  ? Events.Patient.Screen.Feedback.Events.Add_Feedback_Click
                  : event === 'save'
                  ? Events.Patient.Screen.Feedback.Events.Save_Feedback_Click
                  : event === 'close'
                  ? Events.Patient.Screen.Feedback.Events.Close_Feedback_Click
                  : event === 'download'
                  ? Events.Patient.Screen.Feedback.Events.Download_Click
                  : Events.Patient.Screen.Feedback.Events.Search_Click,
              ...defaultTypeOrg
            }
      ];
    case 'stoplight':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Stoplights.Name,
              eventName:
                event === 'filter'
                  ? Events.Employee.Screen.Stoplights.Events.Filter_Click
                  : event === 'add'
                  ? Events.Employee.Screen.Stoplights.Events.Add_Stoplight_Click
                  : event === 'save'
                  ? Events.Employee.Screen.Stoplights.Events
                      .Save_Stoplight_Click
                  : event === 'close'
                  ? Events.Employee.Screen.Stoplights.Events
                      .Close_Stoplight_Click
                  : event === 'download'
                  ? Events.Employee.Screen.Stoplights.Events.Download_Click
                  : Events.Employee.Screen.Stoplights.Events.Search_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Stoplights.Name,
              eventName:
                event === 'filter'
                  ? Events.Patient.Screen.Stoplights.Events.Filter_Click
                  : event === 'add'
                  ? Events.Patient.Screen.Stoplights.Events.Add_Stoplight_Click
                  : event === 'save'
                  ? Events.Patient.Screen.Stoplights.Events.Save_Stoplight_Click
                  : event === 'close'
                  ? Events.Patient.Screen.Stoplights.Events
                      .Close_Stoplight_Click
                  : event === 'download'
                  ? Events.Patient.Screen.Stoplights.Events.Download_Click
                  : Events.Patient.Screen.Stoplights.Events.Search_Click,
              ...defaultTypeOrg
            }
      ];
      break;
    default:
      console.log('No task view for event');
      return [];
  }
}

// Table views
export const logTableStart = (lastPath: string | null) => {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultNameTypeOrg = {
    eventName: null,
    eventType: 'start',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (lastPath) {
    case '/patients/census':
      return [
        {
          module: Events.Patient.Module,
          screen: Events.Patient.Screen.Census.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/patients/issues':
      return [
        {
          module: Events.Patient.Module,
          screen: Events.Patient.Screen.Issues.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/patients/feedback':
      return [
        {
          module: Events.Patient.Module,
          screen: Events.Patient.Screen.Feedback.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/patients/stoplight':
      return [
        {
          module: Events.Patient.Module,
          screen: Events.Patient.Screen.Stoplights.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/employees/list':
      return [
        {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.List.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/employees/issues':
      return [
        {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.Issues.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/employees/feedback':
      return [
        {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.Feedback.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/employees/stoplight':
      return [
        {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.Stoplights.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/support/csrn-list':
      return [
        {
          module: Events.CSRN.Module,
          screen: Events.CSRN.Screen.List.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/support/cned-list':
      return [
        {
          module: Events.CNEd.Module,
          screen: Events.CNEd.Screen.List.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/reports/employee/rounds':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.EmployeeRounds.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/reports/employee/frequency':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.EmployeeFreq.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/reports/patient/compliance':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.PatientCompliance.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/reports/patient/rounds':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.PatientRounds.Name,
          ...defaultNameTypeOrg
        }
      ];
    case '/reports/patient/census':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.PatientCensus.Name,
          ...defaultNameTypeOrg
        }
      ];
    default:
      return [];
  }
};

// List actions
export function logListClick(filter: ListFilters, view?: string) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;
  const { userSection } = store.getState().UserReducer;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (filter) {
    case 'search':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.List.Name,
              eventName: Events.Employee.Screen.List.Events.Search_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? view?.includes('csrn-list')
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.List.Name,
                eventName: Events.CSRN.Screen.List.Events.Search_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.List.Name,
                eventName: Events.CNEd.Screen.List.Events.Search_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Census.Name,
              eventName: Events.Employee.Screen.List.Events.Search_Click,
              ...defaultTypeOrg
            }
      ];
    case 'to-do':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.List.Name,
              eventName: Events.Employee.Screen.List.Events.ToDo_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Census.Name,
              eventName: Events.Patient.Screen.Census.Events.ToDo_Click,
              ...defaultTypeOrg
            }
      ];
    case 'completed':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.List.Name,
              eventName: Events.Employee.Screen.List.Events.Completed_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Census.Name,
              eventName: Events.Patient.Screen.Census.Events.Completed_Click,
              ...defaultTypeOrg
            }
      ];
    case 'new-hires':
      return [
        {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.List.Name,
          eventName: Events.Employee.Screen.List.Events.NewHires_Click,
          ...defaultTypeOrg
        }
      ];
    case 'all':
      return view?.includes('csrn')
        ? [
            {
              module: Events.CSRN.Module,
              screen: Events.CSRN.Screen.List.Name,
              eventName: Events.CSRN.Screen.List.Events.CSRN_All_Click,
              ...defaultTypeOrg
            }
          ]
        : [
            {
              module: Events.CNEd.Module,
              screen: Events.CNEd.Screen.List.Name,
              eventName: Events.CNEd.Screen.List.Events.CNEd_All_Click,
              ...defaultTypeOrg
            }
          ];
    case 'no-round':
      return view?.includes('csrn')
        ? [
            {
              module: Events.CSRN.Module,
              screen: Events.CSRN.Screen.List.Name,
              eventName: Events.CSRN.Screen.List.Events.No_Round_Click,
              ...defaultTypeOrg
            }
          ]
        : [
            {
              module: Events.CNEd.Module,
              screen: Events.CNEd.Screen.List.Name,
              eventName: Events.CNEd.Screen.List.Events.No_Round_Click,
              ...defaultTypeOrg
            }
          ];
    case 'recently-completed':
      return view?.includes('csrn')
        ? [
            {
              module: Events.CSRN.Module,
              screen: Events.CSRN.Screen.List.Name,
              eventName:
                Events.CSRN.Screen.List.Events.Recently_Completed_Click,
              ...defaultTypeOrg
            }
          ]
        : [
            {
              module: Events.CNEd.Module,
              screen: Events.CNEd.Screen.List.Name,
              eventName:
                Events.CNEd.Screen.List.Events.Recently_Completed_Click,
              ...defaultTypeOrg
            }
          ];
    case 'new-nurses':
      return view?.includes('csrn')
        ? [
            {
              module: Events.CSRN.Module,
              screen: Events.CSRN.Screen.List.Name,
              eventName: Events.CSRN.Screen.List.Events.New_Nurses_Click,
              ...defaultTypeOrg
            }
          ]
        : [
            {
              module: Events.CNEd.Module,
              screen: Events.CNEd.Screen.List.Name,
              eventName: Events.CNEd.Screen.List.Events.New_Nurses_Click,
              ...defaultTypeOrg
            }
          ];
    case 'new-to-hca-nurses':
      return view?.includes('csrn')
        ? [
            {
              module: Events.CSRN.Module,
              screen: Events.CSRN.Screen.List.Name,
              eventName: Events.CSRN.Screen.List.Events.New_to_HCA_Nurses_Click,
              ...defaultTypeOrg
            }
          ]
        : [
            {
              module: Events.CNEd.Module,
              screen: Events.CNEd.Screen.List.Name,
              eventName: Events.CNEd.Screen.List.Events.New_to_HCA_Nurses_Click,
              ...defaultTypeOrg
            }
          ];
    case 'new-to-dept-nurses':
      return view?.includes('csrn')
        ? [
            {
              module: Events.CSRN.Module,
              screen: Events.CSRN.Screen.List.Name,
              eventName:
                Events.CSRN.Screen.List.Events.New_to_Dept_Nurses_Click,
              ...defaultTypeOrg
            }
          ]
        : [
            {
              module: Events.CNEd.Module,
              screen: Events.CNEd.Screen.List.Name,
              eventName:
                Events.CNEd.Screen.List.Events.New_to_Dept_Nurses_Click,
              ...defaultTypeOrg
            }
          ];
    default:
      console.log('No task type for event');
      return [];
  }
}

// Round actions
export function logRoundProfile(supportType?: string) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;
  const { userSection } = store.getState().UserReducer;

  const defaultNameTypeOrg = {
    eventName: null,
    eventType: 'start',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  return [
    userSection === 'employee'
      ? {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.Profile.Name,
          ...defaultNameTypeOrg
        }
      : userSection === 'support'
      ? supportType === 'csrn'
        ? {
            module: Events.CSRN.Module,
            screen: Events.CSRN.Screen.Profile.Name,
            ...defaultNameTypeOrg
          }
        : {
            module: Events.CNEd.Module,
            screen: Events.CNEd.Screen.Profile.Name,
            ...defaultNameTypeOrg
          }
      : {
          module: Events.Patient.Module,
          screen: Events.Patient.Screen.Profile.Name,
          ...defaultNameTypeOrg
        }
  ];
}

export function logRoundProfileClick(
  event: HistorySideNavFilters | 'date',
  supportType?: string
) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;
  const { userSection } = store.getState().UserReducer;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };

  switch (event) {
    case 'past-notes':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName: Events.Employee.Screen.Profile.Events.Notes_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName: Events.CSRN.Screen.Profile.Events.Notes_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName: Events.CNEd.Screen.Profile.Events.Notes_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName: Events.Patient.Screen.Profile.Events.Notes_Click,
              ...defaultTypeOrg
            }
      ];
    case 'save-notes':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName: Events.Employee.Screen.Profile.Events.SaveNotes_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName: Events.CSRN.Screen.Profile.Events.SaveNotes_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName: Events.CNEd.Screen.Profile.Events.SaveNotes_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName: Events.Patient.Screen.Profile.Events.SaveNotes_Click,
              ...defaultTypeOrg
            }
      ];
    case 'past-rounds':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName: Events.Employee.Screen.Profile.Events.PastRounds_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName: Events.CSRN.Screen.Profile.Events.PastRounds_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName: Events.CNEd.Screen.Profile.Events.PastRounds_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName: Events.Patient.Screen.Profile.Events.PastRounds_Click,
              ...defaultTypeOrg
            }
      ];
    case 'past-issues':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName: Events.Employee.Screen.Profile.Events.PastIssues_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName: Events.Patient.Screen.Profile.Events.PastIssues_Click,
              ...defaultTypeOrg
            }
      ];
    case 'past-feedback':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName:
                Events.Employee.Screen.Profile.Events.PastFeedback_Click,
              ...defaultTypeOrg
            }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName:
                Events.Patient.Screen.Profile.Events.PastFeedback_Click,
              ...defaultTypeOrg
            }
      ];
    case 'date':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName: Events.Employee.Screen.Profile.Events.Calendar_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName: Events.CSRN.Screen.Profile.Events.Calendar_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName: Events.CNEd.Screen.Profile.Events.Calendar_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName: Events.Patient.Screen.Profile.Events.Yesterday_Click,
              ...defaultTypeOrg
            }
      ];
    default:
      console.log('No type for event');
      return [];
  }
}

export function logRoundClick(event: RoundEvents, supportType?: string) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;
  const { currentRound } = store.getState().RoundReducer;
  const { userSection } = store.getState().UserReducer;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (event) {
    case 'start':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName:
                Events.Employee.Screen.Profile.Events.StartaNewRound_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName:
                  Events.CSRN.Screen.Profile.Events.StartaNewRound_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName:
                  Events.CNEd.Screen.Profile.Events.StartaNewRound_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName:
                Events.Patient.Screen.Profile.Events.StartaNewRound_Click,
              ...defaultTypeOrg
            }
      ];
    case 'cancel':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName:
                Events.Employee.Screen.Profile.Events.CancelRound_Click,
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName: Events.CSRN.Screen.Profile.Events.CancelRound_Click,
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName: Events.CNEd.Screen.Profile.Events.CancelRound_Click,
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName: Events.Patient.Screen.Profile.Events.CancelRound_Click,
              ...defaultTypeOrg
            }
      ];
    case 'complete':
      return [
        userSection === 'employee'
          ? {
              module: Events.Employee.Module,
              screen: Events.Employee.Screen.Profile.Name,
              eventName:
                Events.Employee.Screen.Profile.Events.CompleteRound_Click,
              param: {
                roundId: currentRound.roundId
              },
              ...defaultTypeOrg
            }
          : userSection === 'support'
          ? supportType === 'csrn'
            ? {
                module: Events.CSRN.Module,
                screen: Events.CSRN.Screen.Profile.Name,
                eventName:
                  Events.CSRN.Screen.Profile.Events.CompleteRound_Click,
                param: {
                  roundId: currentRound.roundId
                },
                ...defaultTypeOrg
              }
            : {
                module: Events.CNEd.Module,
                screen: Events.CNEd.Screen.Profile.Name,
                eventName:
                  Events.CNEd.Screen.Profile.Events.CompleteRound_Click,
                param: {
                  roundId: currentRound.roundId
                },
                ...defaultTypeOrg
              }
          : {
              module: Events.Patient.Module,
              screen: Events.Patient.Screen.Profile.Name,
              eventName:
                Events.Patient.Screen.Profile.Events.CompleteRound_Click,
              param: {
                roundId: currentRound.roundId
              },
              ...defaultTypeOrg
            }
      ];
    default:
      console.log('No type for event');
      return [];
  }
}

// Report actions
export function logHRInsightsClick() {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  return [
    {
      module: Events.Report.Module,
      screen: Events.Report.Screen.Insights.Name,
      eventName: Events.Report.Screen.Insights.Events.HRInsights_Click,
      ...defaultTypeOrg
    }
  ];
}
export function logCOGReportClick() {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  return [
    {
      module: Events.Report.Module,
      screen: Events.Report.Screen.COG.Name,
      eventName: Events.Report.Screen.COG.Events.COGPatientReport_Click,
      ...defaultTypeOrg
    }
  ];
}

export function logReportsClick(view: string, event: ReportEvents) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (view) {
    case 'employee-rounds':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.EmployeeRounds.Name,
          eventName:
            event === 'date-range'
              ? Events.Report.Screen.EmployeeRounds.Events.DateRange_Click
              : event === 'filter'
              ? Events.Report.Screen.EmployeeRounds.Events.Filter_Click
              : event === 'export'
              ? Events.Report.Screen.EmployeeRounds.Events.ExportCSV_Click
              : Events.Report.Screen.EmployeeRounds.Events.Search_Click,
          ...defaultTypeOrg
        }
      ];
    case 'employee-frequency':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.EmployeeFreq.Name,
          eventName:
            event === 'date-range'
              ? Events.Report.Screen.EmployeeFreq.Events.DateRange_Click
              : event === 'filter'
              ? Events.Report.Screen.EmployeeFreq.Events.Filter_Click
              : event === 'export'
              ? Events.Report.Screen.EmployeeFreq.Events.ExportCSV_Click
              : Events.Report.Screen.EmployeeFreq.Events.Search_Click,
          ...defaultTypeOrg
        }
      ];
    case 'patient-compliance':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.PatientCompliance.Name,
          eventName:
            event === 'date-range'
              ? Events.Report.Screen.PatientCompliance.Events.DateRange_Click
              : event === 'info'
              ? Events.Report.Screen.PatientCompliance.Events.Info_Click
              : event === 'export'
              ? Events.Report.Screen.PatientCompliance.Events.ExportCSV_Click
              : Events.Report.Screen.PatientCompliance.Events
                  .Location_Dropdown_Click,
          ...defaultTypeOrg
        }
      ];
    case 'patient-rounds':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.PatientRounds.Name,
          eventName:
            event === 'filter'
              ? Events.Report.Screen.PatientRounds.Events.Filter_Click
              : event === 'info'
              ? Events.Report.Screen.PatientRounds.Events.Info_Click
              : event === 'export'
              ? Events.Report.Screen.PatientRounds.Events.ExportCSV_Click
              : event === 'search'
              ? Events.Report.Screen.PatientRounds.Events.Search_Click
              : Events.Report.Screen.PatientRounds.Events
                  .Location_Dropdown_Click,
          ...defaultTypeOrg
        }
      ];
    case 'patient-census':
      return [
        {
          module: Events.Report.Module,
          screen: Events.Report.Screen.PatientCensus.Name,
          eventName:
            event === 'calendar'
              ? Events.Report.Screen.PatientCensus.Events.Calendar_Click
              : event === 'info'
              ? Events.Report.Screen.PatientCensus.Events.Info_Click
              : event === 'export'
              ? Events.Report.Screen.PatientCensus.Events.ExportCSV_Click
              : event === 'search'
              ? Events.Report.Screen.PatientCensus.Events.Search_Click
              : Events.Report.Screen.PatientCensus.Events
                  .Location_Dropdown_Click,
          ...defaultTypeOrg
        }
      ];
    default:
      console.log('No report view for event');
      return [];
  }
}

// Report views
export function logRoundProfileFromReports(
  roundingType: 'employee' | 'patient'
) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultNameTypeOrg = {
    eventName: null,
    eventType: 'start',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  return [
    roundingType === 'employee'
      ? {
          module: Events.Employee.Module,
          screen: Events.Employee.Screen.Profile.Name,
          ...defaultNameTypeOrg
        }
      : {
          module: Events.Patient.Module,
          screen: Events.Patient.Screen.Profile.Name,
          ...defaultNameTypeOrg
        }
  ];
}

// Help
export function logHelpClick(event: HelpEvents) {
  const { coid } = store.getState().AuthorizedUser.authorizedUser;

  const defaultTypeOrg = {
    eventType: 'action',
    org: {
      orgId: coid,
      orgLevel: 'Facility'
    }
  };
  switch (event) {
    case 'sharepoint':
      return [
        {
          module: Events.Help.Module,
          screen: Events.Help.Screen.Sharepoint.Name,
          eventName:
            Events.Help.Screen.Sharepoint.Events.OrbitSharepointSite_Click,
          ...defaultTypeOrg
        }
      ];
    case 'health-stream':
      return [
        {
          module: Events.Help.Module,
          screen: Events.Help.Screen.HS.Name,
          eventName: Events.Help.Screen.HS.Events.HealthStream_Click,
          ...defaultTypeOrg
        }
      ];
    case 'hr-emp-rounding':
      return [
        {
          module: Events.Help.Module,
          screen: Events.Help.Screen.HR.Name,
          eventName: Events.Help.Screen.HR.Events.HREmployeeRounding_Click,
          ...defaultTypeOrg
        }
      ];
    default:
      console.log('No type for event');
      return [];
  }
}

// TODO: Leaving in case complete is added again
// export const tableLogTrackingComplete = (path: string | null) => {
//   const { coid } = store.getState().AuthorizedUser.authorizedUser;
//   switch (path) {
//     case '/patients/census':
//       return logTrackingEvent([
//         {
//           eventName: Events.Patient.Patient_List,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/patients/issues':
//       return logTrackingEvent([
//         {
//           eventName: Events.Patient.Patient_Issues,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/patients/feedback':
//       return logTrackingEvent([
//         {
//           eventName: Events.Patient.Patient_Feedback,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/patients/stoplight':
//       return logTrackingEvent([
//         {
//           eventName: Events.Patient.Patient_Stoplight,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/patients/profile':
//       return logTrackingEvent([
//         {
//           eventName: Events.Patient.Patient_Round,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/employees/list':
//       return logTrackingEvent([
//         {
//           eventName: Events.Employee.Employee_List,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/employees/issues':
//       return logTrackingEvent([
//         {
//           eventName: Events.Employee.Employee_Issues,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/employees/feedback':
//       return logTrackingEvent([
//         {
//           eventName: Events.Employee.Employee_Feedback,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/employees/stoplight':
//       return logTrackingEvent([
//         {
//           eventName: Events.Employee.Employee_Stoplight,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/employees/profile':
//       return logTrackingEvent([
//         {
//           eventName: Events.Employee.Employee_Round,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/employee/rounds':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Employee_Reports_Rounds,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/employee':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Employee_Reports_Rounds,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/employee/frequency':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Employee_Reports_Frequency,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/patient/compliance':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Patient_Reports_Compliance,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/patient':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Patient_Reports_Compliance,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/patient/rounds':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Patient_Reports_Rounds,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     case '/reports/patient/census':
//       return logTrackingEvent([
//         {
//           eventName: Events.Report.Patient_Reports_Census,
//           ...{
//             action: 'complete',
//             org: {
//               orgId: coid,
//               orgLevel: 'Facility'
//             }
//           }
//         }
//       ]);
//     default:
//       return undefined;
//   }
// };

import { all, call, put, select } from 'redux-saga/effects';
import { subWeeks } from 'date-fns';

import { getData, postData, putData } from '../../services/API/axios';

import ACTIONS from '../actions/actionType';
import API from '../../services/API';
import { toast } from '../../services/Toast';

import { getEmployeeNotes } from '../actions/User.action';
import store from '../store';

import { date } from '../../utils/helpers';

// Task sagas
export function* findEmployees(action) {
  try {
    const responses = yield all(
      action.data.map(empId => {
        const url = `${API.employeeSearchUrl}?employeeId=${empId}`;
        return call(getData, url);
      })
    );
    const employees = responses.map(res => res.data[0]).filter(data => data);
    yield put({
      type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_SUCCESS,
      data: employees
    });
  } catch (error) {
    console.log(error);
  }
}

export function* searchEmployees(action) {
  let url;
  if (action.data.match(/\d+/g)) {
    url = `${API.employeeSearchUrl}?employeeId=${action.data}`;
  } else {
    url = `${API.employeeSearchUrl}?name=${action.data}`;
  }
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      // appInsights.trackEvent({
      //   name: 'Employee_Searched'
      // });
      yield put({
        type: ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* searchEmployeesToShare(action) {
  let url;
  if (action.data.match(/\d+/g)) {
    url = `${API.employeeSearchUrl}?employeeId=${action.data}`;
  } else {
    url = `${API.employeeSearchUrl}?name=${action.data}`;
  }
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.EMPLOYEE.SEARCH_EMPLOYEES_TO_SHARE_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getEmployeesToRound(action) {
  let url;
  if (action.data.type === 'csrn' || action.data.type === 'cned') {
    url = `${API.getSupportSubjectsToRoundUrl}?userId=${action.data.hcaid}&supportType=${action.data.type}`;
  } else {
    url = `${API.getEmployeesToRoundUrl}?userId=${action.data.hcaid}`;
  }

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const cleanData = data.map(emp => {
        return {
          ...emp,
          fullName: `${emp.firstName} ${emp.lastName}`,
          lastRoundDate: emp?.lastRound?.roundDate,
          lastRoundedBy: emp?.lastRound?.roundedBy || 'Unknown',
          ...(action.data.type === 'csrn' && emp.rnExperienceDate
            ? {
                rnExperienceDate: date.parseMonthDayYearToYearMonthDay(
                  emp.rnExperienceDate
                )
              }
            : {})
        };
      });

      yield put({
        type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND_SUCCESS,
        data: cleanData
      });
      return yield put({
        type: ACTIONS.EMPLOYEE.SET_DELEGATED_EMPLOYEE,
        data: { hcaid: action.data.hcaid }
      });
    }
    return yield put({
      type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_TO_ROUND_FAILURE
    });
  }
}

export function* getCSRNSubjectsToRoundForList(action) {
  const { facilityId } = yield select(
    state => state.AuthorizedUser?.authorizedUser
  );
  yield call(getEmployeesToRound, { data: { ...action.data, type: 'csrn' } });

  const csrnSubjectsToRound = yield select(
    state => state.EmployeeReducer.employeesToRound
  );
  const csrnSubjectsToRoundIds = new Set(
    csrnSubjectsToRound.map(subject => subject.hcaid)
  );

  const now = new Date();
  const today = date.convertToDayOfYear(now);
  const twoWeeksAgo = date.convertToDayOfYear(subWeeks(now, 2));

  const url = `${
    API.getUserRoundHistoryUrl
  }?facilityId=${facilityId}&roundingType=csrn&startDate=${date.formatFilterStartDate(
    twoWeeksAgo
  )}&endDate=${date.formatFilterEndDate(today)}`;

  try {
    const { status, data } = yield call(getData, url);
    if (status === 200) {
      const adHocRounds = data.reduce((acc, history) => {
        if (!csrnSubjectsToRoundIds.has(history.employeeId)) {
          const subject = {
            ...history,
            adHoc: true,
            hcaid: history.employeeId,
            firstName: history.employeeFirstName,
            lastName: history.employeeLastName,
            fullName: `${history.employeeFirstName} ${history.employeeLastName}`,
            lastRoundDate: history?.roundDate,
            lastRoundedBy:
              `${history?.userFirstName} ${history?.userLastName}`.trim() ||
              'Unknown',
            ...(history.rnExperienceDate
              ? {
                  rnExperienceDate: date.parseMonthDayYearToYearMonthDay(
                    history.rnExperienceDate
                  )
                }
              : {})
          };

          const duplicateIndex = acc.findIndex(
            tempSubject => tempSubject.hcaid === history.employeeId
          );
          if (duplicateIndex !== -1) {
            if (
              history?.roundDate &&
              history?.roundDate > acc[duplicateIndex].roundDate
            ) {
              acc[duplicateIndex] = subject;
            } else {
              return acc;
            }
          }
          return [...acc, subject];
        }
        return acc;
      }, []);

      const combinedCSRNSubjectsAndAdHocRounds = [
        ...csrnSubjectsToRound,
        ...adHocRounds
      ];

      yield put({
        type: ACTIONS.EMPLOYEE.SET_DEPTS,
        data: [
          ...new Set(
            combinedCSRNSubjectsAndAdHocRounds.map(
              employee =>
                (employee.adHoc ? employee?.employeeDept : employee?.dept) ||
                'Unknown'
            )
          )
        ]
      });

      yield put({
        type: ACTIONS.EMPLOYEE.GET_CSRN_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS,
        data: combinedCSRNSubjectsAndAdHocRounds
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getCNEdSubjectsToRoundForList(action) {
  const { facilityId } = yield select(
    state => state.AuthorizedUser?.authorizedUser
  );
  yield call(getEmployeesToRound, { data: { ...action.data, type: 'cned' } });

  const cnedSubjectsToRound = yield select(
    state => state.EmployeeReducer.employeesToRound
  );
  const cnedSubjectsToRoundIds = new Set(
    cnedSubjectsToRound.map(subject => subject.hcaid)
  );

  const now = new Date();
  const today = date.convertToDayOfYear(now);
  const twoWeeksAgo = date.convertToDayOfYear(subWeeks(now, 2));

  const url = `${
    API.getUserRoundHistoryUrl
  }?facilityId=${facilityId}&roundingType=cned&startDate=${date.formatFilterStartDate(
    twoWeeksAgo
  )}&endDate=${date.formatFilterEndDate(today)}`;

  try {
    const { status, data } = yield call(getData, url);
    if (status === 200) {
      const adHocRounds = data.reduce((acc, history) => {
        if (!cnedSubjectsToRoundIds.has(history.employeeId)) {
          const subject = {
            ...history,
            adHoc: true,
            hcaid: history.employeeId,
            firstName: history.employeeFirstName,
            lastName: history.employeeLastName,
            fullName: `${history.employeeFirstName} ${history.employeeLastName}`,
            lastRoundDate: history?.roundDate,
            lastRoundedBy:
              `${history?.userFirstName} ${history?.userLastName}`.trim() ||
              'Unknown',
            ...(history.rnExperienceDate
              ? {
                  rnExperienceDate: date.parseMonthDayYearToYearMonthDay(
                    history.rnExperienceDate
                  )
                }
              : {})
          };

          const duplicateIndex = acc.findIndex(
            tempSubject => tempSubject.hcaid === history.employeeId
          );
          if (duplicateIndex !== -1) {
            if (
              history?.roundDate &&
              history?.roundDate > acc[duplicateIndex].roundDate
            ) {
              acc[duplicateIndex] = subject;
            } else {
              return acc;
            }
          }
          return [...acc, subject];
        }
        return acc;
      }, []);

      const combinedCNEdSubjectsAndAdHocRounds = [
        ...cnedSubjectsToRound,
        ...adHocRounds
      ];

      yield put({
        type: ACTIONS.EMPLOYEE.SET_DEPTS,
        data: [
          ...new Set(
            combinedCNEdSubjectsAndAdHocRounds.map(
              employee =>
                (employee.adHoc ? employee?.employeeDept : employee?.dept) ||
                'Unknown'
            )
          )
        ]
      });

      yield put({
        type: ACTIONS.EMPLOYEE.GET_CNED_SUBJECTS_TO_ROUND_FOR_LIST_SUCCESS,
        data: combinedCNEdSubjectsAndAdHocRounds
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getEmployeesConfig(action) {
  const url = `${API.getEmployeesConfigUrl}?employeeId=${action.data}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const formattedMyEmployeesList = [
        ...(data?.adds || []),
        ...(data?.directsList || [])
      ].map(employee => ({
        ...employee,
        hcaid: employee.userKey.split(':').pop(),
        dept: employee.department,
        managerFullName: employee.manager
      }));
      const formattedListsDelegatedToMe = data.roundingListsDelegatedToMe.map(
        employee => ({
          ...employee,
          employeeName: `${employee.firstName} ${employee.lastName}`
        })
      );
      yield put({
        type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_DELEGATED_TO_ME_SUCCESS,
        data: formattedListsDelegatedToMe
      });
      yield put({
        type: ACTIONS.EMPLOYEE.GET_EMPLOYEES_DELEGATED_TO_SUCCESS,
        data: data.employeesDelegatedTo
      });
      yield put({
        type: ACTIONS.EMPLOYEE.GET_MY_EMPLOYEES_SUCCESS,
        data: formattedMyEmployeesList
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getCSRNEmployeesConfig(action) {
  const url = `${API.getSupportEmployeesConfigUrl}?employeeId=${action.data}&supportType=csrn`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const formattedCSRNEmployeesList = data.map(employee => ({
        ...employee,
        hcaid: employee.userKey?.split(':').pop() ?? '',
        dept: employee.department,
        managerFullName: employee.manager,
        employeeName: `${employee.firstName} ${employee.lastName}`,
        isHidden: employee.isHidden
      }));
      return yield put({
        type: ACTIONS.EMPLOYEE.GET_CSRN_EMPLOYEES_CONFIG_SUCCESS,
        data: formattedCSRNEmployeesList
      });
    }
    return yield put({
      type: ACTIONS.EMPLOYEE.GET_CSRN_EMPLOYEES_CONFIG_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.EMPLOYEE.GET_CSRN_EMPLOYEES_CONFIG_FAILURE
    });
  }
}

export function* updateCSRNHiddenStatus(action) {
  const { userId, employeeId, isHidden } = action.data;
  // TODO: should we add facilityId to the query string?
  const url = `${API.updateSupportHiddenStatusUrl}?id=${employeeId}&supportType=csrn&isHidden=${isHidden}`;
  try {
    const { status } = yield call(putData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.EMPLOYEE.GET_CSRN_EMPLOYEES_CONFIG,
        data: userId
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getCNEdEmployeesConfig(action) {
  const url = `${API.getSupportEmployeesConfigUrl}?employeeId=${action.data}&supportType=cned`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      const formattedCNEdEmployeesList = data.map(employee => ({
        ...employee,
        hcaid: employee.userKey?.split(':').pop() ?? '',
        dept: employee.department,
        managerFullName: employee.manager,
        employeeName: `${employee.firstName} ${employee.lastName}`,
        isHidden: employee.isHidden
      }));
      return yield put({
        type: ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG_SUCCESS,
        data: formattedCNEdEmployeesList
      });
    }
    return yield put({
      type: ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG_FAILURE
    });
  } catch (error) {
    console.log(error);
    return yield put({
      type: ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG_FAILURE
    });
  }
}

export function* updateCNEdHiddenStatus(action) {
  const { userId, employeeId, isHidden } = action.data;
  // TODO: should we add facilityId to the query string?
  const url = `${API.updateSupportHiddenStatusUrl}?id=${employeeId}&supportType=cned&isHidden=${isHidden}`;
  try {
    const { status } = yield call(putData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.EMPLOYEE.GET_CNED_EMPLOYEES_CONFIG,
        data: userId
      });
    }
  } catch (error) {
    console.log(error);
  }
}

// function which helps to call multiple employee search for making MyEmployees list
export function* getMyEmployeesDetailed(employee) {
  const employeeId = employee.userKey.split(':').pop(); //  getting hcaId
  const getEmployeeUrl = `${API.employeeSearchUrl}?employeeId=${employeeId}`;
  const { data, status } = yield call(getData, getEmployeeUrl);
  if (status === 200) {
    return data[0];
  }
  return undefined;
}

export function* getMyEmployees(action) {
  const params = new URLSearchParams(action.data); // making query string
  const url = `${API.getEmployeesConfigUrl}?${params.toString()}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      // Search employee using the employee ID to get additional data
      const employeeData = yield all(
        [...(data?.adds || []), ...(data?.directsList || [])].map(employee =>
          call(getMyEmployeesDetailed, employee)
        )
      );
      yield put({
        type: ACTIONS.EMPLOYEE.GET_MY_EMPLOYEES_SUCCESS,
        data: employeeData
      });
    }
  } catch (error) {
    console.log(error);
  }
}
// To get delegate list rounding settings
export function* getDelegateSettings(action) {
  const params = new URLSearchParams(action.data); // making query string
  const url = `${API.getEmployeesConfigUrl}?${params.toString()}`;
  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.EMPLOYEE.GET_DELEGATE_SETTINGS_SUCCESS,
        data: data.myRoundingListConfig
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* getEmployeeAccountDetails(action) {
  // appInsights.trackEvent({
  //   name: 'Employee_Profile_Opened'
  // });
  const url = `${API.employeeSearchUrl}?employeeId=${action.data}`;

  try {
    const { data, status } = yield call(getData, url);
    if (status === 200) {
      yield put({
        type: ACTIONS.EMPLOYEE.GET_EMPLOYEE_ACCOUNT_DETAILS_SUCCESS,
        data
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function* postEmployeeNote(action) {
  const url = API.postEmployeeNoteUrl;
  try {
    const { status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Note Posted!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.EMPLOYEE.REMOVE_NOTE_SUCCESS
      });

      store.dispatch(getEmployeeNotes(action.data.employeeId)); // updating state

      return undefined;
    }
    return toast('Note Failed to Post!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Note Failed to Post!', 'error', null, 500, true);
  }
}

export function* removeEmployeeNote(action) {
  const url = API.removeEmployeeNoteUrl;
  try {
    const { status } = yield call(postData, url, action.data);
    if (status === 200) {
      toast('Note Removed!', 'success', 1000, 500);
      yield put({
        type: ACTIONS.PATIENT.REMOVE_NOTE_SUCCESS
      });

      // getting the updated employee data and updating the state
      store.dispatch(getEmployeeNotes(action.data.employeeId));

      return undefined;
    }
    return toast('Failed to Remove Note!', 'error', null, 500, true);
  } catch (error) {
    console.log(error);
    return toast('Failed to Remove Note!', 'error', null, 500, true);
  }
}
